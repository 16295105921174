import gql from 'graphql-tag';

export const GET_CALENDAR_EVENTS = gql`
    query CalendarEvents($machineId: ID!, $from: LocalDateTime!, $to: LocalDateTime!) {
        calendarEvents(machineId: $machineId, from: $from, to: $to) {
            id
            start
            end
            eventType
        }
    }
`;
