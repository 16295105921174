import {Typography, Link} from '@sym/common-ui/material';

interface NoMachineInformationProps {
    headline: string;
    description: string;
    href: string;
}

function NoMachineInformation({headline, description, href}: NoMachineInformationProps) {
    return (
        <>
            <Typography variant="body2">{headline}</Typography>
            <Link href={href} target="_top">
                {description}
            </Link>
        </>
    );
}

export default NoMachineInformation;
