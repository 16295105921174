import {css, styled} from '@sym/common-ui/material';
import {Card} from '@sym/common-ui/material';

export const StyledInfoCard = styled(Card)`
    ${({theme: {spacing, breakpoints, palette, shadows}}) => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-height: 80%;
        background: ${palette.background.paper};
        box-shadow: ${shadows[5]};
        padding: ${spacing(2, 3, 3)};
        overflow: auto;

        :focus {
            outline: none;
        }

        ${breakpoints.up('sm')} {
            padding: ${spacing(3, 4)};
            max-width: 60%;
        }
    `}
`;
export const StyledImg = styled('img')`
    ${({theme: {spacing, breakpoints, palette, shadows}}) => css`
        max-width: 100%;
        margin-bottom: ${spacing(3)};
        ${breakpoints.up('sm')} {
            margin-bottom: ${spacing(4)};
        }
    `}
`;
