import React, {ComponentType, useEffect, useState} from 'react';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import {PortalHelper} from '@sym/portal-sdk';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {dateFnsLocalizer, CalendarProps} from 'react-big-calendar';
import withDragAndDrop, {withDragAndDropProps} from 'react-big-calendar/lib/addons/dragAndDrop';
import {addHours, format, parse, getDay, endOfDay, differenceInCalendarISOWeeks, roundToNearestMinutes} from 'date-fns';
import {enUS, de, fr, it} from 'date-fns/locale';
import {useParams} from 'react-router-dom';
import {
    useCalendarEventsQuery,
    useDeleteCalendarEventMutation,
    useUpdateCalendarEventMutation,
    useCreateCalendarEventMutation,
} from '../../../graphql/generated/graphql';
import {
    dtoToCalendarEvent,
    invariantNoDateOverlap,
    mapDateOrStringToDate,
    mapDateOrStringToString,
    currentCalendarWeek,
    adjustForEndOfDay,
    getEventsToCreate,
    EventsToCreate,
} from './util';
import {ApplyTemplateWizard} from '../../../components/ApplyTemplate';
import {SaveTemplateModal} from '../../../components/SaveTemplateModal/SaveTemplateModal';

import {StyledCalendar, StyledCalendarWrapper, StyledTypography} from './ProductionPlannerPage.styled';
import {parseDateToLocalDateTime, removeTimeZone} from '../../../util/dataFormatter';

import {AddDownTime} from '../../../components/ProductionPlanner/addDowntime';
import {TCalendarEvent, DowntimeValues} from '../../../shared/types';
import {SubmitHandler} from 'react-hook-form';
import {EventMenu} from '../../../components';
import {Button, Grid, useMediaQuery} from '@sym/common-ui/material';
import {endOfWeekWithLocale, startOfWeekWithLocale} from '../../../util/i18n';
import {theme} from '../../../theme/GfmsTheme';

const MOBILE_BREAKPOINT = theme.breakpoints.values.md;

const startOfCurrentWeek = removeTimeZone(startOfWeekWithLocale(new Date()));
const endOfCurrentWeek = removeTimeZone(endOfWeekWithLocale(new Date()));

const locales = {
    'en-US': enUS,
    de,
    fr,
    it,
};

// see https://github.com/jquense/react-big-calendar/issues/1773
const DnDCalendar = withDragAndDrop<TCalendarEvent, {}>(
    StyledCalendar as ComponentType<CalendarProps<TCalendarEvent, {}>>
);

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfCurrentWeek,
    getDay,
    locales,
});

export const ProductionPlannerPage: React.FC<{}> = () => {
    const {id: machineId} = useParams<{id: string}>();
    const {enqueueSnackbar} = useSnackbar();
    const isMobile = useMediaQuery(`(max-width:${MOBILE_BREAKPOINT}px)`);
    const [selectedEvent, setSelectedEvent] = useState<TCalendarEvent | null>();

    const [displayDragItemInCell] = useState(true);
    const [draggedEvent, setDraggedEvent] = useState<TCalendarEvent | null>(null);
    const [calendarRange, setCalendarRange] = useState<{start: Date; end: Date}>({
        start: startOfCurrentWeek,
        end: endOfCurrentWeek,
    });

    const [showAddDownTime, setShowAddDownTime] = useState(false);
    const [isApplyModalOpen, setApplyModalOpen] = useState(false);
    const [isSaveTemplateModalOpen, setSaveTemplateModalOpen] = useState(false);

    const [calendarWeek, setCalendarWeek] = useState(currentCalendarWeek());
    const {t, i18n} = useTranslation();

    const {loading, error, data} = useCalendarEventsQuery({
        variables: {
            machineId,
            from: parseDateToLocalDateTime(calendarRange.start),
            to: parseDateToLocalDateTime(calendarRange.end),
        },
        // apply template only updates cache for the current week.
        // Other weeks will have stale data if we don't always fetch
        fetchPolicy: 'network-only',
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const events: TCalendarEvent[] = data?.calendarEvents.map(dtoToCalendarEvent) || [];

    const [deleteEvent] = useDeleteCalendarEventMutation({refetchQueries: ['CalendarEvents']});

    // TODO refetch query missing?
    const [updateEvent] = useUpdateCalendarEventMutation();

    const [createEvent] = useCreateCalendarEventMutation({refetchQueries: ['CalendarEvents']});

    const handleSelectEvent: CalendarProps<TCalendarEvent>['onSelectEvent'] = (event, e) => {
        setAnchorEl(e.currentTarget);
        setSelectedEvent(event);
    };

    const handleDeleteEvent = async (eventId: string) => {
        try {
            await deleteEvent({variables: {eventId, machineId}});
        } catch (error) {
            enqueueSnackbar(t('notification.DeleteEvent'), {
                variant: 'error',
            });
        }
    };

    const closeModal = () => {
        setShowAddDownTime(false);
    };

    const handleSelectSlot: CalendarProps['onSelectSlot'] = async ({start: rawStart, end: rawEnd, slots, action}) => {
        const eventType = 'ProductionTime';

        if (
            !invariantNoDateOverlap(
                {start: mapDateOrStringToDate(rawStart), end: mapDateOrStringToDate(rawEnd), eventType, id: 'fakeid'},
                events
            )
        ) {
            enqueueSnackbar(t('notification.OverlapEvent'), {
                variant: 'error',
            });
            return;
        }

        const start = mapDateOrStringToString(rawStart);
        let end: string;

        // if user clicks instead of drags the event should be one hour long
        if (action === 'click') {
            end = mapDateOrStringToString(addHours(new Date(rawStart), 1));
        } else {
            end = mapDateOrStringToString(rawEnd);
        }

        try {
            await createEvent({
                variables: {
                    data: [
                        {
                            start: parseDateToLocalDateTime(removeTimeZone(new Date(start))),
                            end: parseDateToLocalDateTime(removeTimeZone(new Date(end))),
                            eventType,
                        },
                    ],
                    machineId,
                },
            });
        } catch (error) {
            enqueueSnackbar('Error', {
                variant: 'error',
            });
        }
    };

    const handleDragStart: CalendarProps<TCalendarEvent>['handleDragStart'] = (event) => {
        setDraggedEvent(event);
    };

    const handleNavigate = (date: Date) => {
        const firstDayOfViewWeek = startOfWeekWithLocale(date);
        const firstOfJanuary = new Date(firstDayOfViewWeek.getFullYear(), 0, 1);

        const weekNumber = differenceInCalendarISOWeeks(firstDayOfViewWeek, firstOfJanuary);
        setCalendarWeek(weekNumber);
    };

    // @ts-ignore TODO
    const dragFromOutsideItem: withDragAndDropProps<TCalendarEvent>['dragFromOutsideItem'] = () => {
        return draggedEvent;
    };

    const onDropFromOutside: withDragAndDropProps<TCalendarEvent>['onDropFromOutside'] = ({start, end, allDay}) => {
        if (!draggedEvent) {
            return;
        }
        const event: TCalendarEvent = {
            id: draggedEvent.id,
            start: mapDateOrStringToDate(start),
            end: mapDateOrStringToDate(end),
            eventType: 'ProductionTime',
        };

        setDraggedEvent(null);

        moveEvent?.({event, start, end, isAllDay: false});
    };

    const moveEvent: withDragAndDropProps<TCalendarEvent>['onEventDrop'] = async ({
        event: {id: eventId},
        start,
        end,
        isAllDay: droppedOnAllDaySlot,
    }) => {
        if (droppedOnAllDaySlot) {
            enqueueSnackbar(t('notification.NoAllDayEvents'), {
                variant: 'error',
            });
        }

        if (
            !invariantNoDateOverlap(
                {
                    start: mapDateOrStringToDate(start),
                    end: mapDateOrStringToDate(end),
                    eventType: 'ProductionTime',
                    id: eventId,
                },
                events
            )
        ) {
            enqueueSnackbar(t('notification.OverlapEvent'), {
                variant: 'error',
            });
        }

        start = mapDateOrStringToString(start);
        end = mapDateOrStringToString(end);

        try {
            const eventType = 'ProductionTime';
            await updateEvent({
                variables: {
                    eventId,
                    machineId,
                    data: {
                        start: parseDateToLocalDateTime(
                            removeTimeZone(roundToNearestMinutes(new Date(start), {nearestTo: 30}))
                        ),
                        end: parseDateToLocalDateTime(
                            removeTimeZone(roundToNearestMinutes(new Date(end), {nearestTo: 30}))
                        ),
                        eventType,
                    },
                },
                optimisticResponse: {
                    updateCalendarEvent: {
                        start,
                        end,
                        eventType,
                        id: eventId,
                        __typename: 'CalendarEvent',
                    },
                },
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('notification.UpdateEvent'), {
                variant: 'error',
            });
        }
    };

    const onEventResize: withDragAndDropProps<TCalendarEvent>['onEventResize'] = async ({
        event: {id: eventId},
        start,
        end,
    }) => {
        start = mapDateOrStringToString(start);
        end = mapDateOrStringToString(end);

        if (
            !invariantNoDateOverlap(
                {
                    start: mapDateOrStringToDate(start),
                    end: mapDateOrStringToDate(end),
                    eventType: 'ProductionTime',
                    id: eventId,
                },
                events
            )
        ) {
            enqueueSnackbar(t('notification.OverlapEvent'), {
                variant: 'error',
            });
        }
        try {
            const eventType = 'ProductionTime';
            await updateEvent({
                variables: {
                    eventId,
                    machineId,
                    data: {
                        start: parseDateToLocalDateTime(
                            removeTimeZone(roundToNearestMinutes(new Date(start), {nearestTo: 30}))
                        ),
                        end: parseDateToLocalDateTime(
                            removeTimeZone(
                                roundToNearestMinutes(new Date(end), {
                                    nearestTo: 30,
                                })
                            )
                        ),
                        eventType,
                    },
                },

                optimisticResponse: {
                    updateCalendarEvent: {
                        start,
                        end,
                        eventType,
                        id: eventId,
                        __typename: 'CalendarEvent',
                    },
                },
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('notification.UpdateEvent'), {
                variant: 'error',
            });
        }
    };

    const handleRangeChange: CalendarProps['onRangeChange'] = (range, view) => {
        let start;
        let end;
        // pressing "Today", "Back" or "Next" -> range is Date[]
        if (Array.isArray(range)) {
            start = range[0];
            end = endOfDay(range[range.length - 1]);
            if (start === end) {
                end = endOfDay(start);
            }
        } else {
            // pressing "Day", "Week" or "Month" -> range is {start: Date, end: Date}
            start = mapDateOrStringToDate(range.start);
            end = mapDateOrStringToDate(range.end);
            if (start === end) {
                end = endOfDay(start);
            }
        }
        setCalendarRange({start: removeTimeZone(start), end: removeTimeZone(end)});
    };

    useEffect(() => {
        PortalHelper.displayProgressBar(loading);
    }, [loading]);

    if (error)
        enqueueSnackbar(t('notification.LoadingError'), {
            variant: 'warning',
        });

    const addDownTimeHandler = async (newEvents: EventsToCreate, selectedEvent: TCalendarEvent) => {
        if (newEvents.events.length === 0 && newEvents.shouldDeleteOldEvent === false) {
            enqueueSnackbar(t('productionPlanner.inValidTime'), {
                variant: 'info',
            });
        }

        if (newEvents.events.length === 0 && newEvents.shouldDeleteOldEvent === true) {
            await handleDeleteEvent(selectedEvent.id);
        }

        if (newEvents) {
            if (newEvents.events.length > 0 && newEvents.shouldDeleteOldEvent === true)
                try {
                    await handleDeleteEvent(selectedEvent.id);

                    await createEvent({
                        variables: {
                            data: newEvents.events.map((downtimeEvent) => {
                                return {
                                    start: parseDateToLocalDateTime(removeTimeZone(downtimeEvent.start)),
                                    end: parseDateToLocalDateTime(removeTimeZone(downtimeEvent.end)),
                                    eventType: 'ProductionTime',
                                };
                            }),
                            machineId,
                        },
                    });
                } catch (error) {
                    enqueueSnackbar(t('productionPlanner.errorCreatingEvents'), {
                        variant: 'error',
                    });
                }
        }
    };

    const handleSubmit: SubmitHandler<DowntimeValues> = (downtime) => {
        addDownTimeHandler(
            getEventsToCreate({start: downtime.start, end: downtime.end}, selectedEvent!),
            selectedEvent!
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid container item justifyContent="space-between">
                <Grid item xs={12} sm>
                    <StyledTypography variant="h6" data-testid="production-planner-calendarWeek">
                        {t('productionPlanner.CalendarWeek')}: {calendarWeek}
                    </StyledTypography>
                </Grid>
                <Grid item container spacing={1} xs={12} sm justifyContent="flex-end">
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => setSaveTemplateModalOpen(true)}>
                            {t('productionPlanner.saveTemplate.saveTemplateButton')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => setApplyModalOpen(true)}>
                            {t('productionPlanner.applyTemplate.applyTemplateButton')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <StyledCalendarWrapper data-testid="production-planner">
                    <DnDCalendar
                        // static props
                        defaultView="week"
                        endAccessor="end"
                        messages={{
                            previous: t('productionPlanner.previous'),
                            next: t('productionPlanner.next'),
                            today: t('productionPlanner.today'),
                        }}
                        selectable
                        startAccessor="start"
                        titleAccessor={(event) =>
                            event.eventType === 'ProductionTime' && !isMobile
                                ? t('productionPlanner.productiontime')
                                : ''
                        }
                        views={['week']}
                        // dynamic props
                        culture={i18n.language}
                        localizer={localizer}
                        events={events.map(adjustForEndOfDay)}
                        // @ts-expect-error TODO
                        dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
                        // scrollToTime={setDefaultViewStart(CALENDAR_STARTING_HOUR)}
                        // handlers
                        handleDragStart={handleDragStart}
                        onDropFromOutside={onDropFromOutside}
                        onEventDrop={moveEvent}
                        onEventResize={onEventResize}
                        onNavigate={(date) => handleNavigate(date)}
                        onRangeChange={handleRangeChange}
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleSelectEvent}
                    />
                    <EventMenu
                        anchorEl={anchorEl}
                        onDelete={() => {
                            handleDeleteEvent(selectedEvent!.id);
                            handleClose();
                        }}
                        onAddDowntime={() => {
                            setShowAddDownTime(true);
                            handleClose();
                        }}
                        handleClose={() => handleClose()}
                    />

                    {selectedEvent && (
                        <AddDownTime
                            isOpen={showAddDownTime}
                            onClose={closeModal}
                            event={selectedEvent}
                            onSubmit={handleSubmit}
                        />
                    )}
                </StyledCalendarWrapper>
            </Grid>

            <ApplyTemplateWizard
                open={isApplyModalOpen}
                onClose={() => setApplyModalOpen(false)}
                firstDayOfSchedule={calendarRange.start}
                machineId={machineId}
            />

            <SaveTemplateModal
                open={isSaveTemplateModalOpen}
                onClose={() => {
                    setSaveTemplateModalOpen(false);
                }}
                events={events}
            />
        </Grid>
    );
};
