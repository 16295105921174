import {getValidLocalStorageMachines} from './';
import {getInstalledMachines} from '../components';
import {ApplicationsQuery, MachinesQuery} from '../graphql/generated/graphql';
import {MachineFromQuery} from '../App';
import React, {useEffect} from 'react';

export function useLocalStorageMachines(
    machineData: MachinesQuery | undefined,
    applicationsData: ApplicationsQuery | undefined,
    userId: string,
    NUM_MACHINES_SHOWN_BY_DEFAULT: number,
    setSelectedMachineIds: React.Dispatch<React.SetStateAction<string[]>>
) {
    useEffect(() => {
        if (machineData && applicationsData) {
            const machines: MachineFromQuery[] = machineData?.assets || [];

            const {installedMachines} = getInstalledMachines(machines, applicationsData);
            const sortedMachines = [...installedMachines].sort((a, b) =>
                (a.displayName || '--').localeCompare(b.displayName || '--')
            );
            const initialMachineIds = sortedMachines
                .map((machine) => machine.id)
                .slice(0, NUM_MACHINES_SHOWN_BY_DEFAULT);

            const LOCAL_STORAGE_SELECTED_MACHINES = `performanceanalyzer.${userId}.selectedMachines`;
            const localStorageMachines: string[] = getValidLocalStorageMachines(
                JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_MACHINES) || 'null'),
                installedMachines.map((machine) => machine.id),
                LOCAL_STORAGE_SELECTED_MACHINES
            );

            const areLocalStorageMachinesSet = Boolean(
                JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_MACHINES) || 'null')
            );

            if (localStorageMachines.length >= 1 && areLocalStorageMachinesSet) {
                setSelectedMachineIds(localStorageMachines);
            }
            if (localStorageMachines.length === 0 && areLocalStorageMachinesSet) {
                setSelectedMachineIds([]);
            }
            if (!areLocalStorageMachinesSet) {
                setSelectedMachineIds(initialMachineIds);
            }
        }
    }, [machineData, applicationsData, userId, NUM_MACHINES_SHOWN_BY_DEFAULT, setSelectedMachineIds]);
}
