import {styled, css} from '@sym/common-ui/material';

export const StyledContainer = styled('div')`
    display: flex;
    align-items: center;
`;
export const StyledProgressBarWrapper = styled('div')`
    ${({theme: {spacing}}) => css`
        flex: 1;
        margin-right: ${spacing(1)};
    `}
`;

export const StyledProgramFile = styled('div')`
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
`;
