import React from 'react';
import {Grid, Typography} from '@sym/common-ui/material';
import {StyledGridItem, StyledHeadlineWrapper, StyledHeadline} from './KpiAverages.styled';
import {useTranslation} from 'react-i18next';
import {MoreInfoModal} from '../MoreInfoModal';

interface KpiAveragesProps {
    headline: string;
    description: string;
    availabilityAverage?: string;
    allocationEfficiencyAverage?: string;
    showPlannedKpis: boolean;
    setupRatioAverage?: string;
    technicalEfficiencyAverage?: string;
    utilizationEfficiencyAverage?: string;
    className?: string;
}

export const KpiAverages: React.FC<KpiAveragesProps> = ({
    headline,
    description,
    allocationEfficiencyAverage,
    availabilityAverage,
    showPlannedKpis,
    setupRatioAverage,
    technicalEfficiencyAverage,
    utilizationEfficiencyAverage,
    className,
}) => {
    const {t} = useTranslation();

    return (
        <div className={className}>
            <Grid container>
                <StyledHeadlineWrapper>
                    <StyledHeadline variant="h5">{headline}</StyledHeadline>
                    <MoreInfoModal headline={headline} description={description} />
                </StyledHeadlineWrapper>

                <StyledGridItem item xs={6} md={2}>
                    <Typography variant="h4">{setupRatioAverage}</Typography>
                    <Typography variant="body1">{t('kpiOverview.SetupRatio.Name')}</Typography>
                </StyledGridItem>

                <StyledGridItem item xs={6} md={2}>
                    <Typography variant="h4">{technicalEfficiencyAverage}</Typography>
                    <Typography variant="body1">{t('kpiOverview.TechnicalEfficiency.Name')}</Typography>
                </StyledGridItem>

                <StyledGridItem item xs={6} md={2}>
                    <Typography variant="h4">{utilizationEfficiencyAverage}</Typography>
                    <Typography variant="body1">{t('kpiOverview.UtilizationEfficiency.Name')}</Typography>
                </StyledGridItem>

                {showPlannedKpis && (
                    <>
                        <StyledGridItem item xs={6} md={2}>
                            <Typography variant="h4">{allocationEfficiencyAverage}</Typography>
                            <Typography variant="body1">{t('kpiOverview.AllocationEfficiency.Name')}</Typography>
                        </StyledGridItem>

                        <StyledGridItem item xs={6} md={2}>
                            <Typography variant="h4">{availabilityAverage}</Typography>
                            <Typography variant="body1">{t('kpiOverview.Availability.Name')}</Typography>
                        </StyledGridItem>
                    </>
                )}
            </Grid>
        </div>
    );
};
