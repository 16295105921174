import React from 'react';
import {Grid, Typography} from '@sym/common-ui/material';

import {FiberManualRecordRounded as FiberManualRecordRoundedIcon} from '@sym/common-ui/icons-material';

interface TimelineLegendItemProps {
    color: string;
    machineStateLabel: string;
}

export const TimelineLegendItem: React.FC<TimelineLegendItemProps> = ({color, machineStateLabel}) => {
    return (
        <Grid
            item
            container
            alignContent="center"
            spacing={1}
            wrap="nowrap"
            alignItems="center"
            justifyContent="flex-start"
            xs
        >
            <Grid item>
                <FiberManualRecordRoundedIcon htmlColor={color} />
            </Grid>
            <Grid item>
                <Typography variant="body2" color="textSecondary" noWrap>
                    {machineStateLabel}
                </Typography>
            </Grid>
        </Grid>
    );
};
