import {Menu, MenuItem} from '@sym/common-ui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface EventMenuProps {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    onDelete: () => void;
    onAddDowntime: () => void;
}

export const EventMenu: React.FC<EventMenuProps> = ({anchorEl, handleClose, onDelete, onAddDowntime}) => {
    const {t} = useTranslation();

    return (
        <Menu
            anchorEl={anchorEl}
            id="simple-menu"
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
        >
            <MenuItem aria-label="delete" onClick={onDelete}>
                {t('productionPlanner.DeleteEvent')}
            </MenuItem>
            <MenuItem aria-label="add downtime" onClick={onAddDowntime}>
                {t('productionPlanner.AddDownTimeToEvent')}
            </MenuItem>
        </Menu>
    );
};
