import {Typography} from '@sym/common-ui/material';
import React from 'react';
import {StyledContainer, StyledImageContainer} from './MachineTileHeader.styled';
import fallbackImage from '../../assets/machine_fallback.jpg';
interface MachineTileHeaderProps {
    imageSrc: string;
    machineName: string;
    modelName: string;
}

export const MachineTileHeader: React.FC<MachineTileHeaderProps> = ({imageSrc, machineName, modelName}) => {
    return (
        <StyledContainer>
            <StyledImageContainer>
                <img
                    src={imageSrc}
                    onError={(e) => {
                        // @ts-ignore
                        e.target.src = fallbackImage;
                    }}
                    alt="machine avatar"
                />
            </StyledImageContainer>
            <div>
                <Typography variant="h6">{machineName}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {modelName}
                </Typography>
            </div>
        </StyledContainer>
    );
};
