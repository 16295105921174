export const Routes = {
    machines: '/machines',
    kpiOverview: '/kpiOverview',
    jobs: '/machines/jobs',
    jobsTimeline: '/machines/jobs/:id/jobsTimeline',
    jobsActive: '/machines/jobs/:id/active',
    jobDetailLink: '/machines/activeJob',
    jobDetailRoute: '/machines/activeJob/:assetId/:jobId/:type',
    productionPlanner: '/machines/jobs/:id/planner',
} as const;
