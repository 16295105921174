import React from 'react';
import {useTranslation} from 'react-i18next';
import {SingleMachineSelect, Navigation} from '../../../components';
import {Grid} from '@sym/common-ui/material';
import {useParams, useHistory, Switch, Route, useRouteMatch, useLocation} from 'react-router-dom';

import {Routes} from '../../../routes';
import {ActiveAndHistoricalJobs, getSuffixPathname} from '../jobs';
import {ProductionPlannerPage} from '../planner/ProductionPlannerPage';
import {MachineFromQuery} from '../../../App';
import {StyledNavigationGridContainer} from './JobsPage.styled';
import {JobsTimelinePage} from '../jobsTimeline';
interface JobsPageProps {
    machines: MachineFromQuery[];
    groupId: string | null;
}

export const JobsPage: React.FC<JobsPageProps> = ({machines, groupId}) => {
    const {t} = useTranslation();
    const history = useHistory();
    let {url} = useRouteMatch();
    const location = useLocation();
    const {id} = useParams<{id: string}>();

    const handleChange = (e: any, value: React.ReactNode) => {
        // @ts-ignore
        if (value?.props.disabled) {
            return;
        }
        const suffix = getSuffixPathname(location.pathname);
        history.replace(`${Routes.jobs}/${e.target.value}/${suffix}${location.search}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12}>
                <Grid item xs={12} sm={4}>
                    <SingleMachineSelect value={id} machines={machines} handleChange={handleChange} />
                </Grid>
            </Grid>

            <StyledNavigationGridContainer container item xs={12} sm={6}>
                <Grid item xs>
                    <Navigation
                        tabs={[
                            {label: t('Navigation.Jobs'), route: `${url}/active`},
                            {label: t('Navigation.Timeline'), route: `${url}/jobsTimeline`},
                            {label: t('Navigation.Planner'), route: `${url}/planner`},
                        ]}
                    />
                </Grid>
            </StyledNavigationGridContainer>

            <Grid container item xs={12}>
                <Switch>
                    <Route exact path={`${Routes.jobsActive}`}>
                        <ActiveAndHistoricalJobs groupId={groupId} />
                    </Route>

                    <Route exact path={`${Routes.jobsTimeline}`}>
                        <JobsTimelinePage />
                    </Route>

                    <Route exact path={`${Routes.productionPlanner}`}>
                        <ProductionPlannerPage />
                    </Route>
                </Switch>
            </Grid>
        </Grid>
    );
};
