import {Card} from '@sym/common-ui/material';
import {css, styled} from '@sym/common-ui/material';

export const StyledCard = styled(Card)`
    ${({theme: {breakpoints, spacing, palette}}) => css`
        display: flex;
        justify-content: space-between;
        height: 100%;

        padding: ${spacing(2)} ${spacing(2)} ${spacing(2)};
        background-color: ${palette.background.paper};

     }
    `}
`;
export const StyledContainer = styled('div')`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
    `}
`;

export const StyledImageContainer = styled('div')`
    ${({theme: {spacing}}) => css`
        margin-right: ${spacing(2)};

        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;

        img {
            width: auto;
            height: 100%;
        }
    `}
`;
