import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {getAccessToken} from './auth/authProvider';

const UNAUTHENTICATED_CODE = 'UNAUTHENTICATED';
let authToken: string | undefined;

export const clearAuthToken = (): void => (authToken = undefined);

export const resolveAuthToken = async (): Promise<string | undefined> => {
    if (authToken !== undefined) {
        return Promise.resolve(authToken);
    } else {
        return getAccessToken();
    }
};

const authTokenHeader = setContext((operation, prevContext) =>
    resolveAuthToken().then((token) => {
        authToken = token;
        return {
            headers: {
                ...prevContext.headers,
                authorization: `Bearer ${authToken}`,
            },
        };
    })
);

const resetTokenAndRetry = onError(({graphQLErrors = [], operation, forward}) => {
    const [error] = graphQLErrors;
    if (error?.extensions?.code === UNAUTHENTICATED_CODE) {
        clearAuthToken();
        return forward(operation);
    }
});

export const authLink = resetTokenAndRetry.concat(authTokenHeader);
