import {CalendarEvent as CalenderEventDto} from '../../../graphql/generated/graphql';
import {TCalendarEvent, DowntimeValues} from '../../../shared/types';
import {sub, isEqual, toDate, differenceInCalendarISOWeeks} from 'date-fns';

export const mapDateOrStringToDate = (input: Date | string): Date => {
    if (typeof input === 'object') {
        return input;
    }
    return new Date(input);
};

export const mapDateOrStringToString = (input: Date | string): string => {
    if (typeof input === 'object') {
        return input.toISOString();
    }
    return input;
};

export const dtoToCalendarEvent = (dto: CalenderEventDto): TCalendarEvent => {
    return {
        start: new Date(dto.start),
        end: new Date(dto.end),
        id: dto.id,
        eventType: dto.eventType,
    };
};

/**
 * As per requirement, no two events should ever overlap.
 * Production downtimes will be implemented by splitting existing production times
 * @returns true if no two events overlap, false otherwise
 */
export const invariantNoDateOverlap = (newEvent: TCalendarEvent, existingEvents: TCalendarEvent[]): boolean => {
    const result = existingEvents.map((existingEvent) => {
        if (existingEvent.id === newEvent.id) {
            return true;
        }
        const newEventStartsBeforeStart = newEvent.start <= existingEvent.start;
        const newEventStartsAfterStart = newEvent.start >= existingEvent.start;
        const newEventStartsBeforeEnd = newEvent.start <= existingEvent.end;
        const newEventStartsAfterEnd = newEvent.start >= existingEvent.end;

        const newEventEndsAfterStart = newEvent.end >= existingEvent.start;
        const newEventEndsBeforeStart = newEvent.end <= existingEvent.start;

        if (newEventEndsBeforeStart) {
            return true;
        }

        if (newEventStartsAfterEnd) {
            return true;
        }

        if (newEventStartsAfterStart && newEventStartsBeforeEnd) {
            return false;
        }

        if (newEventStartsBeforeStart && newEventEndsAfterStart) {
            return false;
        }

        return true;
    });
    const invariantOkayForAllExistingEvents = result.every((x) => x === true);

    return invariantOkayForAllExistingEvents;
};

// TODO testing
export const currentCalendarWeek = (): number => {
    const today = new Date();
    const firstJanuaryOfThisYear = new Date(today.getFullYear(), 0, 1);

    return differenceInCalendarISOWeeks(today, firstJanuaryOfThisYear);
};

// TODO testing
export const setDefaultViewStart = (hour: number): Date => {
    const now = new Date();
    const today = toDate(now.setDate(now.getDate()));

    return new Date(today.setHours(hour));
};

export const adjustForEndOfDay = (event: TCalendarEvent): TCalendarEvent => {
    const myEnd = event.end;

    if (myEnd.getHours() !== 0 || myEnd.getMinutes() !== 0) {
        return event;
    }
    const newEnd = sub(myEnd, {minutes: 1});
    return {...event, end: newEnd};
};

export type EventsToCreate = {
    events: DowntimeValues[];
    shouldDeleteOldEvent?: boolean;
};

export const getEventsToCreate = (downtime: DowntimeValues, selectedEvent: TCalendarEvent): EventsToCreate => {
    const startEvent = selectedEvent.start;
    const startDownTime = downtime.start;
    const endDownTime = downtime.end;
    const endEvent = selectedEvent.end;

    if (endDownTime < startEvent || startDownTime > endEvent) return {events: [], shouldDeleteOldEvent: false};

    if (startDownTime < startEvent && startEvent < endDownTime && endDownTime < endEvent) {
        return {
            events: [
                {
                    start: endDownTime,
                    end: endEvent,
                },
            ],
            shouldDeleteOldEvent: true,
        };
    }

    if (startEvent < startDownTime && startDownTime < endEvent && endEvent < endDownTime) {
        return {
            events: [
                {
                    start: startEvent,
                    end: startDownTime,
                },
            ],
            shouldDeleteOldEvent: true,
        };
    }

    if (startDownTime < startEvent && isEqual(endDownTime, endEvent)) {
        return {
            events: [],
            shouldDeleteOldEvent: true,
        };
    }

    if (isEqual(startDownTime, startEvent) && isEqual(endDownTime, endEvent)) {
        return {
            events: [],
            shouldDeleteOldEvent: true,
        };
    }

    if (isEqual(startDownTime, startEvent) && endEvent < endDownTime) {
        return {
            events: [],
            shouldDeleteOldEvent: true,
        };
    }

    if (isEqual(startDownTime, endEvent) && endEvent < endDownTime) {
        return {
            events: [],
            shouldDeleteOldEvent: false,
        };
    }

    if (isEqual(startDownTime, startEvent)) {
        return {
            events: [
                {
                    start: endDownTime,
                    end: endEvent,
                },
            ],
            shouldDeleteOldEvent: true,
        };
    }

    if (isEqual(endDownTime, endEvent)) {
        return {
            events: [
                {
                    start: startEvent,
                    end: startDownTime,
                },
            ],
            shouldDeleteOldEvent: true,
        };
    }

    if (startEvent < startDownTime && endDownTime < endEvent) {
        return {
            events: [
                {
                    start: startEvent,
                    end: startDownTime,
                },
                {
                    start: endDownTime,
                    end: endEvent,
                },
            ],
            shouldDeleteOldEvent: true,
        };
    }

    if (startDownTime < startEvent && endEvent < endDownTime) {
        return {events: [], shouldDeleteOldEvent: true};
    } else return {events: [], shouldDeleteOldEvent: false};
};
