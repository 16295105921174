import React from 'react';

import {History as HistoryIcon} from '@sym/common-ui/icons-material';

import {StyledButton} from './JobHistoryQuicklinks.styled';

interface Props {
    onClick(): void;
}
export const JobHistoryQuickLinksButton: React.FC<Props> = ({onClick}) => {
    return (
        <StyledButton data-testid="drawer-button" color="primary" variant="contained" onClick={onClick}>
            <HistoryIcon />
        </StyledButton>
    );
};
