export const getSuffixPathname = (pathname: string): string => {
    if (pathname.endsWith('/planner')) {
        return 'planner';
    }
    if (pathname.endsWith('/jobsTimeline')) {
        return 'jobsTimeline';
    } else {
        return 'active';
    }
};
