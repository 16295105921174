import React, {useState} from 'react';
import {Box, IconButton, Modal} from '@sym/common-ui/material';
import {InfoCard} from '../InfoCard';

import {Info as InfoIcon} from '@sym/common-ui/icons-material';

import {ImageProps} from '../../shared/types';

export interface MoreInfoModalProps {
    headline: string;
    description: string;
    image?: ImageProps;
}

export const MoreInfoModal: React.FC<MoreInfoModalProps> = ({headline, description, image}) => {
    const [open, setOpen] = useState(false);
    const handleOpenInfo = () => {
        setOpen(true);
    };

    const handleCloseInfo = () => {
        setOpen(false);
    };
    return (
        <>
            <IconButton
                aria-label="show description"
                data-testid="InfoIcon"
                color="default"
                type="button"
                onClick={handleOpenInfo}
            >
                <InfoIcon />
            </IconButton>
            <Modal open={open} onClose={handleCloseInfo} aria-labelledby="KPI description">
                <Box>
                    <InfoCard headline={headline} image={image} description={description} data-testid="InfoCard" />
                </Box>
            </Modal>
        </>
    );
};
