import {startOfToday, startOfTomorrow} from 'date-fns';
import {DateRange} from '../shared/types';
import {removeTimeZone} from '.';

export const getDateRange = (dateRange: DateRange): DateRange | null => {
    if (!dateRange.startDate || !dateRange.endDate) {
        return null;
    }

    let startDate = dateRange.startDate;
    let endDate = dateRange.endDate;
    const startOfFuture = startOfTomorrow();

    if (startDate >= startOfFuture) {
        startDate = startOfToday();
    }

    if (endDate > startOfFuture) {
        endDate = startOfFuture;
    }

    return {
        startDate: removeTimeZone(startDate),
        endDate: removeTimeZone(endDate),
    };
};
