import gql from 'graphql-tag';

export const GET_KPIDATA = gql`
    query KpiOverview($from: LocalDateTime!, $to: LocalDateTime!, $machineIds: [ID!]!) {
        kpiOverview(kpiDataRequestInput: {from: $from, to: $to, machineIds: $machineIds}) {
            availability {
                average
                values {
                    date
                    value
                }
            }
            technicalEfficiency {
                average
                values {
                    date
                    value
                }
            }
            setupRatio {
                average
                values {
                    date
                    value
                }
            }
            allocationEfficiency {
                average
                values {
                    date
                    value
                }
            }
            utilizationEfficiency {
                average
                values {
                    date
                    value
                }
            }
            metaData {
                reliabilities {
                    date
                    percentageReliable
                }
                reliabilityAverage
                binSize {
                    value
                    unit
                }
            }
            plannedBusyRatio {
                average
                values {
                    date
                    value
                }
            }
        }
    }
`;
