import {css, styled} from '@sym/common-ui/material';

export const StyledModuleContainer = styled('div')`
    ${({theme: {spacing, breakpoints}}) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-bottom: ${spacing(4)};

        ${breakpoints.up('sm')} {
            justify-content: flex-end;
            margin-bottom: ${spacing(2)};
        }
    `}
`;

export const StyledDateRangePickerWrapper = styled('div')`
    ${({theme: {spacing, breakpoints}}) => css`
        margin-bottom: ${spacing(1)};
        padding-right: 0;

        ${breakpoints.up('sm')} {
            margin-bottom: 0;
            padding-right: ${spacing(4)};
        }
    `}
`;
