import {DayOfWeek, TemplateEventInput} from '../../graphql/generated/graphql';
import {getDay} from 'date-fns';
import {LocalTime, nativeJs} from '@js-joda/core';
import {TCalendarEvent} from '../../shared/types';

const getDayOfWeekFromNumber = (input: number): DayOfWeek => {
    switch (input) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';

        default:
            throw new Error(`input ${input} has to be between 0 and 6`);
    }
};

export const mapCalendarEventToTemplateEventInput = (event: TCalendarEvent): TemplateEventInput => {
    const {start, end, eventType} = event;

    const dayOfWeek: DayOfWeek = getDayOfWeekFromNumber(getDay(new Date(start)));

    const startAsLocalTime = LocalTime.from(nativeJs(new Date(start)));
    const endAsLocalTime = LocalTime.from(nativeJs(new Date(end)));

    return {
        start: startAsLocalTime.toString(),
        end: endAsLocalTime.toString(),
        eventType,
        dayOfWeek,
    };
};
