import {Card, css, styled} from '@sym/common-ui/material';
import {KpiAverages} from '../components';

export const StyledLayoutContainer = styled('div')`
    ${({theme: {breakpoints, spacing, palette}}) => css`
        width: 100%;
        height: 100%;
        min-height: 100vh;
        padding: ${spacing(2)};

        ${breakpoints.up('sm')} {
            padding: ${spacing(4)};
        }
    `}
`;

export const StyledHeadlineWrapper = styled('div')`
    ${({theme: {breakpoints, spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${spacing(3)};

        ${breakpoints.up('sm')} {
            margin-bottom: ${spacing(4)};
        }
    `}
`;

export const StyledKpiAverages = styled(KpiAverages)`
    ${({theme: {breakpoints, spacing}}) => css`
        margin-bottom: ${spacing(2)};

        ${breakpoints.up('sm')} {
            margin-bottom: ${spacing(4)};
        }
    `}
`;

export const StyledLineChartCard = styled(Card)`
    ${({theme: {breakpoints, spacing, palette}}) => css`
        height: 100%;
        padding: ${spacing(2)};
        backgroundcolor: ${palette.background.paper};

        ${breakpoints.up('sm')} {
            padding: ${spacing(3)};
        }
    `}
`;

export const StyledNavigationContainer = styled('div')<{shouldShowBackButton: boolean}>`
    ${({theme: {breakpoints, spacing}, shouldShowBackButton}) => css`
        margin-bottom: ${shouldShowBackButton ? `${spacing(2)}` : '0'};

        ${breakpoints.up('sm')} {
            margin-bottom: ${shouldShowBackButton ? `${spacing(4)}` : '0'};
        }
    `}
`;

export const StyledComponentWrapper = styled('div')`
    ${({theme: {breakpoints, spacing}}) => css`
        margin-bottom: ${spacing(4)};

        ${breakpoints.up('sm')} {
            margin-bottom: ${spacing(6)};
        }
    `}
`;
