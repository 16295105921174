import gql from 'graphql-tag';

export const GET_JOB_HISTORY = gql`
    query JobHistory($assetId: ID!, $from: LocalDateTime!, $count: Int!) {
        asset(assetId: $assetId) {
            id
            displayName
            jobHistory(from: $from, count: $count) {
                id
                jobExecutionId
                mainProgramFile
                mainProgramName
                startTimestamp
                endTimestamp
                duration
                plannedRuntime
                hasErrors
                messages {
                    id
                    logType
                    text
                    type
                    messageId
                    timestamp
                    occurrenceStart
                    occurrenceEnd
                }
            }
        }
    }
`;
