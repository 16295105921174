import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {Theme} from '@sym/common-ui/material';
import {getResponsiveRules} from './responsiveConfig';

const BIN_SIZE_MONTH = 2592000;

export type ChartConfig = {
    binsize: number | undefined;
    chart: am4charts.XYChart;
    dateAxis: am4charts.DateAxis;
    isAutoScale: boolean;
    legend: am4charts.Legend;
    theme: Theme;
    valueAxis: am4charts.ValueAxis;
    xAxisLabel: string;
    yAxisLabel: string;
};

export const getChartConfig = ({
    binsize,
    chart,
    dateAxis,
    isAutoScale,
    legend,
    theme,
    valueAxis,
    xAxisLabel,
    yAxisLabel,
}: ChartConfig) => {
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);

    // Round all numbers to integer and percent
    chart.numberFormatter.numberFormat = isAutoScale ? '#.#%' : '#.%';

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'zoomX';

    dateAxis.events.on('startchanged', dateAxisChanged);
    dateAxis.events.on('endchanged', dateAxisChanged);
    function dateAxisChanged(
        ev: am4core.AMEvent<typeof dateAxis, am4charts.IDateAxisEvents>['startchanged' | 'endchanged']
    ) {
        console.log(ev);
    }

    dateAxis.title.text = `${xAxisLabel}`;
    dateAxis.startLocation = 0;
    dateAxis.endLocation = 1;
    dateAxis.title.paddingBottom = 16;

    if (binsize === BIN_SIZE_MONTH) {
        dateAxis.baseInterval = {timeUnit: 'day', count: 28};
    }

    valueAxis.extraMax = 0.01;
    valueAxis.extraMin = 0.05;
    valueAxis.strictMinMax = isAutoScale ? false : true;

    valueAxis.renderer.minGridDistance = isAutoScale ? 50 : 20;

    valueAxis.title.text = `${yAxisLabel}`;
    valueAxis.title.contentValign = 'top';

    legend.position = 'bottom';
    legend.valueLabels.template.align = 'center';

    chart.responsive.enabled = true;
    chart.maskBullets = false;

    chart.responsive.rules.push(getResponsiveRules(theme));
};
