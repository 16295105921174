import React from 'react';
import {CircularProgress, Grid} from '@sym/common-ui/material';
import {format} from 'date-fns';
import {MachineStateTimeline, JobHistoryQuickLinksButton, RelatedMessages} from '../../../../../components';
import {MachineTileHeader} from '../../../../../components/MachineTile/MachineTileHeader';
import {formatTime} from '../../../../../util';
import {Infobox} from '../../Infobox';
import {StyledProgramFile} from '../../JobsPage.styled';
import {useTranslation} from 'react-i18next';
import fallbackImage from '../../../../../assets/machine_fallback.jpg';
import {MachineFromQuery} from '../../../../../App';
import {useJobQuery} from '../../../../../graphql/generated/graphql';
import {useParams} from 'react-router-dom';

interface HistoricalJobContentProps {
    machines: MachineFromQuery[];
    isMobile: boolean;
    setQuicklinksDrawerOpen: any;
}

export const HistoricalJobContent: React.FC<HistoricalJobContentProps> = ({
    machines,
    isMobile,
    setQuicklinksDrawerOpen,
}) => {
    const {t} = useTranslation();
    const {assetId, jobId, type} = useParams<{assetId: string; jobId: string; type: 'active' | 'historical'}>();
    const {data, loading} = useJobQuery({variables: {assetId, jobId}});

    const machine = machines.find((machine) => machine.id === assetId);
    const jobHistory = data?.asset?.job;
    // TODO standardize with other 'n/a', '--', etc
    const machineName = machine?.displayName || 'n/a';
    const modelName = machine?.machineModel?.modelName || 'n/a';
    const unitStates = data?.asset?.job?.unitStates;

    const {duration, startTimestamp, endTimestamp, mainProgramFile, mainProgramName} = jobHistory || {};

    const startTimeLabel = startTimestamp
        ? // TODO standardize with other 'n/a', '--', etc
          `${format(new Date(startTimestamp), 'dd.MM.yyyy HH:mm:ss')}`
        : '--';

    const endTimeLabel = endTimestamp ? `${format(new Date(endTimestamp), 'dd.MM.yyyy HH:mm:ss')}` : '--';

    const durationLabel = duration ? formatTime(duration * 1000) : 'n/a';

    if (loading)
        return (
            <Grid container justifyContent="center" xs={isMobile ? 12 : 8}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );

    return (
        <Grid container item spacing={3} xs={isMobile ? 12 : 8}>
            <Grid item xs={12}>
                <MachineTileHeader imageSrc={fallbackImage} machineName={machineName} modelName={modelName} />
            </Grid>

            <Grid item xs={12} sm={4}>
                <Infobox label={t('machineTile.MainProgramName')} value={mainProgramName} />
            </Grid>
            <Grid item xs={12} sm={8}>
                <Infobox
                    label={t('machineTile.MainProgramFile')}
                    value={<StyledProgramFile>{mainProgramFile || '--'}</StyledProgramFile>}
                    title={mainProgramFile || undefined}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <Infobox label={t('machineTile.StartTimestamp')} value={startTimeLabel} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Infobox label={t('machineTile.EndTimestamp')} value={endTimeLabel} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Infobox label={t('machineTile.Duration')} value={durationLabel} />
            </Grid>
            <Grid item xs={12}>
                {unitStates && (
                    <Grid item xs={12}>
                        <MachineStateTimeline unitStates={unitStates} />
                    </Grid>
                )}
            </Grid>

            <RelatedMessages isMobile={isMobile} assetId={assetId} jobId={jobId} />

            {isMobile && <JobHistoryQuickLinksButton onClick={() => setQuicklinksDrawerOpen(true)} />}
        </Grid>
    );
};
