import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {
    GridAreaCard,
    GridAreaImage,
    StyledImageWrapper,
    StyledChip,
    StyledCard,
    StyledCustomGrid,
    StyledContainer,
} from './MachineTile.styled';
import {MachinesQuery, MachineState} from '../../graphql/generated/graphql';
import {Routes} from '../../routes';
import {getAvatarOrFallback, getStateLabel} from './util';
import {MachineInformation} from '..';

interface MachineTileProps {
    machineState: MachineState;
    machine: MachinesQuery['assets'][0] | undefined;
}

function MachineTileInner({machineState, machine}: MachineTileProps) {
    const {t} = useTranslation();
    const history = useHistory();

    const {unitStatusState} = machineState;

    const avatar = getAvatarOrFallback(machine?.machineModel?.avatar);
    const statusStateLabel = unitStatusState ? getStateLabel(unitStatusState, t) : 'Unknown';

    const isOutOfService = unitStatusState === 'OutOfService';

    const handleOnCardClick = () => {
        history.push(`${Routes.jobs}/${machine?.id}/active`);
    };

    return (
        <StyledCustomGrid data-testid={`machineTile-${machine?.displayName || t('NoInformation')}`}>
            <GridAreaImage>
                <StyledContainer>
                    <StyledImageWrapper>
                        <img
                            src={avatar}
                            onError={(e) => {
                                // @ts-ignore
                                e.target.src = fallbackImage;
                            }}
                            alt="machine avatar"
                        />
                    </StyledImageWrapper>
                    <StyledChip
                        size="small"
                        title={`${t('machineTile.MachineState')}: ${statusStateLabel}`}
                        label={statusStateLabel}
                        machineState={unitStatusState ? unitStatusState : ''}
                    />
                </StyledContainer>
            </GridAreaImage>
            <GridAreaCard>
                <StyledCard
                    isWarning={isOutOfService}
                    onClick={handleOnCardClick}
                    data-testid={`machine-tile-${machine?.id}`}
                >
                    <MachineInformation machineState={machineState} machine={machine} />
                </StyledCard>
            </GridAreaCard>
        </StyledCustomGrid>
    );
}

export const MachineTile = memo(MachineTileInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
