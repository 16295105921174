import * as am4charts from '@amcharts/amcharts4/charts';
import {Theme} from '@sym/common-ui/material';

export const getResponsiveRules = (theme: Theme): any => ({
    relevant: function (target: any) {
        if (target.pixelWidth <= 400) {
            return true;
        }
        return false;
    },
    state: function (target: any, stateId: any): any | null {
        if (target instanceof am4charts.Chart) {
            var chartState = target.states.create(stateId);
            chartState.properties.paddingTop = 0;
            chartState.properties.paddingRight = 0;
            chartState.properties.paddingBottom = 0;
            chartState.properties.paddingLeft = 0;

            return chartState;
        }

        if (target instanceof am4charts.DateAxis) {
            var dateAxisState = target.states.create(stateId);
            dateAxisState.properties.paddingBottom = 16;
            dateAxisState.properties.paddingTop = 8;

            return dateAxisState;
        }

        if (target instanceof am4charts.ValueAxis) {
            var valueAxisState = target.states.create(stateId);
            valueAxisState.properties.paddingTop = 0;
            valueAxisState.properties.paddingRight = 0;
            valueAxisState.properties.paddingBottom = 0;
            valueAxisState.properties.paddingLeft = 0;

            return valueAxisState;
        }

        if (target instanceof am4charts.AxisLabel) {
            var AxisLabelState = target.states.create(stateId);
            AxisLabelState.properties.hidden = true;

            return AxisLabelState;
        }

        return null;
    },
});
