import {css, styled} from '@sym/common-ui/material';
import {UnitStatusState} from '../../graphql/generated/graphql';
import {FiberManualRecord} from '@sym/common-ui/icons-material';
export const StyledStatusContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: unset;

    ${({theme: {breakpoints}}) => css`
        ${breakpoints.down('sm')} {
            justify-content: end;
        }
    `}

    & > svg {
        ${({theme: {spacing}}) => css`
            margin-right: ${spacing(1)};
        `}
    }
`;

export const StyledFiberManualRecordIcon = styled(FiberManualRecord)<{state: UnitStatusState}>`
    color: ${({state, theme}) => {
        switch (state) {
            case 'Executing':
                return theme.palette.success.main;
            case 'Waiting':
                return theme.palette.info.main;
            case 'OutOfService':
                return theme.palette.error.main;
            case 'NotApplicable':
            case 'Undefined':
            case 'Unknown':
                return theme.palette.grey[500];
            default:
                return theme.palette.grey[500];
        }
    }};
`;
