import React, {ChangeEvent, FC} from 'react';
import {Typography, FormControlLabel, Radio, RadioGroup, TextField, Grid, Alert} from '@sym/common-ui/material';
import {WeekPicker} from '../../../components';
import {useTranslation} from 'react-i18next';
import {TargetType} from '../ApplyTemplateWizard';
import {startOfWeekWithLocale} from '../../../util/i18n';

export const MIN_WEEKS = 1;
const MAX_WEEKS = 24;
interface Step2Props {
    targetType: TargetType;
    setTargetType: React.Dispatch<React.SetStateAction<TargetType>>;
    targetWeeks: number;
    setTargetWeeks: React.Dispatch<React.SetStateAction<number>>;
    targetDate: Date;
    setTargetDate: React.Dispatch<React.SetStateAction<Date>>;
    targetStartDate: Date;
    setTargetStartDate: React.Dispatch<React.SetStateAction<Date>>;
    startDateIsBeforeEndDate: boolean;
}

export const Step2: FC<Step2Props> = ({
    targetType,
    setTargetType,
    targetWeeks,
    setTargetWeeks,
    targetDate,
    setTargetDate,
    targetStartDate,
    setTargetStartDate,
    startDateIsBeforeEndDate,
}) => {
    const {t} = useTranslation();

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTargetType(event.target.value as TargetType);
    };

    const handleWeeksChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (value > MAX_WEEKS) {
            return setTargetWeeks(MAX_WEEKS);
        }
        if (value < MIN_WEEKS) {
            return setTargetWeeks(MIN_WEEKS);
        }
        setTargetWeeks(value);
    };

    const handleStartDateChange = (date: Date) => {
        setTargetStartDate(startOfWeekWithLocale(date));
    };

    const handleDateChange = (date: Date) => {
        setTargetDate(startOfWeekWithLocale(date));
    };

    return (
        <Grid container spacing={4}>
            <Grid item>
                <Typography variant="h6">{t('productionPlanner.applyTemplate.step2.headline')}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('productionPlanner.applyTemplate.step2.subHeadline')}
                </Typography>
            </Grid>

            <Grid container item spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('productionPlanner.applyTemplate.step2.selectStart')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <WeekPicker value={targetStartDate} handleChange={handleStartDateChange} />
                </Grid>
            </Grid>

            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('productionPlanner.applyTemplate.step2.selectEnd')}
                    </Typography>
                </Grid>

                <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <RadioGroup
                            aria-label="target-select"
                            name="target-select"
                            value={targetType}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel
                                value="repetitions"
                                control={<Radio size="small" color="primary" />}
                                label={`${t('productionPlanner.applyTemplate.step2.repetitionsLabel')}`}
                            />
                            <FormControlLabel
                                value="weekSelect"
                                control={<Radio size="small" color="primary" />}
                                label={`${t('productionPlanner.applyTemplate.step2.weekSelectLabel')}`}
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {targetType === 'repetitions' && (
                            <TextField
                                disabled={targetType !== 'repetitions'}
                                type="number"
                                label={t('productionPlanner.applyTemplate.step2.textInputLabel')}
                                value={targetWeeks}
                                onChange={handleWeeksChange}
                            />
                        )}
                        {targetType === 'weekSelect' && (
                            <WeekPicker
                                isDisabled={targetType !== 'weekSelect'}
                                value={targetDate}
                                handleChange={handleDateChange}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {targetType === 'weekSelect' && !startDateIsBeforeEndDate && (
                <Alert severity="error">Start date must be before end date</Alert>
            )}
        </Grid>
    );
};
