import React from 'react';
import {LinearProgress, Typography} from '@sym/common-ui/material';
import {StyledContainer, StyledProgressBarWrapper} from './ProgressBar.styled';

interface ProgressBarProps {
    headline?: string;
    value: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({headline, value}) => {
    return (
        <>
            {headline && (
                <Typography variant="body2" color="textSecondary">
                    {headline}
                </Typography>
            )}
            <StyledContainer>
                <StyledProgressBarWrapper>
                    <LinearProgress variant="determinate" value={value} />
                </StyledProgressBarWrapper>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </StyledContainer>
        </>
    );
};
