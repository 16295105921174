export const getTextColor = (messageType: string): 'primary' | 'textSecondary' | 'error' => {
    switch (messageType) {
        case 'Error':
            return 'error';
        case 'Warning':
            return 'primary';
        default:
            return 'textSecondary';
    }
};
