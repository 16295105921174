import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useApolloClient, useLazyQuery} from '@apollo/client';
import {DateRange} from '../../shared/types';
import {KpiOverviewQuery} from '../../graphql/generated/graphql';
import {FormControlLabel, Grid, Switch, Typography} from '@sym/common-ui/material';
import {GET_KPIDATA} from '../../queries';
import {StyledKpiAverages} from '../../layout';
import {parseDateToLocalDateTime} from '../../util/dataFormatter';
import {useSnackbar} from 'notistack';
import {Chart} from './Chart';
import {LOCAL_STORAGE_KEY_PLANNED_KPIS, modifyAverage} from './util';
import {MachineFromQuery} from '../../App';

import technicalEfficiencyImage from '../../assets/graphics/kpi/technicalEfficiency.png';
import setupRatioImage from '../../assets/graphics/kpi/setupRatio.png';
import utilizationEfficiencyImage from '../../assets/graphics/kpi/utilizationEfficiency.png';
import availabilityImage from '../../assets/graphics/kpi/availability.png';
import allocationEfficiencyImage from '../../assets/graphics/kpi/allocationEfficiency.png';
import {useKpiOverviewPerMachineQuery} from './hooks/useKpiOverviewPerMachineQuery';
import {KpiOverviewProgress} from './KpiOverviewProgress';

interface KpiOverviewProps {
    currentDateRange: DateRange;
    installedAndSelectedMachineIds: string[];
    installedMachines: MachineFromQuery[];
}

export const KpiOverview: React.FC<KpiOverviewProps> = ({
    currentDateRange,
    installedAndSelectedMachineIds,
    installedMachines,
}) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const client = useApolloClient();

    const noDataText = t('kpiOverview.noData');

    const [isAutoScale, setIsAutoScale] = useState<boolean>(false);
    const [showPlannedKpis, setShowPlannedKpis] = useState<boolean>(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PLANNED_KPIS) || 'false')
    );

    const from = currentDateRange.startDate!;
    const to = currentDateRange.endDate!;

    const [loadKpiData, {loading, error, data}] = useLazyQuery<KpiOverviewQuery>(GET_KPIDATA, {
        variables: {
            from: parseDateToLocalDateTime(from),
            to: parseDateToLocalDateTime(to),
            machineIds: installedAndSelectedMachineIds.map((id) => id),
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (installedAndSelectedMachineIds.length !== 0) {
            loadKpiData();
        }
    }, [installedAndSelectedMachineIds, from, to, loadKpiData]);

    const {
        data: {
            allocationEfficiencyState,
            availabilityState,
            setupRatioState,
            technicalEfficiencyState,
            utilizationEfficiencyState,
        },
        loading: chartLoading,
        error: chartError,
    } = useKpiOverviewPerMachineQuery(installedAndSelectedMachineIds, installedMachines, from, to);

    if (chartError) {
        enqueueSnackbar(t('Error'), {
            variant: 'error',
        });
    }

    const technicalEfficiencyAverage = data?.kpiOverview?.technicalEfficiency?.average;
    const setupRatioAverage = data?.kpiOverview?.setupRatio?.average;
    const utilizationEfficiencyAverage = data?.kpiOverview?.utilizationEfficiency?.average;
    const allocationEfficiencyAverage = data?.kpiOverview?.allocationEfficiency?.average;
    const availabilityAverage = data?.kpiOverview?.availability?.average;

    const technicalEfficiencyLineChartValues = data?.kpiOverview?.technicalEfficiency?.values;
    const setupRatioLineChartValues = data?.kpiOverview?.setupRatio?.values;
    const utilizationEfficiencyLineChartValues = data?.kpiOverview?.utilizationEfficiency?.values;
    const availabilityLineChartValues = data?.kpiOverview?.availability?.values;
    const allocationEfficiencyLineChartValues = data?.kpiOverview?.allocationEfficiency?.values;

    const handleKpiToggle = (checked: boolean) => {
        setShowPlannedKpis(checked);
        localStorage.setItem(LOCAL_STORAGE_KEY_PLANNED_KPIS, String(checked));
    };

    if (loading) return <KpiOverviewProgress testId="Progress" />;

    if (error) {
        // Yes, this sucks. BE needs to provide an error code.
        if (error.message === 'At least one machine id has to be provided') {
            return (
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        {t('kpiOverview.noMachineSelected')}
                    </Typography>
                </Grid>
            );
        } else {
            return (
                <Grid container alignContent="stretch" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            {t('Error')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center">
                            {error.message}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
    }

    if (installedAndSelectedMachineIds.length === 0)
        return (
            <Grid container justifyContent="center" xs>
                <Grid item>
                    <Typography>{t('kpiOverview.noMachineSelected')}</Typography>
                </Grid>
            </Grid>
        );

    return (
        <Grid container alignContent="stretch" spacing={2}>
            <Grid item xs={12}>
                <StyledKpiAverages
                    headline={`${t('kpiOverview.Headline')} (ISO 22400)`}
                    description={t('kpiOverview.Description')}
                    showPlannedKpis={showPlannedKpis}
                    allocationEfficiencyAverage={modifyAverage(allocationEfficiencyAverage, noDataText)}
                    availabilityAverage={modifyAverage(availabilityAverage, noDataText)}
                    setupRatioAverage={modifyAverage(setupRatioAverage, noDataText)}
                    technicalEfficiencyAverage={modifyAverage(technicalEfficiencyAverage, noDataText)}
                    utilizationEfficiencyAverage={modifyAverage(utilizationEfficiencyAverage, noDataText)}
                />
            </Grid>
            {chartLoading ? (
                <KpiOverviewProgress testId="chart-progress" />
            ) : (
                <>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={isAutoScale}
                                    onChange={() => setIsAutoScale(!isAutoScale)}
                                />
                            }
                            label={`${t('kpiOverview.SwitchLabel')}`}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={showPlannedKpis}
                                    onChange={(_, checked) => handleKpiToggle(checked)}
                                />
                            }
                            label={`${t('kpiOverview.SwitchLabelAdditionalCharts')}`}
                        />
                    </Grid>

                    <Chart
                        shouldDisplay={Boolean(technicalEfficiencyLineChartValues)}
                        headline={t('kpiOverview.TechnicalEfficiency.Name')}
                        description={t('kpiOverview.TechnicalEfficiency.Description')}
                        image={{src: technicalEfficiencyImage, alt: t('kpiOverview.TechnicalEfficiency.imageAlt')}}
                        isAutoScale={isAutoScale}
                        data={technicalEfficiencyState}
                        chartId="technicalEfficiency"
                        xAxisLabel={t('kpiOverview.XAxisLabel')}
                        yAxisLabel={t('kpiOverview.YAxisLabel')}
                        dataTestId="technicalEfficiencyChart"
                    />

                    <Chart
                        shouldDisplay={Boolean(setupRatioLineChartValues)}
                        headline={t('kpiOverview.SetupRatio.Name')}
                        description={t('kpiOverview.SetupRatio.Description')}
                        image={{src: setupRatioImage, alt: t('kpiOverview.SetupRatio.imageAlt')}}
                        isAutoScale={isAutoScale}
                        data={setupRatioState}
                        chartId="setupRatioChart"
                        xAxisLabel={t('kpiOverview.XAxisLabel')}
                        yAxisLabel={t('kpiOverview.YAxisLabel')}
                        dataTestId="setupRatioChart"
                    />

                    <Chart
                        shouldDisplay={Boolean(utilizationEfficiencyLineChartValues)}
                        headline={t('kpiOverview.UtilizationEfficiency.Name')}
                        description={t('kpiOverview.UtilizationEfficiency.Description')}
                        image={{src: utilizationEfficiencyImage, alt: t('kpiOverview.UtilizationEfficiency.imageAlt')}}
                        isAutoScale={isAutoScale}
                        data={utilizationEfficiencyState}
                        chartId="utilizationEfficiency"
                        xAxisLabel={t('kpiOverview.XAxisLabel')}
                        yAxisLabel={t('kpiOverview.YAxisLabel')}
                        dataTestId="utilizationEfficiencyChart"
                    />

                    <Chart
                        isPlannedKPI
                        shouldDisplay={showPlannedKpis && Boolean(availabilityLineChartValues)}
                        headline={t('kpiOverview.Availability.Name')}
                        description={t('kpiOverview.Availability.Description')}
                        image={{src: availabilityImage, alt: t('kpiOverview.Availability.imageAlt')}}
                        isAutoScale={isAutoScale}
                        data={availabilityState}
                        chartId="availabilityEfficiency"
                        xAxisLabel={t('kpiOverview.XAxisLabel')}
                        yAxisLabel={t('kpiOverview.YAxisLabel')}
                        dataTestId="availabilityChart"
                    />

                    <Chart
                        isPlannedKPI
                        shouldDisplay={showPlannedKpis && Boolean(allocationEfficiencyLineChartValues)}
                        headline={t('kpiOverview.AllocationEfficiency.Name')}
                        description={t('kpiOverview.AllocationEfficiency.Description')}
                        image={{src: allocationEfficiencyImage, alt: t('kpiOverview.AllocationEfficiency.imageAlt')}}
                        isAutoScale={isAutoScale}
                        data={allocationEfficiencyState}
                        chartId="allocationEfficiency"
                        xAxisLabel={t('kpiOverview.XAxisLabel')}
                        yAxisLabel={t('kpiOverview.YAxisLabel')}
                        dataTestId="allocationEfficiencyChart"
                    />
                </>
            )}
        </Grid>
    );
};
