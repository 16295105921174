import gql from 'graphql-tag';

export const GET_WEEKLY_TEMPLATES = gql`
    query WeeklyTemplates {
        weeklyTemplates {
            id
            name
            events {
                dayOfWeek
                start
                end
                eventType
            }
        }
    }
`;
