import {css, styled} from '@sym/common-ui/material';
import {Calendar} from 'react-big-calendar';
import {Typography} from '@sym/common-ui/material';

export const StyledCalendarWrapper = styled('div')`
    height: 65vh;
`;

export const StyledTypography = styled(Typography)`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing(2)};
    `}
`;

// @ts-ignore
export const StyledCalendar = styled(Calendar)`
    ${({theme: {spacing, palette, breakpoints}}) => css`
        color: ${palette.text.secondary};

        .rbc-btn-group {
            button {
                color: ${palette.text.primary};
            }
        }

        .rbc-header {
            border-color: ${palette.background.paper};
        }

        .rbc-event {
            background-color: ${palette.success.main};
            border-color: ${palette.background.paper};
        }

        .rbc-today {
            background-color: ${palette.background.default};
        }

        .rbc-allday-cell {
            height: 0;
            visibility: hidden;
        }

        .rbc-time-header-content {
            border-left: 1px solid ${palette.background.paper};
        }

        .rbc-toolbar button {
            border: 1px solid ${palette.background.paper};
            :hover {
                background: ${palette.background.paper};
            }
            :active {
                background: ${palette.background.paper};
            }
            :focus {
                background: ${palette.background.paper};
            }
        }

        .rbc-toolbar-label {
            text-align: left;
            padding-left: ${spacing(4)};
        }

        .rbc-toolbar button {
            border: 1px solid ${palette.background.paper};
            :hover {
                background: ${palette.background.paper};
            }
            :active {
                background: ${palette.background.paper};
            }
            :focus {
                background: ${palette.background.paper};
            }
        }

        button:active:focus,
        .rbc-toolbar button.rbc-active:hover,
        .rbc-toolbar button.rbc-active:focus,
        button.rbc-active {
            color: ${palette.primary.main};
            background: ${palette.background.paper};
            border-color: ${palette.background.paper};
            :hover {
                background: ${palette.background.paper};
            }
        }

        .rbc-time-view {
            border: 1px solid ${palette.background.paper};
        }

        .rbc-time-content {
            border-left: 1px solid ${palette.background.paper};
            border-top: 2px solid ${palette.background.paper};
            border-bottom: 1px solid ${palette.background.paper};
        }

        .rbc-day-slot .rbc-time-slot {
            border-top: 1px solid ${palette.background.paper};
        }

        .rbc-timeslot-group {
            border-bottom: 1px solid ${palette.background.paper};
        }

        .rbc-events-container {
            border-left: 1px solid ${palette.background.paper};
        }

        .rbc-time-header.rbc-overflowing {
            border-right: 1px solid ${palette.background.paper};
        }

        .rbc-event-label {
            display: none;
        }

        ${breakpoints.up('md')} {
            .rbc-event-label {
                display: inline;
            }
        }
    `}
`;
