import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {CircularProgress, Fade, Grid, Typography} from '@sym/common-ui/material';
import {ApolloError} from '@apollo/client';
import {MachineStatesQuery, useMachineStatesLazyQuery} from '../../graphql/generated/graphql';
import {MACHINE_STATE_SUBSCRIPTION} from '../../subscriptions/MachineStates';
import {useTranslation} from 'react-i18next';
import {MachineFromQuery} from '../../App';
import {MachineGroupings, MachineGroupSelector, StyledFullHeightGrid} from '../../components';
import {MachineGrouping} from '../../components/MachineGroups/MachineGroupSelector';
import _ from 'lodash';

interface MachinesPageProps {
    machinesLoading: boolean;
    machinesError: ApolloError | undefined;
    installedMachines: MachineFromQuery[];
    uninstalledMachines: MachineFromQuery[];
}

const MachinesPageInner: React.FC<MachinesPageProps> = ({
    machinesLoading,
    machinesError,
    installedMachines,
    uninstalledMachines,
}) => {
    const {t} = useTranslation();

    const [grouping, setGrouping] = useState<MachineGrouping>('FACILITY');

    const machineIds = useMemo(() => {
        return installedMachines.map((machine) => machine.id);
    }, [installedMachines]);

    const [loadMachineStates, {loading: machineStatesLoading, error: machineStatesError, data, subscribeToMore}] =
        useMachineStatesLazyQuery();

    useEffect(() => {
        if (machineIds.length !== 0) {
            loadMachineStates({variables: {machineIds}});
        }
    }, [machineIds, loadMachineStates]);

    // useLayoutEffect needed for bug https://github.com/apollographql/apollo-client/issues/6437#issuecomment-792306730
    useLayoutEffect(() => {
        const unsubscribe = subscribeToMore?.({
            document: MACHINE_STATE_SUBSCRIPTION,
            variables: {machineIds},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev;
                }
                if (!prev.machineStates) {
                    return prev;
                }
                const index = prev.machineStates.findIndex(
                    // @ts-expect-error subscription and query types don't match
                    // query returns array, subscription single value. Should be
                    // fixed in backend so both return arrays
                    (state) => state.id === subscriptionData.data.machineStates.id
                );

                const state = prev.machineStates.slice();

                // @ts-ignore see above. It's an array but TS thinks it's an object
                state[index] = subscriptionData.data.machineStates;

                const newData: MachineStatesQuery = {
                    machineStates: state,
                };

                return newData;
            },
        });
        return () => {
            unsubscribe?.();
        };
    }, [machineIds, subscribeToMore]);

    const handleGroupingChange = (grouping: MachineGrouping) => {
        setGrouping(grouping);
    };

    const machineStates = data?.machineStates || [];

    if (machineStatesLoading || machinesLoading)
        return (
            <Grid container justifyContent="center" xs>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );

    if (machineStatesError || machinesError)
        return (
            <Grid container justifyContent="center" xs={12}>
                <Grid item>
                    <Typography variant="body2" align="center" color="error">
                        {t('Error')}
                    </Typography>
                </Grid>
            </Grid>
        );

    return (
        <StyledFullHeightGrid>
            <Fade in={Boolean(data)}>
                <Grid container spacing={4}>
                    <Grid container item xs={12} justifyContent="flex-end">
                        <Grid item>
                            <MachineGroupSelector grouping={grouping} onChange={handleGroupingChange} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <MachineGroupings
                            installedMachines={installedMachines}
                            uninstalledMachines={uninstalledMachines}
                            machineStates={machineStates}
                            machineGrouping={grouping}
                        />
                    </Grid>
                </Grid>
            </Fade>
        </StyledFullHeightGrid>
    );
};

export const MachinesPage = React.memo(MachinesPageInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
