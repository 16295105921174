import React from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid, Typography} from '@sym/common-ui/material';
import {useJobHistoryWithGroupedMessagesQuery} from '../../../graphql/generated/graphql';
import {StyledCard} from '../RelatedMessages.styled';
import {getTextColor} from '../util';

// quick fix for performance reasons
const MAX_MESSAGES = 200;

interface GroupedMessagesProps {
    assetId: string;
    jobId: string;
    isActiveJob?: boolean;
}

export const GroupedMessages: React.FC<GroupedMessagesProps> = ({assetId, jobId, isActiveJob}) => {
    const {t} = useTranslation();

    const {
        data: groupedMessageData,
        error: groupedMessageError,
        loading: groupedMessageLoading,
    } = useJobHistoryWithGroupedMessagesQuery({
        variables: {assetId, jobId},
        // needed, else we get problems with infinite re-rendering
        // of jobhistoryquery in quicklinks
        fetchPolicy: 'no-cache',
    });

    const groupedMessages = groupedMessageData?.asset?.job?.messages?.slice(0, MAX_MESSAGES) || [];

    if (groupedMessageLoading) {
        return (
            <Grid container justifyContent="center" xs={12}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    if (groupedMessageError) {
        return (
            <Grid container item xs={12} spacing={2}>
                <Grid item justifyContent="center" xs={12}>
                    <Typography variant="body2" color="error" align="center" gutterBottom>
                        {t('jobs.ErrorLoadingMessages')}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    if (groupedMessages.length === 0) {
        return (
            <Grid container justifyContent="center">
                <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                    {isActiveJob ? t('jobs.isActiveMessageInfo') : t('jobs.NoRelatedMessages')}
                </Typography>
            </Grid>
        );
    }

    return (
        <>
            {groupedMessages.map((groupedMessage, idx) => {
                return (
                    <Grid container item xs={12} data-testid={`related-groupedMessages-card-${idx}`} key={idx}>
                        <StyledCard messageType={groupedMessage.type ?? undefined}>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="overline"
                                        color={
                                            groupedMessage.type ? getTextColor(groupedMessage.type) : 'textSecondary'
                                        }
                                    >
                                        {groupedMessage.type}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="textPrimary">
                                        {t('jobs.messageCount')}: {groupedMessage.count}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {groupedMessage.messageId}: {groupedMessage.text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </StyledCard>
                    </Grid>
                );
            })}
        </>
    );
};
