import {KpiOverviewQuery} from '../../graphql/generated/graphql';
import {TimeData, MachineData} from '../../shared/types';
import {ApolloQueryResult} from '@apollo/client';
import {MachineFromQuery} from '../../App';
import {toPercent} from '../../util/dataFormatter';

type Key = 'availability' | 'technicalEfficiency' | 'setupRatio' | 'allocationEfficiency' | 'utilizationEfficiency';

export const extractDataFromQuery = (
    queryResult: ApolloQueryResult<KpiOverviewQuery>[],
    key: Key,
    installedMachines: MachineFromQuery[],
    selectedMachineIds: string[],
    id: string
): MachineData[] => {
    return queryResult.map((item, index) => {
        const rawValues = item.data?.kpiOverview?.[key]?.values;
        const values: TimeData[] = [];
        const reliabilityAverage = item.data.kpiOverview?.metaData?.reliabilityAverage;
        const binSize = item.data.kpiOverview?.metaData?.binSize.value;
        const reliabilities = item.data.kpiOverview?.metaData?.reliabilities;
        const plannedBusyRatio = item.data.kpiOverview?.plannedBusyRatio ?? undefined;

        rawValues?.forEach((value) => {
            if (value) {
                values.push({date: new Date(value.date), value: value.value || undefined});
            }
        });

        const machine = installedMachines.find((machine) => machine.id === selectedMachineIds[index]);

        const labelText = machine?.displayName || 'No Information';

        return {
            id,
            machineId: machine?.id,
            labelText,
            values,
            reliabilityAverage,
            binSize,
            reliabilities,
            kpiAveragePerMachine: item.data.kpiOverview?.[key]?.average,
            plannedBusyRatio,
        };
    });
};

export const LOCAL_STORAGE_KEY_PLANNED_KPIS = 'performanceanalyzer.showPlannedKpis';

export const modifyAverage = (average: number | null | undefined, noDataText: string): '>150%' | string => {
    if (average === undefined || average === null) {
        return noDataText;
    }
    if (average < 0) {
        return '0%';
    }
    if (average <= 1.5 && average >= 0) {
        return toPercent(average).toString() + '%';
    } else {
        return '>150%';
    }
};
