import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {
    Button,
    FormControl,
    Typography,
    Checkbox,
    Drawer,
    Divider,
    Grid,
    Tooltip,
    FormControlLabel,
} from '@sym/common-ui/material';
import {DevicesRounded, CloseRounded} from '@sym/common-ui/icons-material';

import {
    StyledWrapper,
    StyledHeaderWrapper,
    StyledIconWrapper,
    StyledFormGroup,
    StyledCloseButton,
    StyledCloseButtonWrapper,
    StyledButton,
    StyledGridItem,
} from './MachineSelector.styled';

import {MachineFromQuery} from '../../App';
import {useApplicationsQuery} from '../../graphql/generated/graphql';
import {getInstalledMachines} from '..';

interface MachineSelectorProps {
    machines: MachineFromQuery[];
    onSelectedMachinesChange(machineIds: string[]): void;
    selectedMachineIds: string[];
    tenantUserId?: string;
}

export const MachineSelector: React.FC<MachineSelectorProps> = ({
    machines,
    onSelectedMachinesChange,
    selectedMachineIds,
    tenantUserId,
}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [areAllMachinesSelected, setAreAllMachinesSelected] = useState(true);

    const {data: applicationsData} = useApplicationsQuery();

    const {installedMachines, uninstalledMachines} = getInstalledMachines(machines, applicationsData);

    const LOCAL_STORAGE_SELECTED_MACHINES = `performanceanalyzer.${tenantUserId}.selectedMachines`;

    const [newSelectedMachines, setNewSelectedMachines] = useState<string[]>([]);

    useEffect(() => {
        const newIds: string[] =
            JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_MACHINES) || 'null') || selectedMachineIds;
        setNewSelectedMachines(newIds);
    }, [selectedMachineIds, LOCAL_STORAGE_SELECTED_MACHINES]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setNewSelectedMachines([...newSelectedMachines, event.target.id]);
        } else {
            setNewSelectedMachines(newSelectedMachines.filter((id) => id !== event.target.id));
        }
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setAreAllMachinesSelected(true);
            setNewSelectedMachines(installedMachines.map((machine) => machine.id));
        } else {
            setAreAllMachinesSelected(false);
            setNewSelectedMachines([]);
        }
    };

    const areSomeMachinesSelected = selectedMachineIds.length !== machines.length && selectedMachineIds.length > 0;

    const handleClose = (): void => {
        setIsOpen(!isOpen);
        onSelectedMachinesChange(newSelectedMachines);
        localStorage.setItem(LOCAL_STORAGE_SELECTED_MACHINES, JSON.stringify(newSelectedMachines));
    };

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                onClick={() => setIsOpen(!isOpen)}
                startIcon={<DevicesRounded />}
                data-testid="machine-selector"
            >
                {t('Header.machineSelectorButton')}
            </Button>
            <Drawer anchor="right" open={isOpen} onClose={handleClose} data-testid="machine-selection-drawer">
                <Grid container direction="column">
                    <Grid item xs>
                        <StyledWrapper>
                            <FormControl component="fieldset">
                                <Grid container direction="column">
                                    <Grid item xs>
                                        <StyledCloseButtonWrapper>
                                            <StyledCloseButton
                                                color="primary"
                                                variant="text"
                                                onClick={() => setIsOpen(!isOpen)}
                                                startIcon={<CloseRounded />}
                                                data-testid="close"
                                            >
                                                {t('Close')}
                                            </StyledCloseButton>
                                        </StyledCloseButtonWrapper>
                                        <StyledHeaderWrapper>
                                            <StyledIconWrapper>
                                                <DevicesRounded color="primary" />
                                            </StyledIconWrapper>
                                            <Typography id="machine-ids" variant="h5" component="h3">
                                                {t('Header.selectedMachines')}
                                            </Typography>
                                        </StyledHeaderWrapper>
                                    </Grid>

                                    <Grid item xs>
                                        <StyledFormGroup>
                                            <FormControlLabel
                                                key="{}"
                                                label={`(${t('kpiOverview.unselectAll')})`}
                                                data-testid="machine-checkbox-control"
                                                control={
                                                    <Checkbox
                                                        id="id"
                                                        name={t('kpiOverview.unselectAll')}
                                                        color="primary"
                                                        indeterminate={areSomeMachinesSelected}
                                                        checked={areAllMachinesSelected}
                                                        onChange={handleSelectAllChange}
                                                    />
                                                }
                                            />
                                            <Divider light />

                                            {installedMachines
                                                // making sure uninstalled come after installed
                                                .concat(uninstalledMachines)
                                                .map((machine) => machine.id)
                                                .map((id) => {
                                                    const machine = machines.find((machine) => machine.id === id);
                                                    let labelText = '';
                                                    const isSelected = newSelectedMachines.includes(id);
                                                    if (id === 'bbbbbbbb-bbbb-bbbb-bbbb-222222222222') {
                                                        // debugger;
                                                    }
                                                    const isUninstalled = uninstalledMachines
                                                        .map((machine) => machine.id)
                                                        .includes(id);

                                                    if (!machine || !machine.displayName) {
                                                        labelText = t('NoInformation');
                                                    }

                                                    if (machine?.machineModel?.modelName) {
                                                        labelText = `${machine?.displayName} - ${_.startCase(
                                                            machine?.machineModel.modelName
                                                        )}`;
                                                    } else {
                                                        labelText = `${machine?.displayName}`;
                                                    }

                                                    const tooltipTitle = isUninstalled
                                                        ? t('kpiOverview.MachineNotInstalled')
                                                        : '';

                                                    return (
                                                        <Tooltip key={id} title={tooltipTitle} arrow>
                                                            <FormControlLabel
                                                                key={id}
                                                                label={labelText}
                                                                data-testid="machine-checkbox-control"
                                                                control={
                                                                    <Checkbox
                                                                        id={id}
                                                                        name={labelText}
                                                                        color="primary"
                                                                        disabled={isUninstalled}
                                                                        checked={isSelected && !isUninstalled}
                                                                        onChange={handleOnChange}
                                                                    />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    );
                                                })}
                                        </StyledFormGroup>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </StyledWrapper>
                    </Grid>
                    <Grid item container justifyContent="space-around" alignContent="stretch" direction="column">
                        <StyledGridItem item xs>
                            <StyledButton
                                fullWidth
                                color="primary"
                                variant="contained"
                                disabled={Boolean(newSelectedMachines.length === 0)}
                                onClick={handleClose}
                            >
                                {t('Apply')}
                            </StyledButton>
                        </StyledGridItem>
                    </Grid>
                </Grid>
            </Drawer>
        </>
    );
};
