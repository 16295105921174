import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDate: string;
  LocalDateTime: string;
  LocalTime: string;
  Upload: any;
  ZonedDateTime: string;
  _Any: any;
  _FieldSet: any;
};







export type AcceptAssetProposalPayload = {
  proposalId: Scalars['ID'];
  displayName: Scalars['String'];
  facilityId: Scalars['ID'];
};

export type Application = {
  __typename?: 'Application';
  id: Scalars['ID'];
  version: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  installations?: Maybe<Array<Maybe<ApplicationInstallation>>>;
  installConfigurationParameters?: Maybe<Array<Maybe<ConfigurationParameter>>>;
  license?: Maybe<License>;
  manufacturer?: Maybe<Manufacturer>;
  media?: Maybe<ApplicationMedia>;
  plugins?: Maybe<Array<Maybe<Plugin>>>;
  edgeModules?: Maybe<Array<Maybe<EdgeModule>>>;
  hardwareRequirements?: Maybe<HardwareRequirements>;
  portalExtensions?: Maybe<Array<Maybe<PortalExtension>>>;
};

export type ApplicationInstallation = {
  __typename?: 'ApplicationInstallation';
  asset: Asset;
  application: Application;
  installationDate?: Maybe<Scalars['ZonedDateTime']>;
  expiryDate?: Maybe<Scalars['ZonedDateTime']>;
  status?: Maybe<InstallationStatus>;
  version: Scalars['String'];
  initiator?: Maybe<TenantUser>;
  configurationValues?: Maybe<Array<Maybe<ConfigurationValue>>>;
  deploymentStatus?: Maybe<DeploymentStatus>;
};

export type ApplicationInstallationError = {
  __typename?: 'ApplicationInstallationError';
  context: Scalars['String'];
  message: Scalars['String'];
};

export type ApplicationInstallationErrors = {
  __typename?: 'ApplicationInstallationErrors';
  errors?: Maybe<Array<Maybe<ApplicationInstallationError>>>;
};

export type ApplicationInstallationRequest = {
  applicationId: Scalars['String'];
  machineId: Scalars['ID'];
  version: Scalars['String'];
  configuration?: Maybe<Array<Maybe<ConfigurationValueInput>>>;
};

export type ApplicationInstallationResult = ApplicationInstallation | ApplicationInstallationErrors;

export type ApplicationMedia = {
  __typename?: 'ApplicationMedia';
  teaserVideo?: Maybe<Scalars['String']>;
  teaserImage?: Maybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  avatar?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  edgeDevice?: Maybe<EdgeDevice>;
  facility?: Maybe<Facility>;
  hasConnectedCustomerMachine?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  job?: Maybe<JobHistoryItem>;
  jobHistory: Array<JobHistoryItem>;
  jobHistoryByRange: Array<JobHistoryItem>;
  machineModel?: Maybe<MachineModel>;
  machineState?: Maybe<MachineState>;
  serialNumber: Scalars['String'];
  tenant?: Maybe<Tenant>;
  unitModes: Array<UnitModeInterval>;
};


export type AssetJobArgs = {
  id: Scalars['ID'];
};


export type AssetJobHistoryArgs = {
  from: Scalars['LocalDateTime'];
  count: Scalars['Int'];
};


export type AssetJobHistoryByRangeArgs = {
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
};


export type AssetUnitModesArgs = {
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
};

export type BinSize = {
  __typename?: 'BinSize';
  value: Scalars['Float'];
  unit: BinSizeUnit;
};

export type BinSizeUnit =
  | 'SECONDS';

export type BrowserNotification = {
  __typename?: 'BrowserNotification';
  id: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  target?: Maybe<BrowserNotificationTarget>;
  senderName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ZonedDateTime']>;
};

export type BrowserNotificationTarget = {
  __typename?: 'BrowserNotificationTarget';
  target?: Maybe<Scalars['String']>;
  parameters?: Maybe<Array<Maybe<Parameter>>>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  id: Scalars['ID'];
  start: Scalars['LocalDateTime'];
  end: Scalars['LocalDateTime'];
  eventType: CalendarEventType;
};

export type CalendarEventInput = {
  start: Scalars['LocalDateTime'];
  end: Scalars['LocalDateTime'];
  eventType: CalendarEventType;
};

export type CalendarEventType =
  | 'ProductionTime';

export type CertificateChain = {
  __typename?: 'CertificateChain';
  certificateChain: Scalars['String'];
};

export type ChatCredentials = {
  __typename?: 'ChatCredentials';
  login: Scalars['String'];
  password: Scalars['String'];
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  id: Scalars['ID'];
  name: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  externalChatRoomId: Scalars['String'];
  participants?: Maybe<Array<TenantUser>>;
  whiteboards?: Maybe<Array<Maybe<Whiteboard>>>;
  videoConference: VideoConference;
  isArchived?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<Maybe<File>>>;
};

export type ClosingReport = {
  __typename?: 'ClosingReport';
  id: Scalars['ID'];
  isSolved: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
};

export type ConfigurationParameter = {
  __typename?: 'ConfigurationParameter';
  key: Scalars['String'];
  displayName: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
};

export type ConfigurationValue = {
  __typename?: 'ConfigurationValue';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type ConfigurationValueInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type CreateCustomerAddressInput = {
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  name: Scalars['String'];
  linkedTenantId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  status?: Maybe<CustomerStatusEnum>;
  serviceOrganisation: Scalars['ID'];
  address?: Maybe<CreateCustomerAddressInput>;
};

export type CreateFacilityInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateServiceOrganisationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateTenantAddressInput = {
  country: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
  number: Scalars['String'];
};

export type CreateTenantInput = {
  name: Scalars['String'];
  shortname: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  address?: Maybe<CreateTenantAddressInput>;
};

export type CsrInput = {
  csrPem: Scalars['String'];
  issuerCertificatePemChain?: Maybe<Scalars['String']>;
};

export type CustomFieldDefinition = {
  __typename?: 'CustomFieldDefinition';
  machineModelId: Scalars['String'];
  key: Scalars['String'];
  displayName: Scalars['String'];
  required: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['String']>;
  type: CustomFieldType;
  validation?: Maybe<Scalars['String']>;
};

export type CustomFieldType =
  | 'NUMBER'
  | 'BOOLEAN'
  | 'STRING'
  | 'DATE'
  | 'PASSWORD'
  | 'SELECT';

export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type CustomFieldValueInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  tenantId: Scalars['ID'];
  name: Scalars['String'];
  status: CustomerStatusEnum;
  linkedTenantId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  serviceOrganisation?: Maybe<ServiceOrganisation>;
  address?: Maybe<CustomerAddress>;
  machines: Array<CustomerMachine>;
  machinesCount?: Maybe<Scalars['Int']>;
  registeredMachinesCount?: Maybe<Scalars['Int']>;
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type CustomerAddressFilter = {
  country?: Maybe<StringExpression>;
  postalCode?: Maybe<StringExpression>;
  city?: Maybe<StringExpression>;
  street?: Maybe<StringExpression>;
  street2?: Maybe<StringExpression>;
  state?: Maybe<StringExpression>;
  and?: Maybe<Array<CustomerAddressFilter>>;
  or?: Maybe<Array<CustomerAddressFilter>>;
  not?: Maybe<CustomerAddressFilter>;
};

export type CustomerFilter = {
  id?: Maybe<IdExpression>;
  name?: Maybe<StringExpression>;
  status?: Maybe<CustomerStatusEnumExpression>;
  linkedTenantId?: Maybe<StringExpression>;
  externalId?: Maybe<StringExpression>;
  websiteUrl?: Maybe<StringExpression>;
  address?: Maybe<CustomerAddressFilter>;
  and?: Maybe<Array<CustomerFilter>>;
  or?: Maybe<Array<CustomerFilter>>;
  not?: Maybe<CustomerFilter>;
};

export type CustomerMachine = {
  __typename?: 'CustomerMachine';
  id: Scalars['ID'];
  tenant?: Maybe<Tenant>;
  serialNumber: Scalars['String'];
  machineModel?: Maybe<MachineModel>;
  displayName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  status?: Maybe<CustomerMachineStatus>;
  edgeDevice?: Maybe<EdgeDevice>;
  warrantyBegin?: Maybe<Scalars['String']>;
  warrantyEnd?: Maybe<Scalars['String']>;
  facilities: Array<Maybe<Facility>>;
  customer?: Maybe<Customer>;
};

export type CustomerMachineStatus =
  | 'New'
  | 'Registered'
  | 'Connected';

export type CustomerQueryResult = {
  __typename?: 'CustomerQueryResult';
  data: Array<Maybe<Customer>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CustomerSorter = {
  name?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  linkedTenantId?: Maybe<SortDirection>;
  externalId?: Maybe<SortDirection>;
  websiteUrl?: Maybe<SortDirection>;
};

export type CustomerStateStatistic = {
  __typename?: 'CustomerStateStatistic';
  status: CustomerStatusEnum;
  count: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type CustomerStatusEnum =
  | 'NEW'
  | 'INVITED'
  | 'ONBOARDING'
  | 'DEMO'
  | 'CONVERTED';

export type CustomerStatusEnumExpression = {
  is?: Maybe<CustomerStatusEnum>;
};

export type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export type DeploymentState =
  | 'UNKNOWN'
  | 'PENDING'
  | 'RUNNING'
  | 'FAILURE'
  | 'RESTARTING'
  | 'WARNING';

export type DeploymentStatus = {
  __typename?: 'DeploymentStatus';
  state: DeploymentState;
  message?: Maybe<Scalars['String']>;
  moduleMessages?: Maybe<Array<DeviceModuleStatusMessage>>;
  version?: Maybe<Scalars['String']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  info?: Maybe<Scalars['String']>;
};

export type DeviceModuleMessageReason =
  | 'GENERAL'
  | 'STATE'
  | 'CONFIGURATION'
  | 'COMMUNICATION'
  | 'ENVIRONMENT'
  | 'RESOURCES';

export type DeviceModuleMessageSeverity =
  | 'INFORMATION'
  | 'WARNING'
  | 'ERROR';

export type DeviceModuleStatusMessage = {
  __typename?: 'DeviceModuleStatusMessage';
  severity: DeviceModuleMessageSeverity;
  reason: DeviceModuleMessageReason;
  origin?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  text: Array<LocalizedText>;
};

export type DockerImage = {
  __typename?: 'DockerImage';
  repository?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type EdgeDevice = {
  __typename?: 'EdgeDevice';
  id: Scalars['ID'];
  deviceId: Scalars['String'];
  serialNumber?: Maybe<Scalars['String']>;
  type?: Maybe<EdgeDeviceType>;
  serviceProvider?: Maybe<Tenant>;
  operator?: Maybe<Tenant>;
  provisioner?: Maybe<Tenant>;
  connectedMachine?: Maybe<CustomerMachine>;
  connectedAsset?: Maybe<Asset>;
  iotHubStatus?: Maybe<IotHubStatus>;
  connected?: Maybe<Scalars['Boolean']>;
};

export type EdgeDeviceStatus = {
  __typename?: 'EdgeDeviceStatus';
  registrationState: Scalars['String'];
  connectionState: Scalars['String'];
};

export type EdgeDeviceType = {
  __typename?: 'EdgeDeviceType';
  id: Scalars['ID'];
  model?: Maybe<Scalars['String']>;
  articleNumber?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type EdgeModule = {
  __typename?: 'EdgeModule';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dockerImage?: Maybe<DockerImage>;
  version?: Maybe<Scalars['String']>;
};

export type ErrorDetail =
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  | 'UNKNOWN'
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  | 'FIELD_NOT_FOUND'
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  | 'INVALID_CURSOR'
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  | 'UNIMPLEMENTED'
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  | 'INVALID_ARGUMENT'
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  | 'DEADLINE_EXCEEDED'
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  | 'SERVICE_ERROR'
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  | 'THROTTLED_CPU'
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  | 'THROTTLED_CONCURRENCY'
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  | 'ENHANCE_YOUR_CALM'
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  | 'TCP_FAILURE'
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  | 'MISSING_RESOURCE';

export type ErrorType =
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  | 'UNKNOWN'
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  | 'INTERNAL'
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  | 'NOT_FOUND'
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  | 'UNAUTHENTICATED'
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  | 'PERMISSION_DENIED'
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  | 'BAD_REQUEST'
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  | 'UNAVAILABLE'
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  | 'FAILED_PRECONDITION';

export type Facility = {
  __typename?: 'Facility';
  id: Scalars['ID'];
  tenant: Tenant;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type Feature = {
  __typename?: 'Feature';
  id: Scalars['ID'];
  key: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  applicationId: Scalars['ID'];
  permissions: Array<Maybe<Permission>>;
};

export type File = {
  __typename?: 'File';
  uri?: Maybe<Scalars['String']>;
  externalFileName?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type HardwareRequirements = {
  __typename?: 'HardwareRequirements';
  cpuClockInGhz?: Maybe<Scalars['Float']>;
  ramInGb?: Maybe<Scalars['Float']>;
  diskSpaceInMb?: Maybe<Scalars['Float']>;
};

export type IdExpression = {
  is?: Maybe<Scalars['ID']>;
};

export type InstallationStatus =
  | 'INSTALLING'
  | 'INSTALLED'
  | 'UNINSTALLING'
  | 'UNINSTALLED'
  | 'FAILED';

export type IntegerExpression = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
};

export type IotHubStatus =
  | 'New'
  | 'Enabled'
  | 'Disabled';

export type JobHistoryItem = {
  __typename?: 'JobHistoryItem';
  id: Scalars['ID'];
  jobExecutionId: Scalars['String'];
  mainProgramName?: Maybe<Scalars['String']>;
  mainProgramFile?: Maybe<Scalars['String']>;
  startTimestamp?: Maybe<Scalars['LocalDateTime']>;
  endTimestamp?: Maybe<Scalars['LocalDateTime']>;
  duration?: Maybe<Scalars['Int']>;
  plannedRuntime?: Maybe<Scalars['Int']>;
  unitStates?: Maybe<Array<UnitStateInterval>>;
  messages?: Maybe<Array<JobMessage>>;
  hasErrors: Scalars['Boolean'];
};


export type JobHistoryItemMessagesArgs = {
  grouped?: Maybe<Scalars['Boolean']>;
};

export type JobInfo = {
  __typename?: 'JobInfo';
  id?: Maybe<Scalars['ID']>;
  estimatedRemainingRuntime?: Maybe<Scalars['Int']>;
  estimatedRuntime?: Maybe<Scalars['Int']>;
  estimatedEndTimestamp?: Maybe<Scalars['LocalDateTime']>;
  mainProgramName?: Maybe<Scalars['String']>;
  mainProgramFile?: Maybe<Scalars['String']>;
  plannedRuntime?: Maybe<Scalars['Int']>;
  startTimestamp?: Maybe<Scalars['LocalDateTime']>;
};

export type JobMessage = {
  __typename?: 'JobMessage';
  id: Scalars['ID'];
  logType: LogType;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  timestamp: Scalars['LocalDateTime'];
  occurrenceStart: Scalars['LocalDateTime'];
  occurrenceEnd?: Maybe<Scalars['LocalDateTime']>;
  count?: Maybe<Scalars['Int']>;
};

export type KpiData = {
  __typename?: 'KpiData';
  average?: Maybe<Scalars['Float']>;
  values: Array<Maybe<KpiValue>>;
};

export type KpiDataRequestInput = {
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
  machineIds: Array<Scalars['ID']>;
};

export type KpiOverview = {
  __typename?: 'KpiOverview';
  availability?: Maybe<KpiData>;
  technicalEfficiency?: Maybe<KpiData>;
  setupRatio?: Maybe<KpiData>;
  allocationEfficiency?: Maybe<KpiData>;
  utilizationEfficiency?: Maybe<KpiData>;
  plannedBusyRatio?: Maybe<KpiData>;
  metaData?: Maybe<MetaData>;
};

export type KpiValue = {
  __typename?: 'KpiValue';
  date: Scalars['LocalDateTime'];
  value?: Maybe<Scalars['Float']>;
};

export type License =
  | 'FREE'
  | 'PAID'
  | 'DEMO';




export type LocalizedText = {
  __typename?: 'LocalizedText';
  language: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type LogEntry = {
  __typename?: 'LogEntry';
  serviceCaseId: Scalars['ID'];
  changedByUser: TenantUser;
  changedAt: Scalars['ZonedDateTime'];
  fieldName: Scalars['String'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
};

export type LogType =
  | 'UNIT_MESSAGE'
  | 'UNIT_MESSAGE_EN'
  | 'USER_MESSAGE';

export type Machine = {
  __typename?: 'Machine';
  id: Scalars['ID'];
  asset?: Maybe<Asset>;
  customerMachine?: Maybe<CustomerMachine>;
  edgeDevice?: Maybe<EdgeDevice>;
  machineModel?: Maybe<MachineModel>;
  serialNumber?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
};

export type MachineComponent = {
  __typename?: 'MachineComponent';
  id: Scalars['ID'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
};

export type MachineDocument = {
  __typename?: 'MachineDocument';
  id: Scalars['ID'];
  tenant?: Maybe<Tenant>;
  documentUrl: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  documentType: MachineDocumentType;
  language?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['ZonedDateTime'];
  owner: TenantUser;
  documentRelation: MachineDocumentRelationType;
  machineModel?: Maybe<MachineModel>;
  asset?: Maybe<Asset>;
};

export type MachineDocumentRelationType =
  | 'SERVICE_PROVIDER'
  | 'OPERATOR';

export type MachineDocumentType =
  | 'DOCUMENT'
  | 'IMAGE'
  | 'VIDEO'
  | 'LINK';

export type MachineMessage = {
  __typename?: 'MachineMessage';
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['LocalDateTime']>;
};

export type MachineModel = {
  __typename?: 'MachineModel';
  id: Scalars['ID'];
  tenant?: Maybe<Tenant>;
  modelName: Scalars['String'];
  technology: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  components?: Maybe<Array<Maybe<MachineComponent>>>;
  tunnelConfigs?: Maybe<Array<TunnelConfig>>;
};

export type MachineState = {
  __typename?: 'MachineState';
  id?: Maybe<Scalars['ID']>;
  tenantId?: Maybe<Scalars['ID']>;
  machineId?: Maybe<Scalars['ID']>;
  activeJob?: Maybe<JobInfo>;
  lastSourceTimestampUTC?: Maybe<Scalars['ZonedDateTime']>;
  lastDocumentUpdateUTC?: Maybe<Scalars['ZonedDateTime']>;
  lastSourceTimestampOffset?: Maybe<Scalars['Int']>;
  unitMessage?: Maybe<MachineMessage>;
  unitMessageEN?: Maybe<MachineMessage>;
  userMessage?: Maybe<MachineMessage>;
  unitStatusOrderRelation?: Maybe<UnitStatusOrderRelation>;
  unitStatusMode?: Maybe<UnitStatusMode>;
  unitStatusState?: Maybe<UnitStatusState>;
};

export type MailAddress = {
  value?: Maybe<Scalars['String']>;
  type?: Maybe<MailAddressType>;
};

export type MailAddressType =
  | 'USER_ID'
  | 'TENANT_ID'
  | 'MAIL';

export type MailBodyType =
  | 'PLAIN'
  | 'HTML';

export type MailMessage = {
  subject: Scalars['String'];
  body: Scalars['String'];
  type?: Maybe<MailBodyType>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  reliabilities: Array<Reliability>;
  reliabilityAverage: Scalars['Float'];
  binSize: BinSize;
};

export type Mutation = {
  __typename?: 'Mutation';
  createTenant: Tenant;
  updateTenant: Tenant;
  deleteTenant: Scalars['ID'];
  updateTenantUser: TenantUser;
  deleteTenantUser: Scalars['ID'];
  inviteTenantUser: Scalars['String'];
  newInviteTenantUser: Scalars['String'];
  validateUserInvitation: Scalars['Boolean'];
  redeemUserInvitation: Scalars['Boolean'];
  createFacility: Facility;
  updateFacility: Facility;
  deleteFacility: Scalars['ID'];
  createServiceOrganisation: ServiceOrganisation;
  updateServiceOrganisation: ServiceOrganisation;
  deleteServiceOrganisation: Scalars['ID'];
  inviteTenant?: Maybe<Scalars['Boolean']>;
  acceptTenantInvitation?: Maybe<Scalars['Boolean']>;
  storeTheme: Theme;
  deleteTheme: Scalars['ID'];
  createServiceCase?: Maybe<ServiceCase>;
  createServiceorganisation?: Maybe<ServiceOrganization>;
  assignServiceCase?: Maybe<ServiceCase>;
  assignServiceCaseToMe?: Maybe<ServiceCase>;
  unassignServiceCase?: Maybe<ServiceCase>;
  updateState?: Maybe<ServiceCase>;
  updatePriority?: Maybe<ServiceCase>;
  joinChat?: Maybe<ServiceCase>;
  createClosingReport?: Maybe<ServiceCase>;
  watchServiceCase?: Maybe<ServiceCase>;
  unwatchServiceCase?: Maybe<ServiceCase>;
  createRemoteAccess?: Maybe<RemoteAccess>;
  createTunnelConfig?: Maybe<TunnelConfig>;
  startSession?: Maybe<RemoteAccessSession>;
  stopSession?: Maybe<RemoteAccessSession>;
  updateRemoteAccess?: Maybe<RemoteAccess>;
  sendMail?: Maybe<Array<Maybe<SendMailResponse>>>;
  setBrowserNotificationAsRead?: Maybe<BrowserNotification>;
  createAsset?: Maybe<Asset>;
  updateAsset?: Maybe<Asset>;
  deleteAsset?: Maybe<Scalars['Boolean']>;
  assignEdgeDeviceToAsset?: Maybe<Asset>;
  assignMachineModelToAsset?: Maybe<Asset>;
  assignAssetToFacility?: Maybe<Asset>;
  createMachineDocument?: Maybe<MachineDocument>;
  deleteMachineDocument?: Maybe<Scalars['Boolean']>;
  createMachineModel?: Maybe<MachineModel>;
  updateMachineModel?: Maybe<MachineModel>;
  deleteMachineModel?: Maybe<Scalars['Boolean']>;
  createCustomerMachine?: Maybe<CustomerMachine>;
  updateCustomerMachine?: Maybe<CustomerMachine>;
  deleteCustomerMachine?: Maybe<Scalars['Boolean']>;
  assignEdgeDeviceToCustomerMachine?: Maybe<CustomerMachine>;
  assignMachineModelToCustomerMachine?: Maybe<CustomerMachine>;
  createEdgeDevice?: Maybe<EdgeDevice>;
  deleteEdgeDevice?: Maybe<Scalars['Boolean']>;
  synchronizeIotHubDevicesToEdgeDevices?: Maybe<Scalars['Boolean']>;
  acceptAssetProposal?: Maybe<Asset>;
  acceptAssetProposals?: Maybe<Scalars['Boolean']>;
  linkMachines?: Maybe<Scalars['Boolean']>;
  unlinkMachines?: Maybe<Scalars['Boolean']>;
  uploadFile: File;
  signEdgeDeviceInstallationCSR?: Maybe<CertificateChain>;
  signCustomerProvisionerCSR?: Maybe<CertificateChain>;
  signEdgeDeviceIdentityCSR?: Maybe<CertificateChain>;
  signEdgeDeviceCACSR?: Maybe<CertificateChain>;
  createCalendarEvents: Array<CalendarEvent>;
  updateCalendarEvent: CalendarEvent;
  deleteCalendarEvent: Scalars['ID'];
  createWeeklyTemplate: WeeklyTemplate;
  updateWeeklyTemplate: WeeklyTemplate;
  deleteWeeklyTemplate: Scalars['ID'];
  applyWeeklyTemplate: Array<CalendarEvent>;
  createCustomer?: Maybe<Customer>;
  updateCustomer?: Maybe<Customer>;
  deleteCustomer?: Maybe<Scalars['ID']>;
  addFile?: Maybe<ChatRoom>;
  createChatRoom?: Maybe<ChatRoom>;
  createWhiteboard?: Maybe<Whiteboard>;
  installApplication?: Maybe<ApplicationInstallationResult>;
  createTenantRole?: Maybe<TenantRole>;
  deleteTenantRole?: Maybe<Scalars['Boolean']>;
  createFeature?: Maybe<Feature>;
  deleteFeature?: Maybe<Scalars['Boolean']>;
  createPermission?: Maybe<Permission>;
  deletePermission?: Maybe<Scalars['Boolean']>;
  createUserRole?: Maybe<UserRole>;
  deleteUserRole?: Maybe<Scalars['Boolean']>;
  createScope?: Maybe<Scope>;
  deleteScope?: Maybe<Scalars['Boolean']>;
  assignFeatureToTenantRole: TenantRole;
  unassignFeatureFromTenantRole: TenantRole;
  assignPermissionToFeature: Feature;
  unassignPermissionFromFeature: Feature;
  assignPermissionToUserRole: UserRole;
  unassignPermissionFromUserRole: UserRole;
  assignUserRoleToUser: TenantUser;
  unassignUserRoleFromUser: TenantUser;
  assignTenantRoleToTenant: Tenant;
  unassignTenantRoleFromTenant: Tenant;
};


export type MutationCreateTenantArgs = {
  tenant: CreateTenantInput;
};


export type MutationUpdateTenantArgs = {
  tenant: UpdateTenantInput;
};


export type MutationDeleteTenantArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTenantUserArgs = {
  user?: Maybe<UpdateTenantUserInput>;
};


export type MutationDeleteTenantUserArgs = {
  id: Scalars['ID'];
};


export type MutationInviteTenantUserArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type MutationNewInviteTenantUserArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type MutationValidateUserInvitationArgs = {
  invitationId: Scalars['ID'];
  email: Scalars['String'];
  tenantId: Scalars['ID'];
};


export type MutationRedeemUserInvitationArgs = {
  invitationId: Scalars['ID'];
  email: Scalars['String'];
  tenantId: Scalars['ID'];
  identityId: Scalars['ID'];
};


export type MutationCreateFacilityArgs = {
  facility: CreateFacilityInput;
};


export type MutationUpdateFacilityArgs = {
  facility: UpdateFacilityInput;
};


export type MutationDeleteFacilityArgs = {
  id: Scalars['ID'];
};


export type MutationCreateServiceOrganisationArgs = {
  serviceOrganisation: CreateServiceOrganisationInput;
};


export type MutationUpdateServiceOrganisationArgs = {
  serviceOrganisation: UpdateServiceOrganisationInput;
};


export type MutationDeleteServiceOrganisationArgs = {
  id: Scalars['ID'];
};


export type MutationInviteTenantArgs = {
  email: Scalars['String'];
  tenantName?: Maybe<Scalars['String']>;
};


export type MutationAcceptTenantInvitationArgs = {
  token: Scalars['String'];
  user?: Maybe<TenantInvitationUserInput>;
  tenant?: Maybe<TenantInvitationTenantInput>;
};


export type MutationStoreThemeArgs = {
  properties: Scalars['String'];
  image1?: Maybe<Scalars['Upload']>;
  image2?: Maybe<Scalars['Upload']>;
  image3?: Maybe<Scalars['Upload']>;
};


export type MutationCreateServiceCaseArgs = {
  serviceCase: NewServiceCaseRequest;
};


export type MutationCreateServiceorganisationArgs = {
  serviceOrganization: NewServiceOrganizationRequest;
};


export type MutationAssignServiceCaseArgs = {
  serviceCaseId: Scalars['ID'];
  assignee: Scalars['String'];
};


export type MutationAssignServiceCaseToMeArgs = {
  serviceCaseId: Scalars['ID'];
};


export type MutationUnassignServiceCaseArgs = {
  serviceCaseId: Scalars['ID'];
};


export type MutationUpdateStateArgs = {
  serviceCaseId: Scalars['ID'];
  stateId: Scalars['ID'];
};


export type MutationUpdatePriorityArgs = {
  serviceCaseId: Scalars['ID'];
  priority: Scalars['Int'];
};


export type MutationJoinChatArgs = {
  serviceCaseId: Scalars['ID'];
};


export type MutationCreateClosingReportArgs = {
  serviceCaseId: Scalars['ID'];
  stateId: Scalars['ID'];
  isSolved: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
};


export type MutationWatchServiceCaseArgs = {
  serviceCaseId: Scalars['ID'];
};


export type MutationUnwatchServiceCaseArgs = {
  serviceCaseId: Scalars['ID'];
};


export type MutationCreateRemoteAccessArgs = {
  serviceCaseId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateTunnelConfigArgs = {
  displayId: Scalars['String'];
  machineModelId: Scalars['String'];
  config: RemoteAccessSessionConfigurationInput;
};


export type MutationStartSessionArgs = {
  remoteAccessId: Scalars['ID'];
  config: RemoteAccessSessionConfigurationInput;
};


export type MutationStopSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationUpdateRemoteAccessArgs = {
  id: Scalars['String'];
  serviceCaseId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
};


export type MutationSendMailArgs = {
  request: Array<SendMailRequest>;
};


export type MutationSetBrowserNotificationAsReadArgs = {
  notificationId: Scalars['ID'];
};


export type MutationCreateAssetArgs = {
  serialNumber: Scalars['String'];
  facilityId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type MutationUpdateAssetArgs = {
  assetId: Scalars['ID'];
  serialNumber: Scalars['String'];
  facilityId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type MutationDeleteAssetArgs = {
  assetId: Scalars['ID'];
};


export type MutationAssignEdgeDeviceToAssetArgs = {
  assetId: Scalars['ID'];
  edgeDeviceId: Scalars['ID'];
};


export type MutationAssignMachineModelToAssetArgs = {
  assetId: Scalars['ID'];
  machineModelId: Scalars['ID'];
};


export type MutationAssignAssetToFacilityArgs = {
  assetId: Scalars['ID'];
  facilityId: Scalars['ID'];
};


export type MutationCreateMachineDocumentArgs = {
  documentUrl: Scalars['String'];
  documentType?: Maybe<MachineDocumentType>;
  label?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  documentRelation: MachineDocumentRelationType;
  machineModelId?: Maybe<Scalars['ID']>;
  assetId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteMachineDocumentArgs = {
  machineDocumentId: Scalars['ID'];
};


export type MutationCreateMachineModelArgs = {
  modelName: Scalars['String'];
  technology: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMachineModelArgs = {
  machineModelId: Scalars['ID'];
  modelName: Scalars['String'];
  technology: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteMachineModelArgs = {
  machineModelId: Scalars['ID'];
};


export type MutationCreateCustomerMachineArgs = {
  customerId: Scalars['ID'];
  serialNumber: Scalars['String'];
  machineModelId: Scalars['ID'];
  warrantyBegin?: Maybe<Scalars['String']>;
  warrantyEnd?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type MutationUpdateCustomerMachineArgs = {
  customerMachineId: Scalars['ID'];
  customerId: Scalars['ID'];
  serialNumber: Scalars['String'];
  machineModelId: Scalars['ID'];
  warrantyBegin?: Maybe<Scalars['String']>;
  warrantyEnd?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


export type MutationDeleteCustomerMachineArgs = {
  customerMachineId: Scalars['ID'];
};


export type MutationAssignEdgeDeviceToCustomerMachineArgs = {
  customerMachineId: Scalars['ID'];
  edgeDeviceId: Scalars['ID'];
};


export type MutationAssignMachineModelToCustomerMachineArgs = {
  customerMachineId: Scalars['ID'];
  machineModelId: Scalars['ID'];
};


export type MutationCreateEdgeDeviceArgs = {
  deviceId: Scalars['String'];
};


export type MutationDeleteEdgeDeviceArgs = {
  edgeDeviceId: Scalars['ID'];
};


export type MutationAcceptAssetProposalArgs = {
  proposalId: Scalars['ID'];
  displayName: Scalars['String'];
  facilityId: Scalars['ID'];
};


export type MutationAcceptAssetProposalsArgs = {
  payloads?: Maybe<Array<Maybe<AcceptAssetProposalPayload>>>;
};


export type MutationLinkMachinesArgs = {
  customerMachineId: Scalars['ID'];
  assetId: Scalars['ID'];
};


export type MutationUnlinkMachinesArgs = {
  customerMachineId: Scalars['ID'];
  assetId: Scalars['ID'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationSignEdgeDeviceInstallationCsrArgs = {
  edgeDeviceInstallationCSR?: Maybe<CsrInput>;
  csrSignature: Scalars['String'];
  targetTenantId?: Maybe<Scalars['String']>;
  deviceType: Scalars['String'];
  hasTpm: Scalars['Boolean'];
};


export type MutationSignCustomerProvisionerCsrArgs = {
  provisionerCSR?: Maybe<CsrInput>;
};


export type MutationSignEdgeDeviceIdentityCsrArgs = {
  edgeDeviceIdentityCSR?: Maybe<CsrInput>;
  csrSignature: Scalars['String'];
};


export type MutationSignEdgeDeviceCacsrArgs = {
  edgeDeviceCACSR?: Maybe<CsrInput>;
  csrSignature: Scalars['String'];
};


export type MutationCreateCalendarEventsArgs = {
  data: Array<CalendarEventInput>;
  machineId: Scalars['ID'];
};


export type MutationUpdateCalendarEventArgs = {
  data: CalendarEventInput;
  eventId: Scalars['ID'];
  machineId: Scalars['ID'];
};


export type MutationDeleteCalendarEventArgs = {
  eventId: Scalars['ID'];
  machineId: Scalars['ID'];
};


export type MutationCreateWeeklyTemplateArgs = {
  data: WeeklyTemplateInput;
};


export type MutationUpdateWeeklyTemplateArgs = {
  data: WeeklyTemplateInput;
  templateId: Scalars['ID'];
};


export type MutationDeleteWeeklyTemplateArgs = {
  templateId: Scalars['ID'];
};


export type MutationApplyWeeklyTemplateArgs = {
  templateId: Scalars['ID'];
  machineId: Scalars['ID'];
  start: Scalars['LocalDate'];
  end: Scalars['LocalDate'];
};


export type MutationCreateCustomerArgs = {
  customer: CreateCustomerInput;
};


export type MutationUpdateCustomerArgs = {
  customer: UpdateCustomerInput;
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationAddFileArgs = {
  chatRoomId: Scalars['ID'];
  fileInput: NewFileInput;
};


export type MutationCreateChatRoomArgs = {
  chatRoomRequest: NewChatRoomRequest;
};


export type MutationCreateWhiteboardArgs = {
  chatRoomId: Scalars['ID'];
};


export type MutationInstallApplicationArgs = {
  installationRequest: ApplicationInstallationRequest;
};


export type MutationCreateTenantRoleArgs = {
  name: Scalars['String'];
};


export type MutationDeleteTenantRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFeatureArgs = {
  key: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  applicationId: Scalars['ID'];
};


export type MutationDeleteFeatureArgs = {
  key: Scalars['String'];
};


export type MutationCreatePermissionArgs = {
  key: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  applicationId: Scalars['ID'];
};


export type MutationDeletePermissionArgs = {
  key: Scalars['String'];
};


export type MutationCreateUserRoleArgs = {
  name: Scalars['String'];
};


export type MutationDeleteUserRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateScopeArgs = {
  id: Scalars['ID'];
  scopeType: ScopeType;
};


export type MutationDeleteScopeArgs = {
  id: Scalars['ID'];
};


export type MutationAssignFeatureToTenantRoleArgs = {
  featureKey: Scalars['String'];
  tenantRoleId: Scalars['ID'];
};


export type MutationUnassignFeatureFromTenantRoleArgs = {
  featureKey: Scalars['String'];
  tenantRoleId: Scalars['ID'];
};


export type MutationAssignPermissionToFeatureArgs = {
  permissionKey: Scalars['String'];
  featureKey: Scalars['String'];
};


export type MutationUnassignPermissionFromFeatureArgs = {
  permissionKey: Scalars['String'];
  featureKey: Scalars['String'];
};


export type MutationAssignPermissionToUserRoleArgs = {
  permissionKey: Scalars['String'];
  userRoleId: Scalars['ID'];
};


export type MutationUnassignPermissionFromUserRoleArgs = {
  permissionKey: Scalars['String'];
  userRoleId: Scalars['ID'];
};


export type MutationAssignUserRoleToUserArgs = {
  userRoleId: Scalars['ID'];
  userId: Scalars['ID'];
  scopeId: Scalars['ID'];
};


export type MutationUnassignUserRoleFromUserArgs = {
  userRoleAssignmentId: Scalars['ID'];
};


export type MutationAssignTenantRoleToTenantArgs = {
  tenantRoleId: Scalars['ID'];
  tenantId: Scalars['ID'];
};


export type MutationUnassignTenantRoleFromTenantArgs = {
  tenantRoleId: Scalars['ID'];
  tenantId: Scalars['ID'];
};

export type NewChatRoomRequest = {
  context?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  participantIds?: Maybe<Array<Scalars['String']>>;
};

export type NewFileInput = {
  uri?: Maybe<Scalars['String']>;
  externalFileName?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type NewServiceCaseRequest = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  severity: Severity;
  assetId: Scalars['String'];
  customFields?: Maybe<Array<Maybe<CustomFieldValueInput>>>;
};

export type NewServiceOrganizationRequest = {
  name: Scalars['String'];
  zoneId: Scalars['String'];
  shedule?: Maybe<Array<ServiceSheduleInput>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  totalElements?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  fromElement?: Maybe<Scalars['Int']>;
  untilElement?: Maybe<Scalars['Int']>;
};

export type PaginationInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type Parameter = {
  __typename?: 'Parameter';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  key: Scalars['String'];
  displayName: Scalars['String'];
  description: Scalars['String'];
  applicationId: Scalars['ID'];
};

export type Plugin = {
  __typename?: 'Plugin';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  license?: Maybe<License>;
  edgeModules?: Maybe<Array<Maybe<EdgeModule>>>;
  availableFor?: Maybe<Array<Maybe<Application>>>;
};

export type PortalExtension = {
  __typename?: 'PortalExtension';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  menuItemName?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProposedAsset = {
  __typename?: 'ProposedAsset';
  proposalId: Scalars['ID'];
  machineModel?: Maybe<MachineModel>;
  serialNumber?: Maybe<Scalars['String']>;
  proposedBy?: Maybe<Tenant>;
  proposedFor?: Maybe<Tenant>;
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service?: Maybe<_Service>;
  application?: Maybe<Application>;
  applications?: Maybe<Array<Maybe<Application>>>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  assignedScopesOfPermissionForUser?: Maybe<Array<Maybe<Scope>>>;
  assignedUserRolesOfUser: Array<UserRoleAssignment>;
  assignedUsersOfUserRole: Array<UserRoleAssignment>;
  browserNotifications?: Maybe<Array<Maybe<BrowserNotification>>>;
  calendarEvents: Array<CalendarEvent>;
  chatCredentials?: Maybe<ChatCredentials>;
  chatRoom?: Maybe<ChatRoom>;
  chatRooms?: Maybe<Array<Maybe<ChatRoom>>>;
  customFieldDefinitions?: Maybe<Array<Maybe<CustomFieldDefinition>>>;
  customer?: Maybe<Customer>;
  customerMachine?: Maybe<CustomerMachine>;
  customerMachines: Array<CustomerMachine>;
  customerStateStatistics: Array<Maybe<CustomerStateStatistic>>;
  customers: CustomerQueryResult;
  deploymentStatus?: Maybe<DeploymentStatus>;
  edgeDevice?: Maybe<EdgeDevice>;
  edgeDeviceByDeviceId?: Maybe<EdgeDevice>;
  edgeDevices: Array<EdgeDevice>;
  facilities: Array<Facility>;
  facility: Facility;
  feature?: Maybe<Feature>;
  features: Array<Feature>;
  featuresOfApplication: Array<Feature>;
  featuresOfTenant: Array<Feature>;
  file?: Maybe<File>;
  getConnectedServiceProviderTenants: Array<Tenant>;
  getEdgeDeviceStatus?: Maybe<EdgeDeviceStatus>;
  getIssuerCAChain?: Maybe<CertificateChain>;
  getLinkedCustomers?: Maybe<Array<Maybe<Customer>>>;
  getSSHCACertificatesForDevice?: Maybe<CertificateChain>;
  hasProvisionPermission?: Maybe<Scalars['Boolean']>;
  hasTenantFeature: Scalars['Boolean'];
  isServiceOrganizationActive: Scalars['Boolean'];
  isUserAuthorized: Scalars['Boolean'];
  isUserAuthorizedWithScope: Scalars['Boolean'];
  kpiOverview?: Maybe<KpiOverview>;
  machine?: Maybe<Machine>;
  machineDocument?: Maybe<MachineDocument>;
  machineDocuments: Array<Maybe<MachineDocument>>;
  machineModel?: Maybe<MachineModel>;
  machineModels: Array<MachineModel>;
  machineStates: Array<MachineState>;
  machines: Array<Machine>;
  myTenantUser: TenantUser;
  permission?: Maybe<Permission>;
  permissions: Array<Permission>;
  permissionsOfApplication: Array<Permission>;
  permissionsOfCurrentUser: Array<Permission>;
  proposedAssets: Array<Maybe<ProposedAsset>>;
  remoteAccess?: Maybe<RemoteAccess>;
  remoteAccesses?: Maybe<Array<Maybe<RemoteAccess>>>;
  scopes: Array<Scope>;
  serviceCase?: Maybe<ServiceCase>;
  serviceCasesForAsset?: Maybe<Array<ServiceCase>>;
  serviceCasesForAssets?: Maybe<Array<Maybe<ServiceCase>>>;
  serviceCasesForCustomerMachines?: Maybe<Array<Maybe<ServiceCase>>>;
  serviceOrganisation: ServiceOrganisation;
  serviceOrganisations: Array<ServiceOrganisation>;
  serviceOrganizations?: Maybe<Array<ServiceOrganization>>;
  state?: Maybe<State>;
  states?: Maybe<Array<Maybe<State>>>;
  tenant: Tenant;
  tenantInvitation?: Maybe<TenantInvitation>;
  tenantRole?: Maybe<TenantRole>;
  tenantRoles: Array<Maybe<TenantRole>>;
  tenantRolesOfTenant: Array<TenantRole>;
  tenantUser: TenantUser;
  tenantUsers: Array<TenantUser>;
  tenants: Array<Tenant>;
  theme: Theme;
  tunnelConfig?: Maybe<TunnelConfig>;
  tunnelConfigs?: Maybe<Array<Maybe<TunnelConfig>>>;
  tunnelConfigsByMachineModel?: Maybe<Array<Maybe<TunnelConfig>>>;
  userRole?: Maybe<UserRole>;
  userRoles: Array<UserRole>;
  usersWithPermission: Array<Scalars['ID']>;
  weeklyTemplates: Array<WeeklyTemplate>;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type QueryApplicationArgs = {
  id: Scalars['String'];
  version: Scalars['String'];
};


export type QueryAssetArgs = {
  assetId: Scalars['ID'];
};


export type QueryAssignedScopesOfPermissionForUserArgs = {
  permissionKey: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryAssignedUserRolesOfUserArgs = {
  userId: Scalars['ID'];
};


export type QueryAssignedUsersOfUserRoleArgs = {
  userRoleId: Scalars['ID'];
};


export type QueryBrowserNotificationsArgs = {
  minNumberOfNotificationsRequested: Scalars['Int'];
};


export type QueryCalendarEventsArgs = {
  machineId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
};


export type QueryChatRoomArgs = {
  id: Scalars['ID'];
};


export type QueryCustomFieldDefinitionsArgs = {
  machineId?: Maybe<Scalars['String']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerMachineArgs = {
  customerMachineId: Scalars['ID'];
};


export type QueryCustomerStateStatisticsArgs = {
  where?: Maybe<CustomerFilter>;
};


export type QueryCustomersArgs = {
  where?: Maybe<CustomerFilter>;
  pagination?: Maybe<PaginationInput>;
  sortBy?: Maybe<Array<CustomerSorter>>;
};


export type QueryDeploymentStatusArgs = {
  assetId?: Maybe<Scalars['ID']>;
  appId?: Maybe<Scalars['ID']>;
};


export type QueryEdgeDeviceArgs = {
  edgeDeviceId: Scalars['ID'];
};


export type QueryEdgeDeviceByDeviceIdArgs = {
  deviceId: Scalars['String'];
};


export type QueryFacilityArgs = {
  id: Scalars['ID'];
};


export type QueryFeatureArgs = {
  key: Scalars['String'];
};


export type QueryFeaturesOfApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type QueryFeaturesOfTenantArgs = {
  tenantId: Scalars['ID'];
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryGetEdgeDeviceStatusArgs = {
  deviceId: Scalars['ID'];
};


export type QueryGetIssuerCaChainArgs = {
  dn: Scalars['String'];
};


export type QueryGetSshcaCertificatesForDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type QueryHasProvisionPermissionArgs = {
  targetTenantId?: Maybe<Scalars['ID']>;
};


export type QueryHasTenantFeatureArgs = {
  tenantId: Scalars['ID'];
  featureKey: Scalars['String'];
};


export type QueryIsServiceOrganizationActiveArgs = {
  serviceOrganizationId: Scalars['String'];
  dateTime?: Maybe<Scalars['String']>;
};


export type QueryIsUserAuthorizedArgs = {
  permissionKey: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryIsUserAuthorizedWithScopeArgs = {
  permissionKey: Scalars['ID'];
  userId: Scalars['ID'];
  scopeId: Scalars['ID'];
};


export type QueryKpiOverviewArgs = {
  kpiDataRequestInput?: Maybe<KpiDataRequestInput>;
};


export type QueryMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryMachineDocumentArgs = {
  machineDocumentId: Scalars['ID'];
};


export type QueryMachineModelArgs = {
  machineModelId: Scalars['ID'];
};


export type QueryMachineStatesArgs = {
  machineIds: Array<Scalars['ID']>;
};


export type QueryPermissionArgs = {
  key: Scalars['String'];
};


export type QueryPermissionsOfApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type QueryRemoteAccessArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryServiceCaseArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryServiceCasesForAssetArgs = {
  assetId?: Maybe<Scalars['String']>;
};


export type QueryServiceOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryStateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTenantArgs = {
  id: Scalars['ID'];
};


export type QueryTenantInvitationArgs = {
  invitationToken: Scalars['String'];
};


export type QueryTenantRoleArgs = {
  id: Scalars['ID'];
};


export type QueryTenantRolesOfTenantArgs = {
  tenantId: Scalars['ID'];
};


export type QueryTenantUserArgs = {
  id: Scalars['ID'];
};


export type QueryTunnelConfigArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryTunnelConfigsByMachineModelArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryUserRoleArgs = {
  id: Scalars['ID'];
};


export type QueryUsersWithPermissionArgs = {
  permissionKey: Scalars['ID'];
};

export type Reliability = {
  __typename?: 'Reliability';
  date: Scalars['LocalDateTime'];
  percentageReliable: Scalars['Float'];
};

export type RemoteAccess = {
  __typename?: 'RemoteAccess';
  id: Scalars['ID'];
  serviceCase?: Maybe<ServiceCase>;
  state?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  sessions?: Maybe<Array<Maybe<RemoteAccessSession>>>;
};

export type RemoteAccessSession = {
  __typename?: 'RemoteAccessSession';
  id: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  technician?: Maybe<TenantUser>;
  connection?: Maybe<RemoteAccessSessionConnection>;
  configuration: RemoteAccessSessionConfiguration;
  deviceInfo?: Maybe<DeviceInfo>;
  state?: Maybe<Scalars['String']>;
};

export type RemoteAccessSessionConfiguration = {
  __typename?: 'RemoteAccessSessionConfiguration';
  tepsiProxyId: Scalars['String'];
  localIp: Scalars['String'];
  localPort: Scalars['Int'];
  localProxyId: Scalars['String'];
  remoteIp: Scalars['String'];
  remotePort: Scalars['Int'];
  deviceProxyId: Scalars['String'];
};

export type RemoteAccessSessionConfigurationInput = {
  tepsiProxyId: Scalars['String'];
  localIp: Scalars['String'];
  localPort: Scalars['Int'];
  localProxyId: Scalars['String'];
  remoteIp: Scalars['String'];
  remotePort: Scalars['Int'];
  deviceProxyId: Scalars['String'];
};

export type RemoteAccessSessionConnection = {
  __typename?: 'RemoteAccessSessionConnection';
  deviceToTepsiState?: Maybe<Scalars['String']>;
  localToTepsiState?: Maybe<Scalars['String']>;
  localToServiceState?: Maybe<Scalars['String']>;
  deviceToMachineState?: Maybe<Scalars['String']>;
};

export type ScmAsset = {
  __typename?: 'ScmAsset';
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  scmMachineModel?: Maybe<ScmMachineModel>;
  scmEdgeDevice?: Maybe<ScmEdgeDevice>;
};

export type ScmCustomer = {
  __typename?: 'ScmCustomer';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ScmCustomerMachine = {
  __typename?: 'ScmCustomerMachine';
  id: Scalars['ID'];
  serialNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  scmCustomer?: Maybe<ScmCustomer>;
  scmMachineModel?: Maybe<ScmMachineModel>;
  scmEdgeDevice?: Maybe<ScmEdgeDevice>;
  scmTenant?: Maybe<ScmTenant>;
};

export type ScmEdgeDevice = {
  __typename?: 'ScmEdgeDevice';
  deviceId: Scalars['String'];
  connected?: Maybe<Scalars['Boolean']>;
};

export type ScmMachineModel = {
  __typename?: 'ScmMachineModel';
  id: Scalars['ID'];
  modelName?: Maybe<Scalars['String']>;
};

export type ScmTenant = {
  __typename?: 'ScmTenant';
  id: Scalars['ID'];
};

export type Scope = {
  __typename?: 'Scope';
  id: Scalars['ID'];
  scopeType: ScopeType;
  tenant?: Maybe<Tenant>;
};

export type ScopeType =
  | 'Tenant'
  | 'Organisation'
  | 'Machine';

export type SendMailRequest = {
  from: MailAddress;
  replyTo?: Maybe<MailAddress>;
  to: Array<Maybe<MailAddress>>;
  cc?: Maybe<Array<Maybe<MailAddress>>>;
  message: MailMessage;
};

export type SendMailResponse = {
  __typename?: 'SendMailResponse';
  success: Scalars['Boolean'];
};

export type ServiceCase = {
  __typename?: 'ServiceCase';
  id: Scalars['ID'];
  displayId: Scalars['String'];
  state?: Maybe<State>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['ZonedDateTime'];
  updatedAt?: Maybe<Scalars['ZonedDateTime']>;
  closedAt?: Maybe<Scalars['ZonedDateTime']>;
  creator: TenantUser;
  assignee?: Maybe<TenantUser>;
  watcher?: Maybe<Array<Maybe<Watcher>>>;
  isWatchedByCurrentUser?: Maybe<Scalars['Boolean']>;
  asset: Asset;
  scmAsset?: Maybe<ScmAsset>;
  customerMachine: CustomerMachine;
  scmCustomerMachine?: Maybe<ScmCustomerMachine>;
  priority?: Maybe<Scalars['Int']>;
  severity?: Maybe<Severity>;
  auditLog?: Maybe<Array<Maybe<LogEntry>>>;
  customFields?: Maybe<Array<Maybe<CustomFieldValue>>>;
  chatRoom?: Maybe<ChatRoom>;
  closingReport?: Maybe<ClosingReport>;
  remoteAccess?: Maybe<RemoteAccess>;
};

export type ServiceOrganisation = {
  __typename?: 'ServiceOrganisation';
  id: Scalars['ID'];
  tenant: Tenant;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ServiceOrganization = {
  __typename?: 'ServiceOrganization';
  id: Scalars['ID'];
  name: Scalars['String'];
  zoneId: Scalars['String'];
  shedule?: Maybe<Array<ServiceShedule>>;
};

export type ServiceShedule = {
  __typename?: 'ServiceShedule';
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  dayOfWeek: Scalars['Int'];
};

export type ServiceSheduleInput = {
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  dayOfWeek: Scalars['Int'];
};

export type Severity =
  | 'MACHINE_BREAKDOWN'
  | 'PRODUCTION_IMPAIRED'
  | 'NO_IMPAIRMENT';

export type SortDirection =
  | 'ASC'
  | 'DESC';

export type State = {
  __typename?: 'State';
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  isClosed: Scalars['Boolean'];
};

export type StringExpression = {
  equals?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  starts?: Maybe<Scalars['String']>;
  ends?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  machineStates?: Maybe<MachineState>;
};


export type SubscriptionMachineStatesArgs = {
  machineIds: Array<Scalars['ID']>;
};

export type TemplateEvent = {
  __typename?: 'TemplateEvent';
  dayOfWeek: DayOfWeek;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
  eventType: CalendarEventType;
};

export type TemplateEventInput = {
  dayOfWeek: DayOfWeek;
  start: Scalars['LocalTime'];
  end: Scalars['LocalTime'];
  eventType: CalendarEventType;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortname?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  address?: Maybe<TenantAddress>;
  users: Array<TenantUser>;
  tenantRoles: Array<TenantRole>;
};

export type TenantAddress = {
  __typename?: 'TenantAddress';
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type TenantInvitation = {
  __typename?: 'TenantInvitation';
  token: Scalars['ID'];
  tenantName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type TenantInvitationTenantInput = {
  name: Scalars['String'];
  shortname: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  address: CreateTenantAddressInput;
};

export type TenantInvitationUserInput = {
  name: Scalars['String'];
};

export type TenantRole = {
  __typename?: 'TenantRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  tenants: Array<Maybe<Tenant>>;
  features: Array<Maybe<Feature>>;
};

export type TenantUser = {
  __typename?: 'TenantUser';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  tenant: Tenant;
  identityId?: Maybe<Scalars['ID']>;
  externalChatUserId?: Maybe<Scalars['String']>;
  assignedUserRoles?: Maybe<Array<Maybe<UserRoleAssignment>>>;
};

export type Theme = {
  __typename?: 'Theme';
  images: Array<ThemeImage>;
  properties?: Maybe<Scalars['String']>;
  owner?: Maybe<Tenant>;
};

export type TunnelConfig = {
  __typename?: 'TunnelConfig';
  id: Scalars['ID'];
  displayId: Scalars['String'];
  machineModel: MachineModel;
  tepsiProxyId: Scalars['String'];
  remoteIp: Scalars['String'];
  remotePort: Scalars['Int'];
  deviceProxyId: Scalars['String'];
};

export type UnitMode =
  | 'UNDEFINED'
  | 'PRODUCTION'
  | 'SETUP'
  | 'MAINTENANCE'
  | 'UNUSED'
  | 'UNKNOWN'
  | 'NOT_APPLICABLE';

export type UnitModeInterval = {
  __typename?: 'UnitModeInterval';
  from: Scalars['LocalDateTime'];
  to?: Maybe<Scalars['LocalDateTime']>;
  unitMode: UnitMode;
};

export type UnitState =
  | 'UNDEFINED'
  | 'EXECUTING'
  | 'WAITING'
  | 'OUT_OF_SERVICE'
  | 'UNKNOWN'
  | 'NOT_APPLICABLE';

export type UnitStateInterval = {
  __typename?: 'UnitStateInterval';
  from: Scalars['LocalDateTime'];
  to?: Maybe<Scalars['LocalDateTime']>;
  unitState: UnitState;
};

export type UnitStatusMode =
  | 'Undefined'
  | 'Production'
  | 'Setup'
  | 'Maintenance'
  | 'Unused'
  | 'Unknown'
  | 'NotApplicable';

export type UnitStatusOrderRelation =
  | 'Undefined'
  | 'OrderRelated'
  | 'OrderUnrelated'
  | 'Unknown'
  | 'NotApplicable';

export type UnitStatusState =
  | 'Undefined'
  | 'Executing'
  | 'Waiting'
  | 'OutOfService'
  | 'Unknown'
  | 'NotApplicable';

export type UpdateCustomerAddressInput = {
  country: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  linkedTenantId?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  status: CustomerStatusEnum;
  serviceOrganisation: Scalars['ID'];
  address?: Maybe<UpdateCustomerAddressInput>;
};

export type UpdateFacilityInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateServiceOrganisationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateTenantAddressInput = {
  country: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
  number: Scalars['String'];
};

export type UpdateTenantInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  address?: Maybe<UpdateTenantAddressInput>;
};

export type UpdateTenantUserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  tenant?: Maybe<Tenant>;
  permissions: Array<Maybe<Permission>>;
};

export type UserRoleAssignment = {
  __typename?: 'UserRoleAssignment';
  id: Scalars['ID'];
  tenantUser?: Maybe<TenantUser>;
  userRole?: Maybe<UserRole>;
  scope?: Maybe<Scope>;
};

export type VideoConference = {
  __typename?: 'VideoConference';
  externalId: Scalars['ID'];
  key: Scalars['String'];
};

export type Watcher = {
  __typename?: 'Watcher';
  id: Scalars['ID'];
  serviceCaseId: Scalars['ID'];
  tenantUser: TenantUser;
};

export type WeeklyTemplate = {
  __typename?: 'WeeklyTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  events: Array<TemplateEvent>;
};

export type WeeklyTemplateInput = {
  name: Scalars['String'];
  events: Array<TemplateEventInput>;
};

export type Whiteboard = {
  __typename?: 'Whiteboard';
  externalId: Scalars['ID'];
  key: Scalars['String'];
  createdAt: Scalars['ZonedDateTime'];
};



export type _Entity = Asset;


export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type ThemeImage = {
  __typename?: 'themeImage';
  key?: Maybe<Scalars['String']>;
  imageurl?: Maybe<Scalars['String']>;
};

export type CreateCalendarEventMutationVariables = Exact<{
  data: Array<CalendarEventInput> | CalendarEventInput;
  machineId: Scalars['ID'];
}>;


export type CreateCalendarEventMutation = (
  { __typename?: 'Mutation' }
  & { createCalendarEvents: Array<(
    { __typename?: 'CalendarEvent' }
    & Pick<CalendarEvent, 'id' | 'start' | 'end' | 'eventType'>
  )> }
);

export type DeleteCalendarEventMutationVariables = Exact<{
  eventId: Scalars['ID'];
  machineId: Scalars['ID'];
}>;


export type DeleteCalendarEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCalendarEvent'>
);

export type UpdateCalendarEventMutationVariables = Exact<{
  data: CalendarEventInput;
  eventId: Scalars['ID'];
  machineId: Scalars['ID'];
}>;


export type UpdateCalendarEventMutation = (
  { __typename?: 'Mutation' }
  & { updateCalendarEvent: (
    { __typename?: 'CalendarEvent' }
    & Pick<CalendarEvent, 'id' | 'start' | 'end' | 'eventType'>
  ) }
);

export type ApplyWeeklyTemplateMutationVariables = Exact<{
  start: Scalars['LocalDate'];
  end: Scalars['LocalDate'];
  machineId: Scalars['ID'];
  templateId: Scalars['ID'];
}>;


export type ApplyWeeklyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { applyWeeklyTemplate: Array<(
    { __typename?: 'CalendarEvent' }
    & Pick<CalendarEvent, 'id' | 'start' | 'end' | 'eventType'>
  )> }
);

export type CreateWeeklyTemplateMutationVariables = Exact<{
  data: WeeklyTemplateInput;
}>;


export type CreateWeeklyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createWeeklyTemplate: (
    { __typename?: 'WeeklyTemplate' }
    & Pick<WeeklyTemplate, 'id' | 'name'>
    & { events: Array<(
      { __typename?: 'TemplateEvent' }
      & Pick<TemplateEvent, 'dayOfWeek' | 'start' | 'end' | 'eventType'>
    )> }
  ) }
);

export type DeleteWeeklyTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type DeleteWeeklyTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWeeklyTemplate'>
);

export type UpdateWeeklyTemplateMutationVariables = Exact<{
  data: WeeklyTemplateInput;
  templateId: Scalars['ID'];
}>;


export type UpdateWeeklyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateWeeklyTemplate: (
    { __typename?: 'WeeklyTemplate' }
    & Pick<WeeklyTemplate, 'id' | 'name'>
    & { events: Array<(
      { __typename?: 'TemplateEvent' }
      & Pick<TemplateEvent, 'dayOfWeek' | 'start' | 'end' | 'eventType'>
    )> }
  ) }
);

export type ApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'version'>
    & { installations?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationInstallation' }
      & { asset: (
        { __typename?: 'Asset' }
        & Pick<Asset, 'id'>
      ) }
    )>>> }
  )>>> }
);

export type CalendarEventsQueryVariables = Exact<{
  machineId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
}>;


export type CalendarEventsQuery = (
  { __typename?: 'Query' }
  & { calendarEvents: Array<(
    { __typename?: 'CalendarEvent' }
    & Pick<CalendarEvent, 'id' | 'start' | 'end' | 'eventType'>
  )> }
);

export type JobHistoryWithGroupedMessagesQueryVariables = Exact<{
  assetId: Scalars['ID'];
  jobId: Scalars['ID'];
}>;


export type JobHistoryWithGroupedMessagesQuery = (
  { __typename?: 'Query' }
  & { asset?: Maybe<(
    { __typename?: 'Asset' }
    & { job?: Maybe<(
      { __typename?: 'JobHistoryItem' }
      & Pick<JobHistoryItem, 'id' | 'plannedRuntime' | 'duration' | 'startTimestamp' | 'endTimestamp' | 'mainProgramFile' | 'mainProgramName'>
      & { unitStates?: Maybe<Array<(
        { __typename?: 'UnitStateInterval' }
        & Pick<UnitStateInterval, 'from' | 'to' | 'unitState'>
      )>>, messages?: Maybe<Array<(
        { __typename?: 'JobMessage' }
        & Pick<JobMessage, 'logType' | 'text' | 'type' | 'messageId' | 'count'>
      )>> }
    )> }
  )> }
);

export type JobHistoryQueryVariables = Exact<{
  assetId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  count: Scalars['Int'];
}>;


export type JobHistoryQuery = (
  { __typename?: 'Query' }
  & { asset?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'displayName'>
    & { jobHistory: Array<(
      { __typename?: 'JobHistoryItem' }
      & Pick<JobHistoryItem, 'id' | 'jobExecutionId' | 'mainProgramFile' | 'mainProgramName' | 'startTimestamp' | 'endTimestamp' | 'duration' | 'plannedRuntime' | 'hasErrors'>
      & { messages?: Maybe<Array<(
        { __typename?: 'JobMessage' }
        & Pick<JobMessage, 'id' | 'logType' | 'text' | 'type' | 'messageId' | 'timestamp' | 'occurrenceStart' | 'occurrenceEnd'>
      )>> }
    )> }
  )> }
);

export type JobQueryVariables = Exact<{
  assetId: Scalars['ID'];
  jobId: Scalars['ID'];
}>;


export type JobQuery = (
  { __typename?: 'Query' }
  & { asset?: Maybe<(
    { __typename?: 'Asset' }
    & { job?: Maybe<(
      { __typename?: 'JobHistoryItem' }
      & Pick<JobHistoryItem, 'id' | 'plannedRuntime' | 'duration' | 'startTimestamp' | 'endTimestamp' | 'mainProgramFile' | 'mainProgramName' | 'hasErrors'>
      & { unitStates?: Maybe<Array<(
        { __typename?: 'UnitStateInterval' }
        & Pick<UnitStateInterval, 'from' | 'to' | 'unitState'>
      )>>, messages?: Maybe<Array<(
        { __typename?: 'JobMessage' }
        & Pick<JobMessage, 'logType' | 'text' | 'type' | 'messageId' | 'timestamp' | 'occurrenceStart' | 'occurrenceEnd'>
      )>> }
    )> }
  )> }
);

export type JobHistoryWithoutMessagesQueryVariables = Exact<{
  assetId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  count: Scalars['Int'];
}>;


export type JobHistoryWithoutMessagesQuery = (
  { __typename?: 'Query' }
  & { asset?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'displayName'>
    & { jobHistory: Array<(
      { __typename?: 'JobHistoryItem' }
      & Pick<JobHistoryItem, 'id' | 'jobExecutionId' | 'mainProgramFile' | 'mainProgramName' | 'startTimestamp' | 'endTimestamp' | 'duration' | 'plannedRuntime' | 'hasErrors'>
    )> }
  )> }
);

export type JobHistoryByRangeQueryVariables = Exact<{
  assetId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
}>;


export type JobHistoryByRangeQuery = (
  { __typename?: 'Query' }
  & { asset?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'displayName'>
    & { jobHistoryByRange: Array<(
      { __typename?: 'JobHistoryItem' }
      & Pick<JobHistoryItem, 'endTimestamp' | 'hasErrors' | 'id' | 'jobExecutionId' | 'mainProgramName' | 'startTimestamp'>
    )>, unitModes: Array<(
      { __typename?: 'UnitModeInterval' }
      & Pick<UnitModeInterval, 'from' | 'to' | 'unitMode'>
    )> }
  )> }
);

export type KpiOverviewQueryVariables = Exact<{
  from: Scalars['LocalDateTime'];
  to: Scalars['LocalDateTime'];
  machineIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type KpiOverviewQuery = (
  { __typename?: 'Query' }
  & { kpiOverview?: Maybe<(
    { __typename?: 'KpiOverview' }
    & { availability?: Maybe<(
      { __typename?: 'KpiData' }
      & Pick<KpiData, 'average'>
      & { values: Array<Maybe<(
        { __typename?: 'KpiValue' }
        & Pick<KpiValue, 'date' | 'value'>
      )>> }
    )>, technicalEfficiency?: Maybe<(
      { __typename?: 'KpiData' }
      & Pick<KpiData, 'average'>
      & { values: Array<Maybe<(
        { __typename?: 'KpiValue' }
        & Pick<KpiValue, 'date' | 'value'>
      )>> }
    )>, setupRatio?: Maybe<(
      { __typename?: 'KpiData' }
      & Pick<KpiData, 'average'>
      & { values: Array<Maybe<(
        { __typename?: 'KpiValue' }
        & Pick<KpiValue, 'date' | 'value'>
      )>> }
    )>, allocationEfficiency?: Maybe<(
      { __typename?: 'KpiData' }
      & Pick<KpiData, 'average'>
      & { values: Array<Maybe<(
        { __typename?: 'KpiValue' }
        & Pick<KpiValue, 'date' | 'value'>
      )>> }
    )>, utilizationEfficiency?: Maybe<(
      { __typename?: 'KpiData' }
      & Pick<KpiData, 'average'>
      & { values: Array<Maybe<(
        { __typename?: 'KpiValue' }
        & Pick<KpiValue, 'date' | 'value'>
      )>> }
    )>, metaData?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'reliabilityAverage'>
      & { reliabilities: Array<(
        { __typename?: 'Reliability' }
        & Pick<Reliability, 'date' | 'percentageReliable'>
      )>, binSize: (
        { __typename?: 'BinSize' }
        & Pick<BinSize, 'value' | 'unit'>
      ) }
    )>, plannedBusyRatio?: Maybe<(
      { __typename?: 'KpiData' }
      & Pick<KpiData, 'average'>
      & { values: Array<Maybe<(
        { __typename?: 'KpiValue' }
        & Pick<KpiValue, 'date' | 'value'>
      )>> }
    )> }
  )> }
);

export type MachineStatesQueryVariables = Exact<{
  machineIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type MachineStatesQuery = (
  { __typename?: 'Query' }
  & { machineStates: Array<(
    { __typename?: 'MachineState' }
    & Pick<MachineState, 'id' | 'tenantId' | 'machineId' | 'lastSourceTimestampUTC' | 'lastDocumentUpdateUTC' | 'lastSourceTimestampOffset' | 'unitStatusOrderRelation' | 'unitStatusMode' | 'unitStatusState'>
    & { activeJob?: Maybe<(
      { __typename?: 'JobInfo' }
      & Pick<JobInfo, 'id' | 'estimatedRuntime' | 'estimatedRemainingRuntime' | 'estimatedEndTimestamp' | 'mainProgramName' | 'mainProgramFile' | 'plannedRuntime' | 'startTimestamp'>
    )>, userMessage?: Maybe<(
      { __typename?: 'MachineMessage' }
      & Pick<MachineMessage, 'text' | 'timestamp' | 'id' | 'type'>
    )>, unitMessageEN?: Maybe<(
      { __typename?: 'MachineMessage' }
      & Pick<MachineMessage, 'text' | 'timestamp' | 'id' | 'type'>
    )>, unitMessage?: Maybe<(
      { __typename?: 'MachineMessage' }
      & Pick<MachineMessage, 'text' | 'timestamp' | 'id' | 'type'>
    )> }
  )> }
);

export type MachinesQueryVariables = Exact<{ [key: string]: never; }>;


export type MachinesQuery = (
  { __typename?: 'Query' }
  & { assets: Array<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'id' | 'displayName' | 'serialNumber'>
    & { facility?: Maybe<(
      { __typename?: 'Facility' }
      & Pick<Facility, 'name'>
    )>, edgeDevice?: Maybe<(
      { __typename?: 'EdgeDevice' }
      & Pick<EdgeDevice, 'connected'>
    )>, machineModel?: Maybe<(
      { __typename?: 'MachineModel' }
      & Pick<MachineModel, 'avatar' | 'modelName' | 'brand' | 'technology'>
    )> }
  )> }
);

export type UserInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInformationQuery = (
  { __typename?: 'Query' }
  & { myTenantUser: (
    { __typename?: 'TenantUser' }
    & Pick<TenantUser, 'id'>
  ) }
);

export type WeeklyTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type WeeklyTemplatesQuery = (
  { __typename?: 'Query' }
  & { weeklyTemplates: Array<(
    { __typename?: 'WeeklyTemplate' }
    & Pick<WeeklyTemplate, 'id' | 'name'>
    & { events: Array<(
      { __typename?: 'TemplateEvent' }
      & Pick<TemplateEvent, 'dayOfWeek' | 'start' | 'end' | 'eventType'>
    )> }
  )> }
);

export type MachineStatesSubscriptionSubscriptionVariables = Exact<{
  machineIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type MachineStatesSubscriptionSubscription = (
  { __typename?: 'Subscription' }
  & { machineStates?: Maybe<(
    { __typename?: 'MachineState' }
    & Pick<MachineState, 'id' | 'tenantId' | 'machineId' | 'lastSourceTimestampUTC' | 'lastDocumentUpdateUTC' | 'lastSourceTimestampOffset' | 'unitStatusOrderRelation' | 'unitStatusMode' | 'unitStatusState'>
    & { activeJob?: Maybe<(
      { __typename?: 'JobInfo' }
      & Pick<JobInfo, 'id' | 'estimatedRuntime' | 'estimatedRemainingRuntime' | 'estimatedEndTimestamp' | 'mainProgramName' | 'mainProgramFile' | 'plannedRuntime' | 'startTimestamp'>
    )>, userMessage?: Maybe<(
      { __typename?: 'MachineMessage' }
      & Pick<MachineMessage, 'text' | 'timestamp' | 'id' | 'type'>
    )>, unitMessageEN?: Maybe<(
      { __typename?: 'MachineMessage' }
      & Pick<MachineMessage, 'text' | 'timestamp' | 'id' | 'type'>
    )>, unitMessage?: Maybe<(
      { __typename?: 'MachineMessage' }
      & Pick<MachineMessage, 'text' | 'timestamp' | 'id' | 'type'>
    )> }
  )> }
);


export const CreateCalendarEventDocument = gql`
    mutation CreateCalendarEvent($data: [CalendarEventInput!]!, $machineId: ID!) {
  createCalendarEvents(data: $data, machineId: $machineId) {
    id
    start
    end
    eventType
  }
}
    `;
export type CreateCalendarEventMutationFn = ApolloReactCommon.MutationFunction<CreateCalendarEventMutation, CreateCalendarEventMutationVariables>;

/**
 * __useCreateCalendarEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventMutation, { data, loading, error }] = useCreateCalendarEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useCreateCalendarEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalendarEventMutation, CreateCalendarEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCalendarEventMutation, CreateCalendarEventMutationVariables>(CreateCalendarEventDocument, baseOptions);
      }
export type CreateCalendarEventMutationHookResult = ReturnType<typeof useCreateCalendarEventMutation>;
export type CreateCalendarEventMutationResult = ApolloReactCommon.MutationResult<CreateCalendarEventMutation>;
export type CreateCalendarEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCalendarEventMutation, CreateCalendarEventMutationVariables>;
export const DeleteCalendarEventDocument = gql`
    mutation DeleteCalendarEvent($eventId: ID!, $machineId: ID!) {
  deleteCalendarEvent(eventId: $eventId, machineId: $machineId)
}
    `;
export type DeleteCalendarEventMutationFn = ApolloReactCommon.MutationFunction<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>(DeleteCalendarEventDocument, baseOptions);
      }
export type DeleteCalendarEventMutationHookResult = ReturnType<typeof useDeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationResult = ApolloReactCommon.MutationResult<DeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>;
export const UpdateCalendarEventDocument = gql`
    mutation UpdateCalendarEvent($data: CalendarEventInput!, $eventId: ID!, $machineId: ID!) {
  updateCalendarEvent(data: $data, eventId: $eventId, machineId: $machineId) {
    id
    start
    end
    eventType
  }
}
    `;
export type UpdateCalendarEventMutationFn = ApolloReactCommon.MutationFunction<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>;

/**
 * __useUpdateCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarEventMutation, { data, loading, error }] = useUpdateCalendarEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *      eventId: // value for 'eventId'
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useUpdateCalendarEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>(UpdateCalendarEventDocument, baseOptions);
      }
export type UpdateCalendarEventMutationHookResult = ReturnType<typeof useUpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationResult = ApolloReactCommon.MutationResult<UpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>;
export const ApplyWeeklyTemplateDocument = gql`
    mutation ApplyWeeklyTemplate($start: LocalDate!, $end: LocalDate!, $machineId: ID!, $templateId: ID!) {
  applyWeeklyTemplate(
    start: $start
    end: $end
    machineId: $machineId
    templateId: $templateId
  ) {
    id
    start
    end
    eventType
  }
}
    `;
export type ApplyWeeklyTemplateMutationFn = ApolloReactCommon.MutationFunction<ApplyWeeklyTemplateMutation, ApplyWeeklyTemplateMutationVariables>;

/**
 * __useApplyWeeklyTemplateMutation__
 *
 * To run a mutation, you first call `useApplyWeeklyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyWeeklyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyWeeklyTemplateMutation, { data, loading, error }] = useApplyWeeklyTemplateMutation({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      machineId: // value for 'machineId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useApplyWeeklyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApplyWeeklyTemplateMutation, ApplyWeeklyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<ApplyWeeklyTemplateMutation, ApplyWeeklyTemplateMutationVariables>(ApplyWeeklyTemplateDocument, baseOptions);
      }
export type ApplyWeeklyTemplateMutationHookResult = ReturnType<typeof useApplyWeeklyTemplateMutation>;
export type ApplyWeeklyTemplateMutationResult = ApolloReactCommon.MutationResult<ApplyWeeklyTemplateMutation>;
export type ApplyWeeklyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<ApplyWeeklyTemplateMutation, ApplyWeeklyTemplateMutationVariables>;
export const CreateWeeklyTemplateDocument = gql`
    mutation CreateWeeklyTemplate($data: WeeklyTemplateInput!) {
  createWeeklyTemplate(data: $data) {
    id
    name
    events {
      dayOfWeek
      start
      end
      eventType
    }
  }
}
    `;
export type CreateWeeklyTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateWeeklyTemplateMutation, CreateWeeklyTemplateMutationVariables>;

/**
 * __useCreateWeeklyTemplateMutation__
 *
 * To run a mutation, you first call `useCreateWeeklyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeeklyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeeklyTemplateMutation, { data, loading, error }] = useCreateWeeklyTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWeeklyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWeeklyTemplateMutation, CreateWeeklyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWeeklyTemplateMutation, CreateWeeklyTemplateMutationVariables>(CreateWeeklyTemplateDocument, baseOptions);
      }
export type CreateWeeklyTemplateMutationHookResult = ReturnType<typeof useCreateWeeklyTemplateMutation>;
export type CreateWeeklyTemplateMutationResult = ApolloReactCommon.MutationResult<CreateWeeklyTemplateMutation>;
export type CreateWeeklyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWeeklyTemplateMutation, CreateWeeklyTemplateMutationVariables>;
export const DeleteWeeklyTemplateDocument = gql`
    mutation DeleteWeeklyTemplate($templateId: ID!) {
  deleteWeeklyTemplate(templateId: $templateId)
}
    `;
export type DeleteWeeklyTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteWeeklyTemplateMutation, DeleteWeeklyTemplateMutationVariables>;

/**
 * __useDeleteWeeklyTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteWeeklyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWeeklyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWeeklyTemplateMutation, { data, loading, error }] = useDeleteWeeklyTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteWeeklyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWeeklyTemplateMutation, DeleteWeeklyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWeeklyTemplateMutation, DeleteWeeklyTemplateMutationVariables>(DeleteWeeklyTemplateDocument, baseOptions);
      }
export type DeleteWeeklyTemplateMutationHookResult = ReturnType<typeof useDeleteWeeklyTemplateMutation>;
export type DeleteWeeklyTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteWeeklyTemplateMutation>;
export type DeleteWeeklyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWeeklyTemplateMutation, DeleteWeeklyTemplateMutationVariables>;
export const UpdateWeeklyTemplateDocument = gql`
    mutation UpdateWeeklyTemplate($data: WeeklyTemplateInput!, $templateId: ID!) {
  updateWeeklyTemplate(data: $data, templateId: $templateId) {
    id
    name
    events {
      dayOfWeek
      start
      end
      eventType
    }
  }
}
    `;
export type UpdateWeeklyTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateWeeklyTemplateMutation, UpdateWeeklyTemplateMutationVariables>;

/**
 * __useUpdateWeeklyTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateWeeklyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeeklyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeeklyTemplateMutation, { data, loading, error }] = useUpdateWeeklyTemplateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useUpdateWeeklyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWeeklyTemplateMutation, UpdateWeeklyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWeeklyTemplateMutation, UpdateWeeklyTemplateMutationVariables>(UpdateWeeklyTemplateDocument, baseOptions);
      }
export type UpdateWeeklyTemplateMutationHookResult = ReturnType<typeof useUpdateWeeklyTemplateMutation>;
export type UpdateWeeklyTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateWeeklyTemplateMutation>;
export type UpdateWeeklyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWeeklyTemplateMutation, UpdateWeeklyTemplateMutationVariables>;
export const ApplicationsDocument = gql`
    query Applications {
  applications {
    id
    version
    installations {
      asset {
        id
      }
    }
  }
}
    `;

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, baseOptions);
      }
export function useApplicationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, baseOptions);
        }
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>;
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>;
export type ApplicationsQueryResult = ApolloReactCommon.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>;
export const CalendarEventsDocument = gql`
    query CalendarEvents($machineId: ID!, $from: LocalDateTime!, $to: LocalDateTime!) {
  calendarEvents(machineId: $machineId, from: $from, to: $to) {
    id
    start
    end
    eventType
  }
}
    `;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCalendarEventsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, baseOptions);
      }
export function useCalendarEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, baseOptions);
        }
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsQueryResult = ApolloReactCommon.QueryResult<CalendarEventsQuery, CalendarEventsQueryVariables>;
export const JobHistoryWithGroupedMessagesDocument = gql`
    query JobHistoryWithGroupedMessages($assetId: ID!, $jobId: ID!) {
  asset(assetId: $assetId) {
    job(id: $jobId) {
      id
      plannedRuntime
      duration
      startTimestamp
      endTimestamp
      mainProgramFile
      mainProgramName
      unitStates {
        from
        to
        unitState
      }
      messages(grouped: true) {
        logType
        text
        type
        messageId
        count
      }
    }
  }
}
    `;

/**
 * __useJobHistoryWithGroupedMessagesQuery__
 *
 * To run a query within a React component, call `useJobHistoryWithGroupedMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobHistoryWithGroupedMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobHistoryWithGroupedMessagesQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobHistoryWithGroupedMessagesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<JobHistoryWithGroupedMessagesQuery, JobHistoryWithGroupedMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<JobHistoryWithGroupedMessagesQuery, JobHistoryWithGroupedMessagesQueryVariables>(JobHistoryWithGroupedMessagesDocument, baseOptions);
      }
export function useJobHistoryWithGroupedMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobHistoryWithGroupedMessagesQuery, JobHistoryWithGroupedMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobHistoryWithGroupedMessagesQuery, JobHistoryWithGroupedMessagesQueryVariables>(JobHistoryWithGroupedMessagesDocument, baseOptions);
        }
export type JobHistoryWithGroupedMessagesQueryHookResult = ReturnType<typeof useJobHistoryWithGroupedMessagesQuery>;
export type JobHistoryWithGroupedMessagesLazyQueryHookResult = ReturnType<typeof useJobHistoryWithGroupedMessagesLazyQuery>;
export type JobHistoryWithGroupedMessagesQueryResult = ApolloReactCommon.QueryResult<JobHistoryWithGroupedMessagesQuery, JobHistoryWithGroupedMessagesQueryVariables>;
export const JobHistoryDocument = gql`
    query JobHistory($assetId: ID!, $from: LocalDateTime!, $count: Int!) {
  asset(assetId: $assetId) {
    id
    displayName
    jobHistory(from: $from, count: $count) {
      id
      jobExecutionId
      mainProgramFile
      mainProgramName
      startTimestamp
      endTimestamp
      duration
      plannedRuntime
      hasErrors
      messages {
        id
        logType
        text
        type
        messageId
        timestamp
        occurrenceStart
        occurrenceEnd
      }
    }
  }
}
    `;

/**
 * __useJobHistoryQuery__
 *
 * To run a query within a React component, call `useJobHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobHistoryQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      from: // value for 'from'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useJobHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<JobHistoryQuery, JobHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<JobHistoryQuery, JobHistoryQueryVariables>(JobHistoryDocument, baseOptions);
      }
export function useJobHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobHistoryQuery, JobHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobHistoryQuery, JobHistoryQueryVariables>(JobHistoryDocument, baseOptions);
        }
export type JobHistoryQueryHookResult = ReturnType<typeof useJobHistoryQuery>;
export type JobHistoryLazyQueryHookResult = ReturnType<typeof useJobHistoryLazyQuery>;
export type JobHistoryQueryResult = ApolloReactCommon.QueryResult<JobHistoryQuery, JobHistoryQueryVariables>;
export const JobDocument = gql`
    query Job($assetId: ID!, $jobId: ID!) {
  asset(assetId: $assetId) {
    job(id: $jobId) {
      id
      plannedRuntime
      duration
      startTimestamp
      endTimestamp
      mainProgramFile
      mainProgramName
      hasErrors
      unitStates {
        from
        to
        unitState
      }
      messages {
        logType
        text
        type
        messageId
        timestamp
        occurrenceStart
        occurrenceEnd
      }
    }
  }
}
    `;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobQuery(baseOptions: ApolloReactHooks.QueryHookOptions<JobQuery, JobQueryVariables>) {
        return ApolloReactHooks.useQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
      }
export function useJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = ApolloReactCommon.QueryResult<JobQuery, JobQueryVariables>;
export const JobHistoryWithoutMessagesDocument = gql`
    query JobHistoryWithoutMessages($assetId: ID!, $from: LocalDateTime!, $count: Int!) {
  asset(assetId: $assetId) {
    id
    displayName
    jobHistory(from: $from, count: $count) {
      id
      jobExecutionId
      mainProgramFile
      mainProgramName
      startTimestamp
      endTimestamp
      duration
      plannedRuntime
      hasErrors
    }
  }
}
    `;

/**
 * __useJobHistoryWithoutMessagesQuery__
 *
 * To run a query within a React component, call `useJobHistoryWithoutMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobHistoryWithoutMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobHistoryWithoutMessagesQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      from: // value for 'from'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useJobHistoryWithoutMessagesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<JobHistoryWithoutMessagesQuery, JobHistoryWithoutMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<JobHistoryWithoutMessagesQuery, JobHistoryWithoutMessagesQueryVariables>(JobHistoryWithoutMessagesDocument, baseOptions);
      }
export function useJobHistoryWithoutMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobHistoryWithoutMessagesQuery, JobHistoryWithoutMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobHistoryWithoutMessagesQuery, JobHistoryWithoutMessagesQueryVariables>(JobHistoryWithoutMessagesDocument, baseOptions);
        }
export type JobHistoryWithoutMessagesQueryHookResult = ReturnType<typeof useJobHistoryWithoutMessagesQuery>;
export type JobHistoryWithoutMessagesLazyQueryHookResult = ReturnType<typeof useJobHistoryWithoutMessagesLazyQuery>;
export type JobHistoryWithoutMessagesQueryResult = ApolloReactCommon.QueryResult<JobHistoryWithoutMessagesQuery, JobHistoryWithoutMessagesQueryVariables>;
export const JobHistoryByRangeDocument = gql`
    query JobHistoryByRange($assetId: ID!, $from: LocalDateTime!, $to: LocalDateTime!) {
  asset(assetId: $assetId) {
    id
    displayName
    jobHistoryByRange(from: $from, to: $to) {
      endTimestamp
      hasErrors
      id
      jobExecutionId
      mainProgramName
      startTimestamp
    }
    unitModes(from: $from, to: $to) {
      from
      to
      unitMode
    }
  }
}
    `;

/**
 * __useJobHistoryByRangeQuery__
 *
 * To run a query within a React component, call `useJobHistoryByRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobHistoryByRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobHistoryByRangeQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useJobHistoryByRangeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<JobHistoryByRangeQuery, JobHistoryByRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<JobHistoryByRangeQuery, JobHistoryByRangeQueryVariables>(JobHistoryByRangeDocument, baseOptions);
      }
export function useJobHistoryByRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobHistoryByRangeQuery, JobHistoryByRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobHistoryByRangeQuery, JobHistoryByRangeQueryVariables>(JobHistoryByRangeDocument, baseOptions);
        }
export type JobHistoryByRangeQueryHookResult = ReturnType<typeof useJobHistoryByRangeQuery>;
export type JobHistoryByRangeLazyQueryHookResult = ReturnType<typeof useJobHistoryByRangeLazyQuery>;
export type JobHistoryByRangeQueryResult = ApolloReactCommon.QueryResult<JobHistoryByRangeQuery, JobHistoryByRangeQueryVariables>;
export const KpiOverviewDocument = gql`
    query KpiOverview($from: LocalDateTime!, $to: LocalDateTime!, $machineIds: [ID!]!) {
  kpiOverview(
    kpiDataRequestInput: {from: $from, to: $to, machineIds: $machineIds}
  ) {
    availability {
      average
      values {
        date
        value
      }
    }
    technicalEfficiency {
      average
      values {
        date
        value
      }
    }
    setupRatio {
      average
      values {
        date
        value
      }
    }
    allocationEfficiency {
      average
      values {
        date
        value
      }
    }
    utilizationEfficiency {
      average
      values {
        date
        value
      }
    }
    metaData {
      reliabilities {
        date
        percentageReliable
      }
      reliabilityAverage
      binSize {
        value
        unit
      }
    }
    plannedBusyRatio {
      average
      values {
        date
        value
      }
    }
  }
}
    `;

/**
 * __useKpiOverviewQuery__
 *
 * To run a query within a React component, call `useKpiOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiOverviewQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      machineIds: // value for 'machineIds'
 *   },
 * });
 */
export function useKpiOverviewQuery(baseOptions: ApolloReactHooks.QueryHookOptions<KpiOverviewQuery, KpiOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiOverviewQuery, KpiOverviewQueryVariables>(KpiOverviewDocument, baseOptions);
      }
export function useKpiOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiOverviewQuery, KpiOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiOverviewQuery, KpiOverviewQueryVariables>(KpiOverviewDocument, baseOptions);
        }
export type KpiOverviewQueryHookResult = ReturnType<typeof useKpiOverviewQuery>;
export type KpiOverviewLazyQueryHookResult = ReturnType<typeof useKpiOverviewLazyQuery>;
export type KpiOverviewQueryResult = ApolloReactCommon.QueryResult<KpiOverviewQuery, KpiOverviewQueryVariables>;
export const MachineStatesDocument = gql`
    query MachineStates($machineIds: [ID!]!) {
  machineStates(machineIds: $machineIds) {
    id
    tenantId
    machineId
    activeJob {
      id
      estimatedRuntime
      estimatedRemainingRuntime
      estimatedEndTimestamp
      mainProgramName
      mainProgramFile
      plannedRuntime
      startTimestamp
    }
    lastSourceTimestampUTC
    lastDocumentUpdateUTC
    lastSourceTimestampOffset
    unitStatusOrderRelation
    unitStatusMode
    unitStatusState
    userMessage {
      text
      timestamp
      id
      type
    }
    unitMessageEN {
      text
      timestamp
      id
      type
    }
    unitMessage {
      text
      timestamp
      id
      type
    }
  }
}
    `;

/**
 * __useMachineStatesQuery__
 *
 * To run a query within a React component, call `useMachineStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachineStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachineStatesQuery({
 *   variables: {
 *      machineIds: // value for 'machineIds'
 *   },
 * });
 */
export function useMachineStatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MachineStatesQuery, MachineStatesQueryVariables>) {
        return ApolloReactHooks.useQuery<MachineStatesQuery, MachineStatesQueryVariables>(MachineStatesDocument, baseOptions);
      }
export function useMachineStatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MachineStatesQuery, MachineStatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MachineStatesQuery, MachineStatesQueryVariables>(MachineStatesDocument, baseOptions);
        }
export type MachineStatesQueryHookResult = ReturnType<typeof useMachineStatesQuery>;
export type MachineStatesLazyQueryHookResult = ReturnType<typeof useMachineStatesLazyQuery>;
export type MachineStatesQueryResult = ApolloReactCommon.QueryResult<MachineStatesQuery, MachineStatesQueryVariables>;
export const MachinesDocument = gql`
    query Machines {
  assets {
    id
    displayName
    serialNumber
    facility {
      name
    }
    edgeDevice {
      connected
    }
    machineModel {
      avatar
      modelName
      brand
      technology
    }
  }
}
    `;

/**
 * __useMachinesQuery__
 *
 * To run a query within a React component, call `useMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMachinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MachinesQuery, MachinesQueryVariables>) {
        return ApolloReactHooks.useQuery<MachinesQuery, MachinesQueryVariables>(MachinesDocument, baseOptions);
      }
export function useMachinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MachinesQuery, MachinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MachinesQuery, MachinesQueryVariables>(MachinesDocument, baseOptions);
        }
export type MachinesQueryHookResult = ReturnType<typeof useMachinesQuery>;
export type MachinesLazyQueryHookResult = ReturnType<typeof useMachinesLazyQuery>;
export type MachinesQueryResult = ApolloReactCommon.QueryResult<MachinesQuery, MachinesQueryVariables>;
export const UserInformationDocument = gql`
    query UserInformation {
  myTenantUser {
    id
  }
}
    `;

/**
 * __useUserInformationQuery__
 *
 * To run a query within a React component, call `useUserInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserInformationQuery, UserInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<UserInformationQuery, UserInformationQueryVariables>(UserInformationDocument, baseOptions);
      }
export function useUserInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserInformationQuery, UserInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserInformationQuery, UserInformationQueryVariables>(UserInformationDocument, baseOptions);
        }
export type UserInformationQueryHookResult = ReturnType<typeof useUserInformationQuery>;
export type UserInformationLazyQueryHookResult = ReturnType<typeof useUserInformationLazyQuery>;
export type UserInformationQueryResult = ApolloReactCommon.QueryResult<UserInformationQuery, UserInformationQueryVariables>;
export const WeeklyTemplatesDocument = gql`
    query WeeklyTemplates {
  weeklyTemplates {
    id
    name
    events {
      dayOfWeek
      start
      end
      eventType
    }
  }
}
    `;

/**
 * __useWeeklyTemplatesQuery__
 *
 * To run a query within a React component, call `useWeeklyTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWeeklyTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WeeklyTemplatesQuery, WeeklyTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<WeeklyTemplatesQuery, WeeklyTemplatesQueryVariables>(WeeklyTemplatesDocument, baseOptions);
      }
export function useWeeklyTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WeeklyTemplatesQuery, WeeklyTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WeeklyTemplatesQuery, WeeklyTemplatesQueryVariables>(WeeklyTemplatesDocument, baseOptions);
        }
export type WeeklyTemplatesQueryHookResult = ReturnType<typeof useWeeklyTemplatesQuery>;
export type WeeklyTemplatesLazyQueryHookResult = ReturnType<typeof useWeeklyTemplatesLazyQuery>;
export type WeeklyTemplatesQueryResult = ApolloReactCommon.QueryResult<WeeklyTemplatesQuery, WeeklyTemplatesQueryVariables>;
export const MachineStatesSubscriptionDocument = gql`
    subscription MachineStatesSubscription($machineIds: [ID!]!) {
  machineStates(machineIds: $machineIds) {
    id
    tenantId
    machineId
    activeJob {
      id
      estimatedRuntime
      estimatedRemainingRuntime
      estimatedEndTimestamp
      mainProgramName
      mainProgramFile
      plannedRuntime
      startTimestamp
    }
    lastSourceTimestampUTC
    lastDocumentUpdateUTC
    lastSourceTimestampOffset
    unitStatusOrderRelation
    unitStatusMode
    unitStatusState
    userMessage {
      text
      timestamp
      id
      type
    }
    unitMessageEN {
      text
      timestamp
      id
      type
    }
    unitMessage {
      text
      timestamp
      id
      type
    }
  }
}
    `;

/**
 * __useMachineStatesSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMachineStatesSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMachineStatesSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachineStatesSubscriptionSubscription({
 *   variables: {
 *      machineIds: // value for 'machineIds'
 *   },
 * });
 */
export function useMachineStatesSubscriptionSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<MachineStatesSubscriptionSubscription, MachineStatesSubscriptionSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<MachineStatesSubscriptionSubscription, MachineStatesSubscriptionSubscriptionVariables>(MachineStatesSubscriptionDocument, baseOptions);
      }
export type MachineStatesSubscriptionSubscriptionHookResult = ReturnType<typeof useMachineStatesSubscriptionSubscription>;
export type MachineStatesSubscriptionSubscriptionResult = ApolloReactCommon.SubscriptionResult<MachineStatesSubscriptionSubscription>;