export const getValidLocalStorageMachines = (
    localStorageMachines: string[] | null,
    installedMachines: string[],
    LOCAL_STORAGE_SELECTED_MACHINES: string
): string[] => {
    const cleanedUpLocalStorageMachines: string[] = [];

    if (localStorageMachines !== null) {
        localStorageMachines.forEach((id) => {
            if (installedMachines.includes(id)) {
                cleanedUpLocalStorageMachines.push(id);
            } else {
                localStorage.removeItem(LOCAL_STORAGE_SELECTED_MACHINES);
            }
        });
    }

    return cleanedUpLocalStorageMachines;
};
