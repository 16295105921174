import {machineStateHandler} from './machineStatesHandler';
import {machinesHandler} from './machinesHandler';
import {kpiOverviewHandler} from './kpiOverviewHandler';
import {
    jobHandler,
    jobHistoryHandler,
    jobHistoryWithoutMessagesHandler,
    jobsTimelineHandler,
} from './jobHistoryHandler';
import {calendarEventsHandler} from './calendarEventsHandler';
import {createCalendarEventsHandler} from './createCalendarEventsHandler';
import {
    weeklyTemplatesHandler,
    createWeeklyTemplatesHandler,
    updateWeeklyTemplatesHandler,
} from './weeklyTemplates/weeklyTemplatesHandler';
import {applicationsHandler} from './applicationsHandler';
import {userInformationHandler} from './userInformationHandler';

export const handlers = [
    applicationsHandler,
    calendarEventsHandler,
    createCalendarEventsHandler,
    createWeeklyTemplatesHandler,
    jobHandler,
    jobHistoryHandler,
    jobHistoryWithoutMessagesHandler,
    jobsTimelineHandler,
    kpiOverviewHandler,
    machinesHandler,
    machineStateHandler,
    updateWeeklyTemplatesHandler,
    userInformationHandler,
    weeklyTemplatesHandler,
];
