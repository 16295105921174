import {Accordion, AccordionDetails, AccordionSummary} from '@sym/common-ui/material';
import {css, styled} from '@sym/common-ui/material';

export const StyledSpan = styled('span')`
    :not(:last-child):after {
        content: ', ';
    }
`;

export const StyledAccordion = styled(Accordion)<{isMachinesWithoutData?: boolean}>`
    ${({theme: {spacing}, isMachinesWithoutData = false}) => css`
        box-shadow: none;
        padding: 0;
        margin-bottom: ${isMachinesWithoutData ? 0 : spacing(2)};
        ::before {
            visibility: hidden;
        }
    `}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    .MuiAccordionSummary-content {
        flex-grow: 0;
    }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    text-align: center;
`;
