import {graphql} from 'msw';
import {CalendarEvent, CalendarEventsQuery} from '../graphql/generated/graphql';

import calendarEvents from './fixtures/calendarEvents.json';

export const calendarEventsHandler = graphql.query<CalendarEventsQuery>('CalendarEvents', (req, res, context) => {
    return res(
        context.data({
            calendarEvents: calendarEvents as CalendarEvent[],
        })
    );
});
