import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE';
import {Theme} from '@sym/common-ui/material';
import {getResponsiveRules} from './responsiveConfig';

export type ChartConfig = {
    categoryAxis: am4charts.CategoryAxis;
    chart: am4charts.XYChart;
    dateAxis: am4charts.DateAxis;
    theme: Theme;
};

export const getChartConfig = ({chart, dateAxis, theme, categoryAxis}: ChartConfig) => {
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);

    chart.paddingLeft = 0;
    chart.paddingRight = 0;

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'zoomX';

    chart.language.locale = am4lang_en_US;
    chart.numberFormatter.language = new am4core.Language();
    chart.numberFormatter.language.locale = am4lang_de_DE;
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_de_DE;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'zoomX';
    chart.cursor.lineY.hidden = true;

    dateAxis.events.on('startchanged', dateAxisChanged);
    dateAxis.events.on('endchanged', dateAxisChanged);
    function dateAxisChanged(
        ev: am4core.AMEvent<typeof dateAxis, am4charts.IDateAxisEvents>['startchanged' | 'endchanged']
    ) {
        console.log(ev);
    }

    dateAxis.startLocation = 0;
    dateAxis.endLocation = 1;
    dateAxis.marginTop = 32;

    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = {count: 1, timeUnit: 'second'};
    dateAxis.strictMinMax = true;

    categoryAxis.dataFields.category = 'name';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    chart.responsive.enabled = true;
    chart.maskBullets = false;

    // chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarX.showOnInit = true;
    // chart.scrollbarX.background.fill = am4core.color(theme.palette.grey[500]);
    // chart.scrollbarX.background.fillOpacity = 0.2;
    // chart.scrollbarX.thumb.background.fill = am4core.color(theme.palette.grey[500]);
    // chart.scrollbarX.thumb.background.fillOpacity = 0.2;
    // chart.scrollbarX.startGrip.icon.disabled = true;
    // chart.scrollbarX.endGrip.icon.disabled = true;

    // chart.scrollbarX.startGrip.background.fill = am4core.color(theme.palette.grey[500]);
    // chart.scrollbarX.endGrip.background.fill = am4core.color(theme.palette.grey[500]);
    // chart.scrollbarX.thumb.background.fill = am4core.color(theme.palette.grey[700]);
    // chart.scrollbarX.startGrip.icon.stroke = am4core.color(theme.palette.grey[700]);
    // chart.scrollbarX.endGrip.icon.stroke = am4core.color(theme.palette.grey[700]);

    chart.responsive.rules.push(getResponsiveRules(theme));
};
