import {format} from 'date-fns';
import {JobHistoryItem} from '../../graphql/generated/graphql';

export type TableRowType = {startTime: string; id: string; name: string};

export const extractFromJobHistory = (item: JobHistoryItem): TableRowType => {
    const name = item.mainProgramName || 'n/a';
    const startTime = item.startTimestamp ? format(new Date(item.startTimestamp), 'dd.MM.yyyy H:mm:ss') : 'n/a';
    const id = item.id;

    return {name, startTime, id};
};
