import React, {memo, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {JobHistoryQuicklinksDrawer} from '../../../../components';
import {useMachineStatesLazyQuery} from '../../../../graphql/generated/graphql';
import {MACHINE_STATE_SUBSCRIPTION} from '../../../../subscriptions';
import {MachineFromQuery} from '../../../../App';
import _ from 'lodash';
import {ActiveJobContent} from './ActiveJob/JobInfoActive';
import {HistoricalJobContent} from './HistoricalJob/JobInfoHistorical';
import {CircularProgress, Drawer, Grid} from '@sym/common-ui/material';

interface JobsDetailPageProps {
    machines: MachineFromQuery[];
    isMobile: boolean;
}

const JobDetailPage: React.FC<JobsDetailPageProps> = ({machines, isMobile}) => {
    const {assetId, jobId, type} = useParams<{assetId: string; jobId: string; type: 'active' | 'historical'}>();
    const isActivePage = type === 'active';

    const {t} = useTranslation();

    const [fetchMachineState, {loading, error, data: machineStateData, subscribeToMore}] = useMachineStatesLazyQuery({
        variables: {machineIds: [assetId]},
    });

    const machineState = React.useMemo(() => machineStateData?.machineStates?.[0] || {}, [machineStateData]);

    const activeJobId = machineStateData?.machineStates[0].activeJob?.id;

    useEffect(() => {
        if (isActivePage) {
            fetchMachineState();
        }
    }, [isActivePage, fetchMachineState]);

    const [isQuicklinksDrawerOpen, setQuicklinksDrawerOpen] = useState(false);

    useLayoutEffect(() => {
        const unsubscribe = subscribeToMore?.({
            document: MACHINE_STATE_SUBSCRIPTION,
            variables: {machineIds: [assetId]},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev;
                }
                return Object.assign({}, prev, subscriptionData);
            },
        });
        return () => {
            unsubscribe?.();
        };
    }, [assetId, subscribeToMore]);

    // So we don't trigger rerenders in JobHistoryQuickLinks
    const handleClose = useCallback(() => {
        setQuicklinksDrawerOpen(false);
    }, []);

    if (loading)
        return (
            <Grid container justifyContent="center" xs>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );

    if (error) return <p>{t('Error')}</p>;

    return (
        <Grid container spacing={6}>
            {isActivePage && activeJobId ? (
                <ActiveJobContent
                    assetId={assetId}
                    activeJobId={activeJobId}
                    state={machineState}
                    machines={machines}
                    isMobile={isMobile}
                    setQuicklinksDrawerOpen={setQuicklinksDrawerOpen}
                />
            ) : (
                <HistoricalJobContent
                    machines={machines}
                    isMobile={isMobile}
                    setQuicklinksDrawerOpen={setQuicklinksDrawerOpen}
                />
            )}

            <Drawer
                open={isMobile ? isQuicklinksDrawerOpen : true}
                anchor="right"
                variant={isMobile ? 'temporary' : 'persistent'}
                onClose={handleClose}
            >
                <JobHistoryQuicklinksDrawer
                    assetId={assetId}
                    jobId={jobId || ''}
                    isMobile={isMobile}
                    onClose={handleClose}
                />
            </Drawer>
        </Grid>
    );
};

export const ActiveJobDetailPage = memo(JobDetailPage, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
