import React, {useEffect} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {am4themes_myTheme} from '../../../theme/GfmsTheme';
import {TimeData} from '../../../shared/types';

export interface SparklineChartProps {
    chartId: string;
    data: TimeData[];
}

am4core.useTheme(am4themes_myTheme);

export const SparklineChart: React.FC<SparklineChartProps> = ({chartId, data}: SparklineChartProps) => {
    useEffect(() => {
        const chart = am4core.create(`${chartId}`, am4charts.XYChart);

        chart.paddingRight = 20;

        const values = data;

        chart.data = values;

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.7;
        dateAxis.cursorTooltipEnabled = false;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;

        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.dateX = 'date';
        series.strokeWidth = 3;
        series.minBulletDistance = 15;

        return () => {
            chart.dispose();
        };
    }, [chartId, data]);

    return <div id={chartId} />;
};
