import {css, styled, Card} from '@sym/common-ui/material';

export const StyledCard = styled(Card)<{messageType: string | undefined}>`
    ${({theme: {spacing, palette}, messageType}) => css`
        padding: ${spacing(2)};
        width: 100%;
        background-color: ${palette.background.paper};

        ${messageType === 'Error' &&
        css`
            border: 1px solid ${palette.error.main};
            border-left: 4px solid ${palette.error.main};
        `}
    `}
`;
