import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE';

export type ChartLocaleConfig = {
    chart: am4charts.XYChart;
};

export const getChartLocaleConfig = ({chart}: ChartLocaleConfig) => {
    chart.language.locale = am4lang_de_DE;
    chart.numberFormatter.language = new am4core.Language();
    chart.numberFormatter.language.locale = am4lang_de_DE;
    chart.dateFormatter.language = new am4core.Language();
    chart.dateFormatter.language.locale = am4lang_de_DE;
};
