import {graphql} from 'msw';
import {v4 as uuid} from 'uuid';

import {CreateCalendarEventMutation, MutationCreateCalendarEventsArgs} from '../graphql/generated/graphql';

export const createCalendarEventsHandler = graphql.mutation<
    CreateCalendarEventMutation,
    MutationCreateCalendarEventsArgs
>('CreateCalendarEvent', (req, res, context) => {
    const {start, end, eventType} = req.variables.data[0];
    return res(
        context.data({
            createCalendarEvents: [
                {
                    start,
                    end,
                    id: uuid(),
                    eventType,
                },
            ],
        })
    );
});
