import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {
    ThemeOptions,
    unstable_createMuiStrictModeTheme as createMuiTheme,
    responsiveFontSizes,
    Theme,
    Components,
} from '@sym/common-ui/material';

const createGlobalOverrides: (theme: Theme) => Components = (theme) => {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                // TODO scrollbars are here
                '@global': {
                    '*': {
                        '&::-webkit-scrollbar': {
                            backgroundColor: 'transparent',
                            width: `${theme.spacing(1)}`,
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: theme.palette.divider,
                            margin: `${theme.spacing(1)}`,
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.divider,
                            width: `${theme.spacing(0.5)}`,
                            borderRadius: `${theme.spacing(0.5)}`,
                        },
                        '&::-webkit-scrollbar-button': {
                            width: `${theme.spacing(1)}`,
                            height: `${theme.spacing(1)}`,
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: theme.palette.text.secondary,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.secondary,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                fullWidth: {
                    minWidth: 'auto',
                },
            },
        },
    };
};

const options: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#e18142',
        },
        secondary: {
            main: '#005499',
        },
        error: {
            main: '#C16C7A',
        },
        success: {
            main: '#69AC58',
        },
        background: {
            default: '#141414',
            paper: '#2B2B2B',
        },
    },
    typography: {
        fontFamily: ['DIN Next W1G', 'DIN', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
};

export const theme = responsiveFontSizes(createMuiTheme(options));

theme.components = {
    ...theme.components,
    ...createGlobalOverrides(theme),
};

//  Find out more in the Docs: https://www.amcharts.com/docs/v4/tutorials/creating-themes/
export function am4themes_myTheme(target: any) {
    if (target instanceof am4charts.AxisRenderer) {
        // target.minLabelPosition = 0.1;
        // target.maxLabelPosition = 0.9;
    }
    if (target instanceof am4core.InterfaceColorSet) {
        target.setFor('text', am4core.color(theme.palette.text.primary));
        target.setFor('alternativeText', am4core.color(theme.palette.text.secondary));
    }
    if (target instanceof am4core.ColorSet) {
        target.list = [
            am4core.color('#FFFF00'),
            am4core.color('#EF6000'),
            am4core.color('#D500F9'),
            am4core.color('#2979FF'),
            am4core.color('#00E676'),
        ];
    }
}

// UnitState and UnitMode COLORS
export const COLOR_EXECUTING = theme.palette.success.main;
export const COLOR_OUT_OF_SERVICE = theme.palette.error.main;
export const COLOR_WAITING = theme.palette.info.main;
export const COLOR_UNKNOWN = theme.palette.grey[600];
export const COLOR_UNDEFINED = theme.palette.grey[400];
export const COLOR_SETUP = theme.palette.warning.main;
export const COLOR_MAINTENANCE = theme.palette.info.main;
export const COLOR_PRODUCTION = '#9575CD';
