import {useApolloClient} from '@apollo/client';
import {useCallback, useEffect, useState} from 'react';
import {Data, MachineData} from '../../../shared/types';
import {KpiOverviewQuery} from '../../../graphql/generated/graphql';
import {GET_KPIDATA} from '../../../queries';
import {parseDateToLocalDateTime} from '../../../util';
import {extractDataFromQuery} from '../util';
import {MachineFromQuery} from '../../../App';

export interface KpiOverviewPerMachineState {
    allocationEfficiencyState: Data[];
    availabilityState: Data[];
    setupRatioState: Data[];
    technicalEfficiencyState: Data[];
    utilizationEfficiencyState: Data[];
}

const INITIAL_KPI_OVERVIEW_PER_MACHINE_STATE: KpiOverviewPerMachineState = {
    allocationEfficiencyState: [],
    availabilityState: [],
    setupRatioState: [],
    technicalEfficiencyState: [],
    utilizationEfficiencyState: [],
};

export interface UseKpiOverviewPerMachineQueryResult {
    data: KpiOverviewPerMachineState;
    loading: boolean;
    error: boolean;
}

export const useKpiOverviewPerMachineQuery = (
    installedAndSelectedMachineIds: string[],
    installedMachines: MachineFromQuery[],
    from: Date,
    to: Date
): UseKpiOverviewPerMachineQueryResult => {
    const client = useApolloClient();
    const [kpiOverViewPerMachineState, setKpiOverPerViewMachineState] = useState<KpiOverviewPerMachineState>(
        INITIAL_KPI_OVERVIEW_PER_MACHINE_STATE
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const getData = useCallback(
        () =>
            Promise.all(
                installedAndSelectedMachineIds.map((machineId) =>
                    client.query<KpiOverviewQuery>({
                        query: GET_KPIDATA,
                        variables: {
                            from: parseDateToLocalDateTime(from),
                            to: parseDateToLocalDateTime(to),
                            machineIds: [machineId],
                        },
                        fetchPolicy: 'network-only',
                    })
                )
            ),
        [installedAndSelectedMachineIds, from, to, client]
    );

    useEffect(() => {
        setLoading(true);
        getData()
            .then((result) => {
                const technicalEfficiencyState: MachineData[] = extractDataFromQuery(
                    result,
                    'technicalEfficiency',
                    installedMachines,
                    installedAndSelectedMachineIds,
                    'technicalEfficiencyPerMachine'
                );
                const setupRatioState: MachineData[] = extractDataFromQuery(
                    result,
                    'setupRatio',
                    installedMachines,
                    installedAndSelectedMachineIds,
                    'setupRatioPerMachine'
                );
                const utilizationEfficiencyState: MachineData[] = extractDataFromQuery(
                    result,
                    'utilizationEfficiency',
                    installedMachines,
                    installedAndSelectedMachineIds,
                    'utilizationEfficiencyPerMachine'
                );
                const allocationEfficiencyState: MachineData[] = extractDataFromQuery(
                    result,
                    'allocationEfficiency',
                    installedMachines,
                    installedAndSelectedMachineIds,
                    'allocationEfficiencyPerMachine'
                );
                const availabilityState: MachineData[] = extractDataFromQuery(
                    result,
                    'availability',
                    installedMachines,
                    installedAndSelectedMachineIds,
                    'availabilityPerMachine'
                );
                setKpiOverPerViewMachineState({
                    allocationEfficiencyState,
                    availabilityState,
                    setupRatioState,
                    technicalEfficiencyState,
                    utilizationEfficiencyState,
                });
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [getData, installedMachines, installedAndSelectedMachineIds]);

    return {data: kpiOverViewPerMachineState, loading, error};
};
