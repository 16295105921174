import React from 'react';
import {add, differenceInMilliseconds, startOfTomorrow} from 'date-fns';
import {getStatus, JobHistoryQuickLinksButton, RelatedMessages} from '../../../../../components';
import {MachineTileHeader} from '../../../../../components/MachineTile/MachineTileHeader';
import {formatTime, formatDistanceWithLocale, dateToSameDateInUTC} from '../../../../../util';
import {Infobox} from '../../Infobox';
import {StyledProgramFile} from '../../JobsPage.styled';
import {useTranslation} from 'react-i18next';
import {MachineFromQuery} from '../../../../../App';
import {MachineStatesQuery, useJobHistoryWithoutMessagesQuery} from '../../../../../graphql/generated/graphql';
import {Grid, Typography} from '@sym/common-ui/material';
import {ProgressBar} from '../../../../../components/ProgressBar';
import {getAvatarOrFallback} from '../../../../../components/MachineTile/util';
import {LocalDateTime, nativeJs} from '@js-joda/core';

const tomorrow: Date = startOfTomorrow();
const from = LocalDateTime.from(nativeJs(tomorrow)).toString();

interface ActiveJobContentProps {
    state: MachineStatesQuery['machineStates'][0];
    machines: MachineFromQuery[];
    assetId: string;
    activeJobId: string;
    isMobile: boolean;
    setQuicklinksDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ActiveJobContent: React.FC<ActiveJobContentProps> = ({
    isMobile,
    machines,
    assetId,
    activeJobId,
    state,
    setQuicklinksDrawerOpen,
}) => {
    const machine = machines.find((machine) => machine.id === state.machineId);

    const {t} = useTranslation();
    const machineName = machine?.displayName || 'n/a';
    const modelName = machine?.machineModel?.modelName || 'n/a';

    const machineState = getStatus(state);
    const machineMode = state.unitStatusMode;

    const mainProgramFile = state.activeJob?.mainProgramFile;

    const remainingTimeLabel = state.activeJob?.estimatedRemainingRuntime
        ? formatTime(state.activeJob.estimatedRemainingRuntime)
        : 'n/a';
    const estimatedRemainingRuntime = state.activeJob?.estimatedRemainingRuntime || null;
    const mainProgramName = state.activeJob?.mainProgramName ?? '';
    const startTimestamp = state.activeJob?.startTimestamp;

    const currentMachineTime = add(dateToSameDateInUTC(new Date()), {minutes: state.lastSourceTimestampOffset || 0});

    const elapsedTimeInMillis = startTimestamp
        ? differenceInMilliseconds(currentMachineTime, new Date(startTimestamp))
        : null;

    const elapsedTimeLabel = elapsedTimeInMillis ? formatTime(elapsedTimeInMillis) : 'n/a';

    const lastUpdate = state.lastDocumentUpdateUTC
        ? `${formatDistanceWithLocale(new Date(state.lastDocumentUpdateUTC), new Date(), {
              includeSeconds: true,
          })} ${t('machineTile.Ago')}`
        : '--';

    const getProgressValue = () => {
        if (elapsedTimeInMillis === null || elapsedTimeInMillis === 0 || estimatedRemainingRuntime === null) {
            return 0;
        }

        if (estimatedRemainingRuntime === 0) {
            return 1;
        }

        return (elapsedTimeInMillis / (elapsedTimeInMillis + estimatedRemainingRuntime)) * 100;
    };

    const avatar = getAvatarOrFallback(machine?.machineModel?.avatar);

    // TODO: CLEAN THIS UP!!!

    const {
        data: jobHistoryData,
        error,
        loading,
    } = useJobHistoryWithoutMessagesQuery({
        variables: {assetId, from, count: 1},
    });

    const latestJobHistoryItem = jobHistoryData?.asset?.jobHistory[0];

    const isActiveJobInColdData = Boolean(
        latestJobHistoryItem?.startTimestamp === state.activeJob?.startTimestamp?.split('.')[0]
    );

    return (
        <Grid container item spacing={3} xs={isMobile ? 12 : 8}>
            <Grid item xs={12} sm={4}>
                <MachineTileHeader imageSrc={avatar} machineName={machineName} modelName={modelName} />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Infobox label={t('machineTile.MachineState')} value={machineState} />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Infobox label={t('machineTile.MachineMode')} value={machineMode} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Infobox label={t('machineTile.MainProgramName')} value={mainProgramName} />
            </Grid>
            <Grid item xs={12} sm={8}>
                <Infobox
                    label={t('machineTile.MainProgramFile')}
                    value={<StyledProgramFile>{mainProgramFile || '--'}</StyledProgramFile>}
                    title={mainProgramFile || undefined}
                />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Infobox label={t('machineTile.ElapsedTime')} value={elapsedTimeLabel} />
            </Grid>
            <Grid item xs={6} sm={4}>
                <Infobox label={t('machineTile.RemainingTime')} value={remainingTimeLabel} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Infobox label={t('machineTile.LastUpdate')} value={lastUpdate} />
            </Grid>
            <Grid item xs={12}>
                <ProgressBar headline={t('jobs.Progress')} value={getProgressValue()} />
            </Grid>

            {isActiveJobInColdData && latestJobHistoryItem ? (
                <RelatedMessages isMobile={isMobile} assetId={assetId} jobId={latestJobHistoryItem.id} isActiveJob />
            ) : (
                <Grid item xs={12}>
                    <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                        {t('jobs.isActiveMessageInfo')}
                    </Typography>
                </Grid>
            )}

            {isMobile && <JobHistoryQuickLinksButton onClick={() => setQuicklinksDrawerOpen(true)} />}
        </Grid>
    );
};
