// https://material-ui-pickers.dev/demo/datepicker
import clsx from 'clsx';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import React from 'react';
import isWithinInterval from 'date-fns/isWithinInterval';
import {useTranslation} from 'react-i18next';

import {IconButton, TextField} from '@sym/common-ui/material';
import {endOfWeekWithLocale, formatWithLocale, startOfWeekWithLocale} from '../../util/i18n';
import {gfmsTheme} from '@sym/common-ui/themes';
import {DatePicker, PickersDayProps} from '@sym/common-ui/lab';
import {isSameMonth} from 'date-fns/esm';

interface WeekPickerProps {
    value: Date;
    handleChange: any;
    isDisabled?: boolean;
    // classes: any;
}
const WeekPickerInner: React.FC<WeekPickerProps> = ({value, handleChange, isDisabled}) => {
    const {t} = useTranslation();

    const classes: any = {};
    const formatWeekSelectLabel = (date: Date, invalidLabel: string) => {
        let dateClone = date;

        const firstDay = startOfWeekWithLocale(dateClone);
        const validLabel = `${t('WeekOf')} ${formatWithLocale(firstDay, 'dd. MMM')}`;

        return dateClone && isValid(dateClone) ? validLabel : invalidLabel;
    };

    const renderWrappedWeekDay = (
        date: Date,
        selectedDates: (Date | null)[],
        pickersDayProps: PickersDayProps<Date>
    ) => {
        // @ts-ignore
        let dateClone = date;
        let selectedDateClone = selectedDates[0] || new Date();

        const dayInCurrentMonth = isSameMonth(selectedDateClone, new Date());

        const start = startOfWeekWithLocale(selectedDateClone);
        const end = endOfWeekWithLocale(selectedDateClone);

        const dayIsBetween = isWithinInterval(dateClone, {start, end});
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, 'd')} </span>
                </IconButton>
            </div>
        );
    };

    return (
        <DatePicker
            renderInput={(props) => {
                return <TextField {...props} value="asdf" />;
            }}
            disabled={isDisabled}
            label={t('productionPlanner.applyTemplate.step2.weekpickerLabel')}
            value={value}
            onChange={(date) => {
                return handleChange(date);
            }}
            // renderDay={renderWrappedWeekDay}
            // labelFunc={formatWeekSelectLabel}
        />
    );
};

// const styles = createStyles((theme: any) => ({
//     dayWrapper: {
//         position: 'relative',
//     },
//     day: {
//         width: 36,
//         height: 36,
//         fontSize: theme.typography.caption.fontSize,
//         margin: '0 2px',
//         color: 'inherit',
//     },
//     customDayHighlight: {
//         position: 'absolute',
//         top: 0,
//         bottom: 0,
//         left: '2px',
//         right: '2px',
//         border: `1px solid ${theme.palette.secondary.main}`,
//         borderRadius: '50%',
//     },
//     nonCurrentMonthDay: {
//         color: theme.palette.text.disabled,
//     },
//     highlightNonCurrentMonthDay: {
//         color: '#676767',
//     },
//     highlight: {
//         background: theme.palette.primary.main,
//         color: theme.palette.common.white,
//     },
//     firstHighlight: {
//         extend: 'highlight',
//         borderTopLeftRadius: '50%',
//         borderBottomLeftRadius: '50%',
//     },
//     endHighlight: {
//         extend: 'highlight',
//         borderTopRightRadius: '50%',
//         borderBottomRightRadius: '50%',
//     },
// }));

// export const WeekPicker = withStyles(styles, {defaultTheme: gfmsTheme})(WeekPickerInner);
export const WeekPicker = WeekPickerInner;
