import {onError} from '@apollo/client/link/error';
import {HttpLink, split} from '@apollo/client';
import {Configuration} from '../AppConfig';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {resolveAuthToken} from './authLink';

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const wsLink = new WebSocketLink({
    uri: Configuration.MachineStateWebsocketUri || '',
    options: {
        reconnect: true,

        connectionParams: async () => {
            const authToken = await resolveAuthToken();
            return {Authorization: `Bearer ${authToken}`};
        },
    },
});

const machineStateLink = new HttpLink({
    uri: Configuration.MachineStateUri,

    fetch: (...args) => fetch(...args),
});

const gatewayLink = new HttpLink({
    uri: Configuration.GatewayUri,

    fetch: (...args) => fetch(...args),
});

export const splitIfMachineState = split(
    ({query}) => {
        const definition = getMainDefinition(query);

        const isMachineStateQuery = definition.name?.value === 'MachineStates';

        return isMachineStateQuery;
    },
    machineStateLink,
    gatewayLink
);

export const splitIfSubscription = split(
    ({query}) => {
        const definition = getMainDefinition(query);

        const isSubscription = definition.kind === 'OperationDefinition' && definition.operation === 'subscription';

        return isSubscription;
    },
    wsLink,
    splitIfMachineState
);
