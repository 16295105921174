import {TimePicker} from '@sym/common-ui/lab';
import {css, styled} from '@sym/common-ui/material';
import {Button, Card} from '@sym/common-ui/material';

export const StyledCard = styled(Card)`
    ${({theme: {spacing, palette, breakpoints}}) => css`
        position: absolute;
        width: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${palette.background.paper};
        padding: ${spacing(3, 3, 3)};
        overflow: auto;

        ${breakpoints.up('sm')} {
            width: auto;
        }
    `}
`;

export const StyledTimePicker = styled(TimePicker)`
    ${({theme: {spacing, palette}}) => css`
        width: 100%;
    `}
`;

export const StyledCancelButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        margin-right: ${spacing(2)};
    `}
`;
