import {graphql} from 'msw';

import assets from './fixtures/machines.json';

export const machinesHandler = graphql.query('Machines', (req, res, context) =>
    res(
        context.data({
            assets,
        })
    )
);
