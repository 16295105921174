import {graphql} from 'msw';
import userInformation from './fixtures/userInformation.json';

export const userInformationHandler = graphql.query('UserInformation', (req, res, context) => {
    return res(
        context.data({
            myTenantUser: userInformation,
        })
    );
});
