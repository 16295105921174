import {SyntheticEvent} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Routes} from '../../../routes';
import _ from 'lodash';
import {MachinesQuery, MachineState, UnitStatusMode} from '../../../graphql/generated/graphql';
import {Grid, Tooltip, Chip} from '@sym/common-ui/material';
import {getLastUpdateLabel, getUnitStatusModeLabel, isLastUpdateMoreThan24Hours} from '../util';
import {Infobox} from '../..';
import {StyledTypography} from './MachineInformation.styled';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import CloudOffRoundedIcon from '@mui/icons-material/CloudOffRounded';
import NightlightRoundedIcon from '@mui/icons-material/NightlightRounded';
import NoMachineInformation from './NoMachineInformation/NoMachineInformation';
import PrecisionManufacturingRoundedIcon from '@mui/icons-material/PrecisionManufacturingRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcons from './MessageIcons/MessageIcons';

interface MachineInformationProps {
    machine: MachinesQuery['assets'][0] | undefined;
    machineState: MachineState;
}

export function MachineInformation({machine, machineState}: MachineInformationProps) {
    const {t} = useTranslation();
    const history = useHistory();

    const {userMessage, unitMessage, unitStatusMode, lastDocumentUpdateUTC} = machineState;

    const hasInfo = Boolean(machine);
    const messages = [userMessage, unitMessage];
    const errorMessages = messages.filter((message) => message?.type === 'Error');
    const warningMessages = messages.filter((message) => message?.type === 'Warning');
    const isError = errorMessages.length >= 1;
    const isWarning = warningMessages.length >= 1;

    const unitStatusModeLabel = getUnitStatusModeLabel(unitStatusMode || undefined, t);
    const isLastUpdateMoreThan24HoursAgo = lastDocumentUpdateUTC && isLastUpdateMoreThan24Hours(lastDocumentUpdateUTC);
    const lastUpdateLabel = lastDocumentUpdateUTC && getLastUpdateLabel(lastDocumentUpdateUTC, t);

    const handleOnIconClick = (event: SyntheticEvent) => {
        event.stopPropagation();
        history.push(`${Routes.jobDetailLink}/${machineState.machineId}/${machineState?.activeJob?.id}/active`);
    };

    const getUnitStatusModeIcon = (unitMode: UnitStatusMode | undefined | null) => {
        switch (unitMode) {
            case 'Production':
                return <PrecisionManufacturingRoundedIcon />;
            case 'Setup':
                return <SettingsIcon />;
            case 'Maintenance':
                return <BuildRoundedIcon />;
            case 'Unused':
                return <NightlightRoundedIcon />;
            case 'Unknown':
                return <QuestionMarkRoundedIcon />;
            default:
                return undefined;
        }
    };

    return (
        <>
            <Grid>
                <Grid container item justifyContent="space-between" columnSpacing={1} alignItems="center">
                    <Grid item xs>
                        <StyledTypography variant="subtitle2" gutterBottom>
                            {machine?.displayName || t('NoInformation')}
                        </StyledTypography>
                    </Grid>
                    {lastUpdateLabel && isLastUpdateMoreThan24HoursAgo && (
                        <Grid item>
                            <Tooltip title={lastUpdateLabel}>
                                <CloudOffRoundedIcon
                                    color="disabled"
                                    fontSize="medium"
                                    data-testid="machineTile-lastUpdateIcon"
                                />
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Infobox
                    label={`${t('machineTile.SerialNumber')}:`}
                    value={_.startCase(machine?.serialNumber || t('NoInformation'))}
                />
            </Grid>
            {hasInfo ? (
                <Grid container justifyContent="space-between" alignItems="center" xs={12}>
                    <Grid item>
                        <Chip
                            size="small"
                            label={unitStatusModeLabel}
                            title={`${t('machineTile.MachineMode')}: ${unitStatusModeLabel}`}
                            icon={getUnitStatusModeIcon(unitStatusMode)}
                        />
                    </Grid>
                    <MessageIcons isWarning={isWarning} isError={isError} onIconClick={handleOnIconClick} />
                </Grid>
            ) : (
                <NoMachineInformation
                    headline={t('machineTile.NoInformation')}
                    description={t('machineTile.GetHelp')}
                    href="/machines"
                />
            )}
        </>
    );
}

export default MachineInformation;
