import {MachineFromQuery} from '../../App';
import {ApplicationsQuery} from '../../graphql/generated/graphql';
import {MachineGrouping} from './MachineGroupSelector';

const VALID_APP_IDS = new Set(['perfanalapp', 'gfmsopcua']);

const getLabelForGrouping = (machine: MachineFromQuery, grouping: MachineGrouping): string => {
    switch (grouping) {
        case 'BRAND':
            return machine.machineModel?.brand || 'No brand';
        case 'MODEL':
            return machine.machineModel?.modelName || 'No model';
        case 'FACILITY':
            return machine.facility?.name || 'No facility';
        case 'TECHNOLOGY':
            return machine.machineModel?.technology || 'No technology';
        case 'NONE':
            return 'no grouping';
    }
};

export const getGroupedMachines = (
    machines: MachineFromQuery[],
    machineGrouping: MachineGrouping
): [string, MachineFromQuery[]][] => {
    const groupingMap = new Map<string, MachineFromQuery[]>();

    machines.forEach((machine) => {
        const label = getLabelForGrouping(machine, machineGrouping);
        const entries = groupingMap.get(label) || [];
        entries.push(machine);
        groupingMap.set(label, entries);
    });

    const labelMachineTuple: [string, MachineFromQuery[]][] = [];
    groupingMap.forEach((value, key) => {
        labelMachineTuple.push([key, value]);
    });

    labelMachineTuple.sort((a, b) => {
        return a[0].localeCompare(b[0]);
    });

    return labelMachineTuple;
};


export const getInstalledMachines = (
    machines: MachineFromQuery[],
    data: ApplicationsQuery | undefined
): {installedMachines: MachineFromQuery[]; uninstalledMachines: MachineFromQuery[]} => {
    if (!data) {
        return {installedMachines: [], uninstalledMachines: []};
    }
    const applications = data.applications;

    // note: multiple apps can have id 'perfanalapp'
    const perfanalapps = applications?.filter((app) => app?.id && VALID_APP_IDS.has(app?.id));

    const installedMachines: MachineFromQuery[] = [];
    const uninstalledMachines: MachineFromQuery[] = [];

    machines.forEach((machine) => {
        let installed = false;
        perfanalapps?.forEach((app) => {
            app?.installations?.forEach((installation) => {
                if (installation?.asset?.id === machine.id) {
                    installedMachines.push(machine);
                    installed = true;
                }
            });
        });
        if (installed === false) {
            uninstalledMachines.push(machine);
        }
    });
    return {installedMachines, uninstalledMachines};
};
