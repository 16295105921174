import {graphql} from 'msw';

import applications from './fixtures/applications.json';

export const applicationsHandler = graphql.query('Applications', (req, res, context) =>
    res(
        context.data({
            applications,
        })
    )
);
