import {Collapse, Grid, Typography} from '@sym/common-ui/material';

import {ArrowDropUpRounded, ArrowDropDownRounded} from '@sym/common-ui/icons-material';

import React from 'react';
import {UnconnectedMachineTile} from '..';
import {MachineFromQuery} from '../../App';
import {useTranslation} from 'react-i18next';

interface UnconnectedGroupingProps {
    machines: MachineFromQuery[];
    isOpen: boolean;
    onClick(): void;
}

export const UnconnectedGrouping: React.FC<UnconnectedGroupingProps> = ({machines, onClick, isOpen}) => {
    const {t} = useTranslation();

    const sortedMachines = [...machines].sort((a, b) => {
        return (a.displayName || '--').localeCompare(b.displayName || '--');
    });

    return (
        <Grid container item xs={12} onClick={onClick}>
            <Grid container item xs={12}>
                <Grid item>
                    <Typography variant="h5" color="textSecondary" paragraph>
                        {t('machinesPage.unConnectedMachines')}
                    </Typography>
                </Grid>
                <Grid item> {isOpen ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}</Grid>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={isOpen} unmountOnExit={false}>
                    <Grid container spacing={3}>
                        {sortedMachines.map((machine) => {
                            return (
                                <Grid key={machine.id} item xs={12} sm={6} md={4} lg={4} xl={3}>
                                    <UnconnectedMachineTile machine={machine} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};
