import {Grid} from '@sym/common-ui/material';
import {css, styled} from '@sym/common-ui/material';

export const StyledNavigationGridContainer = styled(Grid)`
    ${({theme: {breakpoints}}) => css`
        overflow: scroll;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */

        ${breakpoints.up('sm')} {
        }
    `}
`;

export const StyledProgramFile = styled('div')`
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
`;
