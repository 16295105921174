import {StyledLayoutContainer, StyledNavigationContainer} from './Layout.styled';
import {Navigation} from '../components';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Routes} from '../routes';
import {Button, Grid} from '@sym/common-ui/material';

import {ArrowBackIosRounded as ArrowBackIosRoundedIcon} from '@sym/common-ui/icons-material';

const BackButton = () => {
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <Button
            data-testid="navigation-back-button"
            color="primary"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosRoundedIcon />}
        >
            {t('Back')}
        </Button>
    );
};

export const Layout: React.FC = ({children}) => {
    const {t} = useTranslation();
    const location = useLocation();

    const shouldShowBackButton: boolean = location.pathname.startsWith(Routes.jobDetailLink);

    return (
        <StyledLayoutContainer>
            <StyledNavigationContainer shouldShowBackButton={shouldShowBackButton}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Navigation
                            tabs={[
                                {
                                    label: t('Navigation.Machines'),
                                    route: Routes.machines,
                                },
                                {
                                    label: t('Navigation.KpiOverview'),
                                    route: Routes.kpiOverview,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {shouldShowBackButton && <BackButton />}
                    </Grid>
                </Grid>
            </StyledNavigationContainer>
            {children}
        </StyledLayoutContainer>
    );
};
