import React, {memo} from 'react';
import {MachinesQuery} from '../../graphql/generated/graphql';
import {StyledCard, StyledContainer, StyledImageContainer} from './UnconnectedMachineTile.styled';
import {Typography} from '@sym/common-ui/material';

import {PortableWifiOff as PortableWifiOffIcon} from '@sym/common-ui/icons-material';

import _ from 'lodash';
import {getAvatarOrFallback} from './util';
import fallbackImage from '../../assets/machine_fallback.jpg';

interface UnconnectedMachineTileProps {
    machine: MachinesQuery['assets'][0] | undefined;
}

export const UnconnectedMachineTileInner: React.FC<UnconnectedMachineTileProps> = ({machine}) => {
    const machineName = machine?.displayName;
    const modelName = machine?.machineModel?.modelName || '--';

    const avatar = getAvatarOrFallback(machine?.machineModel?.avatar);

    return (
        <StyledCard data-testid={`machine-tile-${machine?.id}`}>
            <StyledContainer>
                <StyledImageContainer>
                    <img
                        src={avatar}
                        onError={(e) => {
                            // @ts-ignore
                            e.target.src = fallbackImage;
                        }}
                        alt="machine avatar"
                    />
                </StyledImageContainer>
                <div>
                    <Typography variant="subtitle1">{machineName}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {modelName}
                    </Typography>
                </div>
            </StyledContainer>
            <PortableWifiOffIcon color="disabled" />
        </StyledCard>
    );
};
export const UnconnectedMachineTile = memo(UnconnectedMachineTileInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
