import {MachineFromQuery} from '../../App';
import {MachineState} from '../../graphql/generated/graphql';

// We need to summarize machines and machineStates due to sort the machineTiles before mapping
// because the gateway can't handle subscriptions, it currently has to be solved in such an ugly way
export const getSummarizedMachineData = (
    machines: MachineFromQuery[],
    machineStates: MachineState[]
): [MachineFromQuery, MachineState][] => {
    return machines.map((machine, key) => {
        const machineState = machineStates.find((machineState) => machines[key].id === machineState.machineId) ?? {};
        return [machine, machineState];
    });
};
