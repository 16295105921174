import {addDays, differenceInCalendarDays, endOfDay, startOfDay} from 'date-fns';
import {JobHistoryItem, UnitModeInterval} from '../../../../graphql/generated/graphql';
import {JobsTimelineItem} from '../../../../shared/types';
import {
    COLOR_SETUP,
    COLOR_OUT_OF_SERVICE,
    COLOR_UNKNOWN,
    COLOR_MAINTENANCE,
    COLOR_EXECUTING,
    COLOR_PRODUCTION,
} from '../../../../theme/GfmsTheme';

export const getUnitModeColor = (unitMode: string): string => {
    switch (unitMode) {
        case 'SETUP':
            return COLOR_SETUP;
        case 'MAINTENANCE':
            return COLOR_MAINTENANCE;
        case 'UNUSED':
            return COLOR_UNKNOWN;
        case 'UNKNOWN':
            return COLOR_UNKNOWN;
        case 'UNDEFINED':
            return COLOR_UNKNOWN;
        default:
            return COLOR_PRODUCTION;
    }
};

export const unitModesToJobTimelineItems = (unitModes: UnitModeInterval[], t: Function): JobsTimelineItem[] => {
    const unitModesWithColors: JobsTimelineItem[] = [];
    const cleanedUnitModes = unitModes.filter((unitMode) => {
        switch (unitMode.unitMode) {
            case 'SETUP':
                return true;
            case 'MAINTENANCE':
                return true;
            case 'UNUSED':
                return false;
            case 'UNKNOWN':
                return false;
            case 'UNDEFINED':
                return false;
            default:
                return true;
        }
    });

    const getUnitModeLabelText = (unitMode: string): string => {
        switch (unitMode) {
            case 'SETUP':
                return t('jobTimeline.unitModes.setup');
            case 'MAINTENANCE':
                return t('jobTimeline.unitModes.maintenance');
            default:
                return t('jobTimeline.unitModes.production');
        }
    };

    cleanedUnitModes.forEach((unitMode) => {
        // if there's no end date we throw it away
        // can only happen for the last unitState in the interval
        if (!unitMode.to) {
            return;
        }
        const type = 'unitMode';
        const label = getUnitModeLabelText(unitMode.unitMode);
        const name = t('jobTimeline.unitMode');
        const fromDate = new Date(unitMode.from);
        const toDate = new Date(unitMode.to) > new Date() ? new Date() : new Date(unitMode.to);
        const color = getUnitModeColor(unitMode.unitMode);

        unitModesWithColors.push({type, label, name, fromDate, toDate, color});
    });

    return unitModesWithColors;
};

export const jobsToJobTimelineItems = (jobs: JobHistoryItem[], t: Function): JobsTimelineItem[] => {
    const jobsTimeline: JobsTimelineItem[] = [];
    const jobsWithColor = jobs.map((job) => {
        if (job.hasErrors) {
            return {...job, color: COLOR_OUT_OF_SERVICE};
        } else {
            return {...job, color: COLOR_EXECUTING};
        }
    });

    jobsWithColor.forEach((job, key) => {
        // if there's no end date we throw it away
        // can only happen for the last unitState in the interval

        if (!job.endTimestamp) {
            return;
        }
        const type = 'job';
        const id = job.id;
        const label = job.mainProgramName || 'n/a';
        const name = t('jobTimeline.job');
        const fromDate = new Date(job.startTimestamp!);
        const toDate = new Date(job.endTimestamp);
        const color = job.color;

        jobsTimeline.push({type, id, label, name, fromDate, toDate, color});
    });

    return jobsTimeline;
};

export const combineJobsAndUnitModes = (
    jobs: JobHistoryItem[],
    unitModes: UnitModeInterval[],
    t: Function
): JobsTimelineItem[] | undefined => {
    const mappedJobs = jobsToJobTimelineItems(jobs, t);
    const mappedUnitModes = unitModesToJobTimelineItems(unitModes, t);

    const combinedTimelineItems = [...mappedJobs, ...mappedUnitModes];

    const overlappingItems = combinedTimelineItems.filter(
        (timelineItem: JobsTimelineItem) => startOfDay(timelineItem.fromDate) < startOfDay(timelineItem.toDate)
    );

    const finalTimelineItems: JobsTimelineItem[] = [...combinedTimelineItems];

    overlappingItems.forEach((item) => {
        //  we first want to find out if the item skips more than a whole day
        if (checkIfItemIsLongerThanADay(item)) {
            const overlappingDays = getDatesWithinDateRange(item.fromDate, item.toDate);

            overlappingDays?.forEach((day) => {
                var currentDate = addDays(day, 1);

                // we insert a 24h JobsTimelineItem for each whole day the items date range is overlapping
                if (startOfDay(currentDate).getMilliseconds() < startOfDay(item.toDate).getMilliseconds()) {
                    const type = item.label === t('jobTimeline.job') ? 'job' : 'unitMode';
                    const id = item.name === t('jobTimeline.job') ? item.id : undefined;
                    const label = item.label + ' (' + t('jobTimeline.overlapping') + ')' || 'n/a';
                    const name = item.name === t('jobTimeline.job') ? t('jobTimeline.job') : t('jobTimeline.unitMode');
                    const fromDate = startOfDay(currentDate);
                    const toDate = endOfDay(currentDate);
                    const color = item.color;
                    finalTimelineItems.push({type, id, label, name, fromDate, toDate, color});
                }
                // finally we insert an object for the toDate where the overlapping item ends
                if (startOfDay(currentDate).getMilliseconds() === startOfDay(item.toDate).getMilliseconds()) {
                    const type = item.color === COLOR_EXECUTING ? 'job' : 'unitMode';
                    const id = item.name === t('jobTimeline.job') ? item.id : undefined;
                    const label = item.label + ' (' + t('jobTimeline.overlapping') + ')' || 'n/a';
                    const name = item.name === t('jobTimeline.job') ? t('jobTimeline.job') : t('jobTimeline.unitMode');
                    const fromDate = startOfDay(currentDate);
                    const toDate = item.toDate;
                    const color = item.color;
                    finalTimelineItems.push({type, id, label, name, fromDate, toDate, color});
                } else {
                    return;
                }
            });
        } else {
            const type = item.label === t('jobTimeline.job') ? 'job' : 'unitMode';
            const id = item.name === t('jobTimeline.job') ? item.id : undefined;
            const label = item.label + ' (' + t('jobTimeline.overlapping') + ')' || 'n/a';
            const name = item.name === t('jobTimeline.job') ? t('jobTimeline.job') : t('jobTimeline.unitMode');
            const fromDate = startOfDay(item.toDate);
            const toDate = item.toDate;
            const color = item.color;
            finalTimelineItems.push({type, id, label, name, fromDate, toDate, color});
        }
    });
    const sortedTimelineItems = finalTimelineItems.sort((a, b) => {
        return a.fromDate.getTime() - b.fromDate.getTime();
    });
    if (sortedTimelineItems.length < 1) {
        return undefined;
    } else {
        return sortedTimelineItems;
    }
};

export const getDatesWithinDateRange = (
    startDate: Date | undefined,
    stopDate: Date | undefined
): Date[] | undefined => {
    var dateArray = [];
    if (startDate !== undefined && stopDate !== undefined) {
        var currentDate = startDate;

        while (differenceInCalendarDays(stopDate, currentDate) > 0) {
            dateArray.push(startOfDay(new Date(currentDate)));
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    } else {
        return;
    }
};

export const checkIfItemIsLongerThanADay = (JobsTimelineItem: JobsTimelineItem): Boolean => {
    if (differenceInCalendarDays(JobsTimelineItem.toDate, JobsTimelineItem.fromDate) > 0) {
        return true;
    } else {
        return false;
    }
};
