import {Collapse, Grid, Typography} from '@sym/common-ui/material';

import {ArrowDropDownRounded, ArrowDropUpRounded} from '@sym/common-ui/icons-material';

import React, {memo, useEffect, useState} from 'react';
import {MachineFromQuery} from '../../App';
import {MachineStatesQuery} from '../../graphql/generated/graphql';
import {MachineCardGroup} from './MachineCardGroup';
import {getGroupedMachines} from './util';
import {MachineGrouping} from './MachineGroupSelector';
import {UnconnectedGrouping} from './UnconnectedGrouping';
import _ from 'lodash';

const UNINSTALLED_LABEL = 'uninstalled';

interface MachineGroupingsProps {
    installedMachines: MachineFromQuery[];
    uninstalledMachines: MachineFromQuery[];
    machineStates: MachineStatesQuery['machineStates'];
    machineGrouping: MachineGrouping;
}

const MachineGroupingsInner: React.FC<MachineGroupingsProps> = ({
    installedMachines,
    uninstalledMachines,
    machineStates,
    machineGrouping,
}) => {
    const groupedInstalledMachines = getGroupedMachines(installedMachines, machineGrouping);

    const [isCollapsedMap, setIsCollapsedMap] = useState<Record<string, boolean>>({});

    useEffect(() => {
        setIsCollapsedMap(() => ({}));
    }, [machineGrouping]);

    return (
        <Grid container spacing={4}>
            {groupedInstalledMachines.map(([key, values]) => {
                return (
                    <Grid container item xs={12} key={key}>
                        {/* if NONE is selected we don't show any grouping ui */}
                        {machineGrouping !== 'NONE' && (
                            <Grid
                                container
                                item
                                xs={12}
                                onClick={() => {
                                    setIsCollapsedMap((prev) => ({...prev, [key]: !prev[key]}));
                                }}
                            >
                                <Grid item>
                                    <Typography variant="h5" color="textSecondary" paragraph>
                                        {key}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {!isCollapsedMap[key] ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Collapse in={!isCollapsedMap[key]} unmountOnExit={false}>
                                <MachineCardGroup machines={values} machineStates={machineStates} />
                            </Collapse>
                        </Grid>
                    </Grid>
                );
            })}
            <UnconnectedGrouping
                machines={uninstalledMachines}
                onClick={() => {
                    setIsCollapsedMap((prev) => ({...prev, uninstalled: !prev[UNINSTALLED_LABEL]}));
                }}
                isOpen={!isCollapsedMap[UNINSTALLED_LABEL]}
            />
        </Grid>
    );
};

export const MachineGroupings = memo(MachineGroupingsInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
