export const handleMaxValue = (value: number): number => {
    if (value > 1 && value < 1.5) return value;
    if (value >= 1.5) return 1.5;
    if (value < 0) return 1;
    else return 1;
};

export const handleMinValue = (value: number): number => {
    if (value < 0 && value > -1.5) return value;
    if (value <= -1.5) return -1.5;
    if (value > 0) return 0;
    else return 0;
};
