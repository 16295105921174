import React from 'react';
import {Button, Grid, Typography} from '@sym/common-ui/material';
import {StyledFullHeightGrid} from './EmptyState.styled';
import {StyledImg} from '../InfoCard/InfoCard.styled';
import {ImageProps} from '../../shared/types';

interface EmptyStateProps {
    headline: string;
    copy: string;
    image?: ImageProps;
    buttonText?: string;
    onClick?: () => void;
}

export const EmptyState: React.FC<EmptyStateProps> = ({headline, copy, image, buttonText, onClick}) => {
    return (
        <StyledFullHeightGrid container item xs spacing={2} alignContent="center">
            <Grid item xs>
                <StyledImg src={image?.src} alt={image?.alt} width="500" />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" align="center">
                    {headline}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" align="center">
                    {copy}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {buttonText && (
                    <Button onClick={onClick} color="primary" fullWidth>
                        {buttonText}
                    </Button>
                )}
            </Grid>
        </StyledFullHeightGrid>
    );
};
