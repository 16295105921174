import {Grid, Typography} from '@sym/common-ui/material';
import React from 'react';
import {DayOfWeek, TemplateEvent, WeeklyTemplate} from '../../graphql/generated/graphql';
import {StyledBodyItem} from './Schedule.styled';

const weekdays: DayOfWeek[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const eventsFor = (day: DayOfWeek, template: WeeklyTemplate): TemplateEvent[] => {
    return template.events.filter((event) => event.dayOfWeek === day);
};

interface ScheduleProps {
    template: WeeklyTemplate | null;
    firstDayOfSchedule: Date;
}

export const Schedule: React.FC<ScheduleProps> = ({template, firstDayOfSchedule}) => {
    if (!template) {
        return null;
    }

    return (
        <Grid container xs={12} spacing={1}>
            {weekdays.map((day) => {
                const events = eventsFor(day, template);
                return (
                    <Grid key={day} item xs={12} md>
                        {/* TODO don't show on mobile if no events for that day? */}
                        <Typography variant="caption" color="textSecondary" gutterBottom>
                            {day}
                        </Typography>
                        {events.map((event) => {
                            return (
                                <StyledBodyItem
                                    variant="caption"
                                    key={event.start}
                                    gutterBottom
                                >{`${event.start}-${event.end}`}</StyledBodyItem>
                            );
                        })}
                    </Grid>
                );
            })}
        </Grid>
    );
};
