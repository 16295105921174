import React from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid, Typography} from '@sym/common-ui/material';
import {format} from 'date-fns';
import {StyledCard} from '../RelatedMessages.styled';
import {useJobQuery} from '../../../graphql/generated/graphql';
import {getTextColor} from '../util';

// quick fix for performance reasons
const MAX_MESSAGES = 200;

interface MessagesProps {
    assetId: string;
    jobId: string;
    isActiveJob?: boolean;
}

export const Messages: React.FC<MessagesProps> = ({assetId, jobId}) => {
    const {t} = useTranslation();

    const {
        data: messageData,
        error: messageError,
        loading: messageLoading,
    } = useJobQuery({
        variables: {assetId: assetId, jobId: jobId},
        // needed, else we get problems with infinite re-rendering
        // of jobhistoryquery in quicklinks
        fetchPolicy: 'no-cache',
    });

    const messages = messageData?.asset?.job?.messages?.slice(0, MAX_MESSAGES) || [];

    if (messageLoading) {
        return (
            <Grid container justifyContent="center" xs={12}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    if (messageError) {
        return (
            <Grid container item xs={12} spacing={2}>
                <Grid item justifyContent="center" xs={12}>
                    <Typography variant="body2" color="error" align="center" gutterBottom>
                        {t('jobs.ErrorLoadingMessages')}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    if (messages.length === 0) {
        return (
            <Grid container justifyContent="center">
                <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                    {t('jobs.NoRelatedMessages')}
                </Typography>
            </Grid>
        );
    }

    return (
        <>
            {messages.map((message, idx) => {
                return (
                    <Grid container item xs={12} data-testid={`related-messages-card-${idx}`} key={idx}>
                        <StyledCard messageType={message.type ?? undefined}>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="overline"
                                        color={message.type ? getTextColor(message.type) : 'textSecondary'}
                                    >
                                        {message.type}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="overline" color="textSecondary">
                                        {message.timestamp
                                            ? format(new Date(message.timestamp), 'dd.MM.yyyy, H:mm:ss')
                                            : 'n/a'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {message.messageId}: {message.text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </StyledCard>
                    </Grid>
                );
            })}
        </>
    );
};
