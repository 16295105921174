import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Waypoint} from 'react-waypoint';
import qs from 'qs';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {addMilliseconds} from 'date-fns';
import {CircularProgress, Grid} from '@sym/common-ui/material';
import _ from 'lodash';
import {LocalDateTime, nativeJs} from '@js-joda/core';
import {add} from 'date-fns/esm';
import {DataTable} from '@sym/common-ui/components';
import {DataTableColumnDefinition} from '@sym/common-ui/components/organisms/datatable/interfaces/DataTableColumnDefinition';
import {JobHistoryItem, useJobHistoryWithoutMessagesLazyQuery} from '../../graphql/generated/graphql';
import {Routes} from '../../routes';
import {StyledDataTableContainer} from './JobHistory.styled';
import {extractFromJobHistory} from './jobHistoryTableUtil';
import {TableRowType} from './jobHistoryTableUtil';
import {MobileDataTableCard} from '../MobileDataTableCard';

interface JobHistoryProps {
    machineId: string;
}

// we start one day in the future so timezones don't make trouble
// we always get the newest entries
const defaultSearchStartDate = add(new Date(), {days: 1});

const now: string = LocalDateTime.from(nativeJs(defaultSearchStartDate)).toString();

export const JobHistoryTable: React.FC<JobHistoryProps> = ({machineId}) => {
    const {t} = useTranslation();
    const {id: assetId} = useParams<{id: string}>();
    const location = useLocation();
    const history = useHistory();
    const {time} = qs.parse(location.search, {ignoreQueryPrefix: true});

    const queryDate = new Date(time as string);

    const from = time ? LocalDateTime.from(nativeJs(queryDate)).toString() : now;

    const [items, setItems] = useState<JobHistoryItem[]>([]);
    const [jobHistoryFinished, setJobHistoryFinished] = useState(false);

    const [fetchMessages, {data, error, loading, fetchMore}] = useJobHistoryWithoutMessagesLazyQuery({
        // needed so fetchMore sets loading to true
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        setItems([]);
        setJobHistoryFinished(false);
    }, [assetId]);

    useEffect(() => {
        fetchMessages({
            variables: {
                assetId,
                from,
                count: 20,
            },
        });
    }, [fetchMessages, assetId, from]);

    useEffect(() => {
        if (data?.asset?.jobHistory) {
            const completedJobs = data.asset.jobHistory.filter((job) => job.endTimestamp !== null);
            setItems((prev) => [...prev, ...completedJobs]);
        }
    }, [data]);

    const handleRowClick = (rowData: TableRowType) => {
        const jobId = rowData.id;
        history.push(`${Routes.jobDetailLink}/${machineId}/${jobId}/historical`);
    };

    const columns: DataTableColumnDefinition<TableRowType>[] = [
        {
            title: t('jobs.JobName'),
            accessor: 'name',
            width: '25%',
            minWidth: 150,
            renderer: (cellProps: any) => {
                const value = cellProps.cell.value;
                const rowIndex = cellProps.row.index;
                const uniqueRows = _.uniqBy(items, 'id');

                if (rowIndex === uniqueRows.length - 5) {
                    return (
                        <Fragment>
                            <Waypoint
                                onEnter={async ({currentPosition}) => {
                                    if (jobHistoryFinished) {
                                        return;
                                    }

                                    let count = 20;

                                    if (currentPosition === 'inside' && !loading && !error) {
                                        let lastStart: Date | null = null;

                                        // since startTimestamp can be null we get the
                                        // latest non-null timestamp from our items
                                        for (let idx = items.length - 1; idx >= -1; idx--) {
                                            const candidate = items[idx].startTimestamp;
                                            if (candidate) {
                                                lastStart = new Date(candidate);
                                                break;
                                            }
                                        }

                                        // if no item has a startTimestamp we
                                        // fetch again from the start but more this time
                                        // (duplicates are filtered out)
                                        if (!lastStart) {
                                            lastStart = defaultSearchStartDate;
                                            count = count + 20;
                                        }

                                        const from = addMilliseconds(lastStart, 1);

                                        // TODO debounce
                                        const newData = await fetchMore?.({
                                            variables: {
                                                assetId: assetId,
                                                from: LocalDateTime.from(nativeJs(from)).toString(),
                                                count,
                                            },
                                        });
                                        const jobHistory = newData?.data.asset?.jobHistory;

                                        if (jobHistory && jobHistory.length === 0) {
                                            setJobHistoryFinished(true);
                                        }
                                        const newItems = jobHistory || [];

                                        setItems((prev) => [...prev, ...newItems]);
                                    }
                                }}
                            />
                            {value}
                        </Fragment>
                    );
                } else {
                    return <Fragment>{value}</Fragment>;
                }
            },
        },
        {
            title: t('jobs.StartTime'),
            accessor: 'start',
            width: '25%',
            minWidth: 150,
        },
        {
            title: t('jobs.PlannedRuntime'),
            accessor: 'plannedTime',
            width: '25%',
            minWidth: 150,
        },
        {
            title: t('jobs.Duration'),
            accessor: 'duration',
            width: '25%',
            minWidth: 150,
        },
        {title: '', accessor: 'id', width: '0%', renderer: () => null},
        {title: '', accessor: 'rowIndex', width: '0%', renderer: () => null},
        {title: '', accessor: 'numItems', width: '0%', renderer: () => null},
    ];

    const uniqueItems = _.uniqBy(items, 'id');
    const tableItems = uniqueItems.map((item, idx) => extractFromJobHistory(item, idx, uniqueItems.length));

    return (
        // maxHeight here is needed so infinite scroll doesn't scroll infinitely
        <StyledDataTableContainer data-testid="job-history">
            <DataTable
                columns={columns}
                data={tableItems}
                onRowClick={handleRowClick}
                error={error && t('jobs.ErrorLoadingJobs')}
                loading={loading && !data}
                labelEmptyData={t('jobs.NoJobs')}
                // Hide filter button while we don't have filters
                labelFilters=""
                mobileRender={(props) => {
                    const [
                        {value: name},
                        {value: start},
                        {value: plannedTime},
                        {value: duration},
                        {value: id},
                        {value: rowIndex},
                        {value: numItems},
                    ] = props.cells;

                    const cardComponent = (
                        <MobileDataTableCard
                            items={[
                                {label: t('jobs.JobName'), value: name},
                                {label: t('jobs.StartTime'), value: start},
                                {label: t('jobs.PlannedRuntime'), value: plannedTime},
                                {label: t('jobs.Duration'), value: duration},
                            ]}
                            onClick={() => handleRowClick({id, name, start, plannedTime, duration, rowIndex, numItems})}
                            dataTestId="mobile-data-table-card-job-history"
                        />
                    );

                    if (rowIndex === numItems - 5) {
                        return (
                            <Fragment>
                                <Waypoint
                                    onEnter={async ({currentPosition}) => {
                                        if (jobHistoryFinished) {
                                            return;
                                        }

                                        let count = 20;

                                        if (currentPosition === 'inside' && !loading && !error) {
                                            let lastStart: Date | null = null;

                                            // since startTimestamp can be null we get the
                                            // latest non-null timestamp from our items
                                            for (let idx = items.length - 1; idx >= -1; idx--) {
                                                const candidate = items[idx].startTimestamp;
                                                if (candidate) {
                                                    lastStart = new Date(candidate);
                                                    break;
                                                }
                                            }

                                            // if no item has a startTimestamp we
                                            // fetch again from the start but more this time
                                            // (duplicates are filtered out)
                                            if (!lastStart) {
                                                lastStart = defaultSearchStartDate;
                                                count = count + 20;
                                            }

                                            const from = addMilliseconds(lastStart, 1);

                                            // TODO debounce
                                            const newData = await fetchMore?.({
                                                variables: {
                                                    assetId: assetId,
                                                    from: LocalDateTime.from(nativeJs(from)).toString(),
                                                    count,
                                                },
                                            });
                                            const jobHistory = newData?.data.asset?.jobHistory;

                                            if (jobHistory && jobHistory.length === 0) {
                                                setJobHistoryFinished(true);
                                            }
                                            const newItems = jobHistory || [];

                                            setItems((prev) => [...prev, ...newItems]);
                                        }
                                    }}
                                />
                                {cardComponent}
                            </Fragment>
                        );
                    } else {
                        return <Fragment>{cardComponent}</Fragment>;
                    }
                }}
            />

            {/* `data` because we want to only show this spinner on fetchMore loading */}
            {/* so we don't show two spinners under each other */}
            {data && loading && (
                <Grid container justifyContent="center" xs>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
        </StyledDataTableContainer>
    );
};
