import {LocalDate, nativeJs} from '@js-joda/core';
import {add} from 'date-fns';
import {endOfWeekWithLocale} from '../../util/i18n';
import {TargetType} from './ApplyTemplateWizard';

export const calculateEndDate = ({
    targetType,
    numWeeks,
    startDate,
    endDate,
}: {
    targetType: TargetType;
    numWeeks: number;
    startDate: Date;
    endDate: Date;
}): LocalDate => {
    if (targetType === 'repetitions') {
        const endOfFirstWeek = endOfWeekWithLocale(startDate);
        // numWeeks -1 because the first week is done by endOfWeek()
        const endOfPeriod = add(endOfFirstWeek, {weeks: numWeeks - 1});

        return LocalDate.from(nativeJs(endOfPeriod));
    } else {
        const rawEnd = endOfWeekWithLocale(endDate);

        return LocalDate.from(nativeJs(rawEnd));
    }
};
