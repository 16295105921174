import {graphql} from 'msw';

import job from './fixtures/job.json';
import jobHistory from './fixtures/jobHistory.json';
import jobHistoryWithoutMessages from './fixtures/jobHistoryWithoutMessages.json';
import jobsTimeline from './fixtures/jobsTimeline.json';
import {uniqueId} from 'lodash';

export const jobHandler = graphql.query('Job', (req, res, context) => {
    return res(
        context.data({
            asset: job,
        })
    );
});

export const jobHistoryHandler = graphql.query('JobHistory', (req, res, context) => {
    return res(
        context.data({
            asset: jobHistory,
        })
    );
});

export const jobHistoryWithoutMessagesHandler = graphql.query('JobHistoryWithoutMessages', (req, res, context) => {
    const {count} = req.body?.variables;
    const messages = [...jobHistoryWithoutMessages.slice(0, count)];
    // because we filter in the app for unique messages we need to give them unique ids
    const newMessages = messages.map((historyItem, idx) => {
        return {...historyItem, id: uniqueId()};
    });
    return res(
        context.data({
            asset: {
                id: 'AAAAAAAA-AAAA-AAAA-AAAA-111111111111',
                displayName: 'Genf 2 (LASER P 400)',
                jobHistory: newMessages,
            },
        })
    );
});

export const jobsTimelineHandler = graphql.query('JobHistoryByRange', (req, res, context) => {
    return res(
        context.data({
            asset: jobsTimeline,
        })
    );
});
