import React from 'react';
import {Button, Grid, Modal, TextField, Typography} from '@sym/common-ui/material';
import {StyledCancelButton, StyledCard, StyledTimePicker} from './AddDownTime.styled';
import {useTranslation} from 'react-i18next';
import {useForm, SubmitHandler, Controller} from 'react-hook-form';
import {addMinutes, subMinutes} from 'date-fns';
import {TCalendarEvent, DowntimeValues} from '../../../shared/types';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {ErrorMessage} from '@hookform/error-message';

interface AddDownTimeProps {
    isOpen: boolean;
    onClose: () => void;
    event: TCalendarEvent;
    onSubmit: SubmitHandler<DowntimeValues>;
}

const schema = yup.object().shape({
    start: yup.date().required().max(yup.ref('end')),
    end: yup.date().required(),
});

export const AddDownTime: React.FC<AddDownTimeProps> = ({isOpen, onClose, event, onSubmit}) => {
    const {t} = useTranslation();
    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<DowntimeValues>({resolver: yupResolver(schema)});

    const selectedEvent = event;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby={t('productionPlanner.AddDownTimeToEvent')}
            aria-describedby={t('productionPlanner.AddDownTimeToEvent')}
        >
            <StyledCard>
                <form
                    onSubmit={handleSubmit((e) => {
                        onSubmit(e);
                        onClose();
                    })}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t('productionPlanner.AddDownTimeToEvent')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="start"
                                control={control}
                                defaultValue={addMinutes(selectedEvent.start, 30)}
                                shouldUnregister
                                rules={{required: t('productionPlanner.downTimeStartRequired').toString()}}
                                render={({field: {ref, onChange, value}, fieldState: {invalid}}) => (
                                    <StyledTimePicker
                                        inputRef={ref}
                                        renderInput={(params) => <TextField {...params} />}
                                        ampm={false}
                                        minutesStep={30}
                                        label={t('productionPlanner.start')}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="end"
                                control={control}
                                rules={{required: t('productionPlanner.downTimeEndRequired').toString()}}
                                defaultValue={subMinutes(selectedEvent.end, 30)}
                                shouldUnregister
                                render={({field: {ref, onChange, value}}) => (
                                    <StyledTimePicker
                                        inputRef={ref}
                                        renderInput={(params) => <TextField {...params} />}
                                        ampm={false}
                                        minutesStep={30}
                                        label={t('productionPlanner.end')}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Grid>
                        {errors.start && (
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="start"
                                    render={({}) => (
                                        <Typography variant="caption" color="error">
                                            {t('productionPlanner.startNotBeforeEnd')}
                                        </Typography>
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid container item xs={12} justifyContent="flex-end">
                            <Grid item>
                                <StyledCancelButton variant="text" onClick={onClose}>
                                    {t('Cancel')}
                                </StyledCancelButton>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary">
                                    {t('Apply')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </StyledCard>
        </Modal>
    );
};
