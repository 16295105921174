import {DateRange} from '../../../shared/types';

export const parseStartAndEndDate = (startDate: unknown, endDate: unknown): DateRange | null => {
    if (typeof startDate !== 'string' || typeof endDate !== 'string') {
        return null;
    }

    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    // parsed to invalid date
    if (isNaN(parsedStartDate.getTime()) || isNaN(parsedEndDate.getTime())) {
        return null;
    }

    return {startDate: parsedStartDate, endDate: parsedEndDate};
};
