import gql from 'graphql-tag';

export const MACHINE_STATE_SUBSCRIPTION = gql`
    subscription MachineStatesSubscription($machineIds: [ID!]!) {
        machineStates(machineIds: $machineIds) {
            id
            tenantId
            machineId
            activeJob {
                id
                estimatedRuntime
                estimatedRemainingRuntime
                estimatedEndTimestamp
                mainProgramName
                mainProgramFile
                plannedRuntime
                startTimestamp
            }
            lastSourceTimestampUTC
            lastDocumentUpdateUTC
            lastSourceTimestampOffset
            unitStatusOrderRelation
            unitStatusMode
            unitStatusState
            userMessage {
                text
                timestamp
                id
                type
            }
            unitMessageEN {
                text
                timestamp
                id
                type
            }
            unitMessage {
                text
                timestamp
                id
                type
            }
        }
    }
`;
