import {CircularProgress, Grid} from '@sym/common-ui/material';

export interface KpiOverviewProgressProps {
    testId?: string;
}

export const KpiOverviewProgress = ({testId}: KpiOverviewProgressProps): JSX.Element => (
    <Grid container justifyContent="center" xs>
        <Grid item>
            <CircularProgress data-testid={testId} />
        </Grid>
    </Grid>
);
