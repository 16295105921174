import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@sym/common-ui/material';
import {WeeklyTemplate} from '../../../graphql/generated/graphql';
import {Schedule} from '../Schedule';

interface Step1Props {
    templates: WeeklyTemplate[];
    selectedTemplate: WeeklyTemplate | null;
    setSelectedTemplate: React.Dispatch<React.SetStateAction<WeeklyTemplate | null>>;
    firstDayOfSchedule: Date;
}

export const Step1: FC<Step1Props> = ({templates, selectedTemplate, setSelectedTemplate, firstDayOfSchedule}) => {
    const {t} = useTranslation();

    const handleChange = (e: SelectChangeEvent<string>) => {
        const templateId = e.target.value;
        const template = templates.find((template) => template.id === templateId);

        if (template) {
            setSelectedTemplate(template);
        }
    };
    return (
        <Grid container spacing={4}>
            <Grid item>
                <Typography variant="h6" gutterBottom>
                    {t('productionPlanner.applyTemplate.step1.headline')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('productionPlanner.applyTemplate.step1.subHeadline')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel shrink={true} htmlFor="age-native-helper" id={'selectTemplate'}>
                    {t('productionPlanner.applyTemplate.step1.selectLabelText')}
                </InputLabel>
                <Select
                    labelId={'selectTemplate'}
                    value={selectedTemplate?.id || ''}
                    onChange={handleChange}
                    data-testid="select-template"
                    fullWidth
                >
                    {templates.map((template) => {
                        return (
                            <MenuItem key={template.id} value={template.id}>
                                {template.name}
                            </MenuItem>
                        );
                    })}
                </Select>
                <FormHelperText>{t('productionPlanner.applyTemplate.step1.chooseTemplateLabel')}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary" paragraph>
                    {t('productionPlanner.applyTemplate.step1.choosedTemplate')}
                </Typography>

                {selectedTemplate ? (
                    <Schedule template={selectedTemplate} firstDayOfSchedule={firstDayOfSchedule} />
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        {t('productionPlanner.applyTemplate.step1.noTemplateSelectedInfo')}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
