import {FormHelperText, Grid, Modal, Typography} from '@sym/common-ui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {ConfirmDialog, CrudButtons, TextField} from '@sym/common-ui/components';
import {Autocomplete} from '@sym/common-ui/material';
import {
    TemplateEventInput,
    useCreateWeeklyTemplateMutation,
    useDeleteWeeklyTemplateMutation,
    useUpdateWeeklyTemplateMutation,
    useWeeklyTemplatesQuery,
    WeeklyTemplate,
} from '../../graphql/generated/graphql';
import {StyledPaper} from './SaveTemplateModal.styled';

import {TCalendarEvent} from '../../shared/types';
import {mapCalendarEventToTemplateEventInput} from './util';

interface SaveTemplateModalProps {
    open: boolean;
    onClose(): void;
    events: TCalendarEvent[];
}

export const SaveTemplateModal: React.FC<SaveTemplateModalProps> = ({open, onClose, events}) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [text, setText] = useState('');
    const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const {data, loading} = useWeeklyTemplatesQuery();

    const [createTemplate] = useCreateWeeklyTemplateMutation({
        refetchQueries: ['WeeklyTemplates'],
    });
    const [updateTemplate] = useUpdateWeeklyTemplateMutation({
        refetchQueries: ['WeeklyTemplates'],
    });
    const [deleteTemplate] = useDeleteWeeklyTemplateMutation({
        refetchQueries: ['WeeklyTemplates'],
    });

    const templates = data?.weeklyTemplates || [];

    const handleClose = () => {
        setText('');
        onClose();
    };

    const template = data?.weeklyTemplates.find((template) => template.name === text);
    const isTemplateNameTaken: boolean = Boolean(data?.weeklyTemplates.find((template) => template.name === text));

    const handleSave = async () => {
        if (template) {
            setShowUpdateConfirmationModal(true);
        } else {
            try {
                const eventsInput: TemplateEventInput[] = events.map((event) =>
                    mapCalendarEventToTemplateEventInput(event)
                );

                await createTemplate({
                    variables: {
                        data: {name: text, events: eventsInput},
                    },
                });
                enqueueSnackbar(t('productionPlanner.saveTemplate.createdSnack'), {variant: 'success'});
            } catch (error) {
                console.log(error);
                enqueueSnackbar(t('productionPlanner.saveTemplate.error'), {variant: 'error'});
            } finally {
                handleClose();
            }
        }
    };

    const handleUpdate = async () => {
        if (!template) {
            return;
        }

        try {
            const eventsInput: TemplateEventInput[] = events.map((event) =>
                mapCalendarEventToTemplateEventInput(event)
            );
            await updateTemplate({
                variables: {
                    data: {name: text, events: eventsInput},
                    templateId: template.id,
                },
            });
            enqueueSnackbar(t('productionPlanner.saveTemplate.updatedSnack'), {variant: 'success'});
        } catch (error) {
            console.log(error);
            enqueueSnackbar(t('productionPlanner.saveTemplate.error'), {variant: 'error'});
        } finally {
            setShowUpdateConfirmationModal(false);
            handleClose();
        }
    };

    const handleDelete = async () => {
        if (!template) {
            return;
        }

        try {
            await deleteTemplate({
                variables: {
                    templateId: template.id,
                },
            });
            enqueueSnackbar(t('productionPlanner.saveTemplate.deleteSnack'), {variant: 'success'});
        } catch (error) {
            console.log(error);
            enqueueSnackbar(t('productionPlanner.saveTemplate.error'), {variant: 'error'});
        } finally {
            setShowDeleteConfirmationModal(false);
            handleClose();
        }
    };

    return (
        <Modal disableEnforceFocus open={open} onClose={handleClose} data-testid="save-template-modal">
            <StyledPaper elevation={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {t('productionPlanner.saveTemplate.headline')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph>
                            {t('productionPlanner.saveTemplate.subHeadline')}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Autocomplete<WeeklyTemplate, false, false, true>
                            options={templates}
                            freeSolo
                            getOptionLabel={(option) => option.name}
                            onInputChange={(_, value) => setText(value.trim())}
                            renderInput={(params) => (
                                <TextField {...params} label={t('productionPlanner.saveTemplate.label')} />
                            )}
                        />
                        <FormHelperText>
                            {isTemplateNameTaken ? (
                                <Typography variant="caption" color="primary" paragraph>
                                    {t('productionPlanner.saveTemplate.explainOverwrite')}
                                </Typography>
                            ) : (
                                <Typography variant="caption" color="textSecondary" paragraph>
                                    {t('productionPlanner.saveTemplate.explainer')}
                                </Typography>
                            )}
                        </FormHelperText>
                    </Grid>
                    <Grid container alignContent="space-between" xs={12}>
                        <CrudButtons
                            isConfirmDisabled={text === '' || loading}
                            canDelete={Boolean(data?.weeklyTemplates.find((template) => template.name === text))}
                            labelConfirm={
                                isTemplateNameTaken
                                    ? t('productionPlanner.saveTemplate.overwrite')
                                    : t('productionPlanner.saveTemplate.save')
                            }
                            labelCancel={t('productionPlanner.saveTemplate.cancel')}
                            labelDelete={t('productionPlanner.saveTemplate.delete')}
                            onConfirm={handleSave}
                            onClose={handleClose}
                            onDelete={() => setShowDeleteConfirmationModal(true)}
                        />
                    </Grid>
                </Grid>
                <ConfirmDialog
                    open={showUpdateConfirmationModal}
                    onClose={() => {
                        setShowUpdateConfirmationModal(false);
                    }}
                    onConfirm={handleUpdate}
                    text={t('productionPlanner.saveTemplate.confirmOverwrite')}
                    labelCancel={t('productionPlanner.saveTemplate.cancelOverwriteButton')}
                    labelConfirm={t('productionPlanner.saveTemplate.confirmOverwriteButton')}
                />
                <ConfirmDialog
                    open={showDeleteConfirmationModal}
                    onClose={() => {
                        setShowDeleteConfirmationModal(false);
                    }}
                    onConfirm={handleDelete}
                    text={t('productionPlanner.saveTemplate.confirmDelete')}
                    labelCancel={t('productionPlanner.saveTemplate.cancelOverwriteButton')}
                    labelConfirm={t('productionPlanner.saveTemplate.confirmDeleteButton')}
                />
            </StyledPaper>
        </Modal>
    );
};
