import {Card, styled, css, Chip} from '@sym/common-ui/material';
import {theme} from '../../theme/GfmsTheme';

const getMachineStateColor = (state: string | undefined) => {
    switch (state) {
        case 'Executing':
            return theme.palette.success.main;
        case 'Waiting':
            return theme.palette.secondary.light;
        case 'OutOfService':
            return theme.palette.error.main;
        default:
            return theme.palette.background.paper;
    }
};

export const StyledCustomGrid = styled('div')`
    ${() => css`
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 1fr;
        gap: 0px 16px;
        cursor: pointer;
    `}
`;

export const GridAreaImage = styled('div')`
    ${({theme: {spacing}}) => css`
        grid-area: 1 / 1 / 2 / 3;
        margin-top: ${spacing(2)};
        margin-right: -${spacing(2)};
        z-index: 1000;
    `}
`;

export const GridAreaCard = styled('div')`
    ${() => css`
        grid-area: 1 / 2 / 2 / 11;
    `}
`;

export const StyledCard = styled(Card)<{isWarning: boolean}>`
    ${({theme: {palette, spacing}, isWarning}) => css`
        padding: ${spacing(2)} ${spacing(2)};
        padding-left: calc(10% + ${spacing(3)});
        border-color: transparent;
        ${isWarning && `box-shadow: 0 0 0 2px ${palette.error.main}`};
    `}
`;

export const StyledContainer = styled('div')`
    ${() => css`
        position: relative;
    `}
`;

export const StyledImageWrapper = styled('div')`
    ${() => css`
        position: relative;
        aspect-ratio: 1 / 1;
        overflow: hidden;
        border-radius: 50%;
        object-fit: fill;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    `}
`;

export const StyledChip = styled(Chip)<{machineState: string}>`
    ${({machineState}) => css`
        position: absolute;
        transform-origin: 50% 50%;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${getMachineStateColor(machineState)};
    `}
`;
