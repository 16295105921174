import React from 'react';
import {StyledModuleContainer, StyledDateRangePickerWrapper} from './Header.styled';
import {DateRangePicker} from '@sym/common-ui/components';
import {DateRange} from '../../shared/types';

import {useTranslation} from 'react-i18next';
import {sub, startOfToday, startOfTomorrow} from 'date-fns';
import {MachineSelector} from '../MachineSelector';
import {MachineFromQuery} from '../../App';

interface HeaderProps {
    machines: MachineFromQuery[];
    onDateRangeChange(dateRange: DateRange): void;
    onSelectedMachinesChange(machineIds: string[]): void;
    selectedDateRange: DateRange;
    selectedMachineIds: string[];
    tenantUserId?: string;
}

export const Header: React.FC<HeaderProps> = ({
    machines,
    onDateRangeChange,
    onSelectedMachinesChange,
    selectedDateRange,
    selectedMachineIds,
    tenantUserId,
}) => {
    const {t} = useTranslation();

    const sortedMachines = [...machines].sort((a, b) => (a.displayName || '--').localeCompare(b.displayName || '--'));

    const definedRanges = [
        {
            label: t('Header.definedRanges.Today'),
            startDate: startOfToday(),
            endDate: startOfTomorrow(),
        },
        {
            label: t('Header.definedRanges.Last7Days'),
            startDate: sub(startOfToday(), {days: 7}),
            endDate: startOfTomorrow(),
        },
        {
            label: t('Header.definedRanges.Last30Days'),
            startDate: sub(startOfToday(), {days: 30}),
            endDate: startOfTomorrow(),
        },
        {
            label: t('Header.definedRanges.Last90Days'),
            startDate: sub(startOfToday(), {days: 90}),
            endDate: startOfTomorrow(),
        },
    ];

    return (
        <StyledModuleContainer>
            <StyledDateRangePickerWrapper>
                <DateRangePicker
                    dateRange={selectedDateRange}
                    definedRanges={definedRanges}
                    onChangeDateRange={onDateRangeChange}
                    maxDate={startOfTomorrow()}
                />
            </StyledDateRangePickerWrapper>
            <MachineSelector
                machines={sortedMachines}
                onSelectedMachinesChange={onSelectedMachinesChange}
                selectedMachineIds={selectedMachineIds}
                tenantUserId={tenantUserId}
            />
        </StyledModuleContainer>
    );
};
