import {css, styled} from '@sym/common-ui/material';
import {Typography} from '@sym/common-ui/material';

export const StyledBodyItem = styled(Typography)`
    ${({theme: {spacing, palette}}) => css`
        display: block;
        text-align: center;
        border-radius: ${spacing(0.5)};
        white-space: nowrap;
        padding: ${spacing(0.25)}px ${spacing(0.5)};
        background-color: ${palette.success.main};
    `}
`;
