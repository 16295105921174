import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@sym/common-ui/material';
import {format} from 'date-fns';
import {startCase} from 'lodash';

import {MachineState, UnitStatusState} from '../../graphql/generated/graphql';
import {formatTime} from '../../util/dataFormatter';
import {StyledFiberManualRecordIcon, StyledStatusContainer} from './ActiveJobInfo.styled';
import {useHistory} from 'react-router-dom';
import {Routes} from '../../routes';
import {DataTable} from '@sym/common-ui/components';
import {DataTableColumnDefinition} from '@sym/common-ui/components/organisms/datatable/interfaces/DataTableColumnDefinition';
import {MobileDataTableCard} from '../MobileDataTableCard';

type TableRowType = {
    name: string;
    start: string;
    remaining: string;
    status: string;
};

const getStatusText = (state: UnitStatusState) => {
    switch (state) {
        case 'Executing':
        case 'OutOfService':
        case 'Waiting':
            return startCase(state);
        case 'NotApplicable':
        case 'Undefined':
        case 'Unknown':
            return 'Unknown';
        default:
            return 'Unknown';
    }
};

export const getStatus = (machineState: MachineState) => {
    if (!machineState.unitStatusState) {
        return '--';
    }
    return (
        <StyledStatusContainer>
            <StyledFiberManualRecordIcon fontSize="small" state={machineState.unitStatusState} />
            <Typography>{getStatusText(machineState.unitStatusState)}</Typography>
        </StyledStatusContainer>
    );
};

interface ActiveJobInfoProps {
    machineState: MachineState;
}

export const ActiveJobInfo: React.FC<ActiveJobInfoProps> = ({machineState}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const mainProgramName = machineState.activeJob?.mainProgramName || '--';
    const startTimestamp = machineState.activeJob?.startTimestamp
        ? format(new Date(machineState.activeJob?.startTimestamp), 'dd.MM.yyyy HH:mm:ss')
        : '--';
    const estimatedRemainingRuntime = machineState.activeJob?.estimatedRemainingRuntime
        ? formatTime(machineState.activeJob?.estimatedRemainingRuntime)
        : '--';
    const unitStatusState = getStatus(machineState);

    const handleRowClick = () => {
        const {machineId, activeJob} = machineState;
        if (machineId && activeJob?.startTimestamp) {
            history.push(`${Routes.jobDetailLink}/${machineState.machineId}/${machineState?.activeJob?.id}/active`);
        }
    };

    const columns: DataTableColumnDefinition<TableRowType>[] = [
        {
            title: t('jobs.JobName'),
            accessor: 'name',
            width: '25%',
            minWidth: 150,
        },
        {
            title: t('jobs.StartTime'),
            accessor: 'start',
            width: '25%',
            minWidth: 150,
        },
        {
            title: t('jobs.EstimatedRemaining'),
            accessor: 'remaining',
            width: '25%',
            minWidth: 150,
        },
        {
            title: t('jobs.Status'),
            accessor: 'status',
            width: '25%',
            minWidth: 150,
        },
        // align indentation with job history table
        {title: '', accessor: 'id', width: '0%', renderer: () => null},
        {title: '', accessor: 'id2', width: '0%', renderer: () => null},
        {title: '', accessor: 'id3', width: '0%', renderer: () => null},
    ];

    return (
        <div data-testid="active-job-info">
            <DataTable
                columns={columns}
                data={[
                    {
                        name: mainProgramName,
                        start: startTimestamp,
                        remaining: estimatedRemainingRuntime,
                        status: unitStatusState,
                    },
                ]}
                onRowClick={handleRowClick}
                labelEmptyData={t('jobs.NoJobs')}
                // Hide filter button while we don't have filters
                labelFilters=""
                mobileRender={(props) => {
                    const [{value: name}, {value: startTime}, {value: remaining}, {value: status}] = props.cells;

                    return (
                        <MobileDataTableCard
                            items={[
                                {label: t('jobs.JobName'), value: name},
                                {label: t('jobs.StartTime'), value: startTime},
                                {label: t('jobs.EstimatedRemaining'), value: remaining},
                                {label: t('jobs.Status'), value: status},
                            ]}
                            onClick={handleRowClick}
                            dataTestId="mobile-data-table-card-active-job"
                        />
                    );
                }}
            />
        </div>
    );
};
