import {css, styled} from '@sym/common-ui/material';
import {Typography} from '@sym/common-ui/material';

export const StyledInfobox = styled('div')`
    ${({theme}) => css`
        display: flex;
        flex-direction: column;
    `}
`;

export const StyledLabelWrapper = styled('div')`
    display: flex;
    align-items: center;
`;

export const StyledTypography = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
