import React from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid} from '@sym/common-ui/material';
import {useJobHistoryByRangeQuery} from '../../../graphql/generated/graphql';
import {getDatesWithinDateRange, JobsTimeline} from '../../../components/JobsTimeline';
import {DateRangePicker} from '@sym/common-ui/components';
import {DateRange} from 'materialui-daterange-picker';
import {parseDateToLocalDateTime, removeTimeZone} from '../../../util';
import {differenceInCalendarDays, startOfToday, startOfTomorrow, sub, subDays} from 'date-fns';
import {useHistory, useLocation, useParams, useRouteMatch} from 'react-router';
import {useSnackbar} from 'notistack';
import qs from 'qs';
import {parseStartAndEndDate} from './jobsTimeLineUtil';

// the amount of days shown initially as range in the timeline
const DEFAULT_TIMELINE_RANGE = 3;

const defaultDateRange: DateRange = {
    startDate: removeTimeZone(sub(startOfToday(), {days: 3})),
    endDate: removeTimeZone(startOfTomorrow()),
};

export const JobsTimelinePage: React.FC = () => {
    const {t} = useTranslation();
    const {id: assetId} = useParams<{id: string}>();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const history = useHistory();
    const {startDate, endDate} = qs.parse(location.search, {ignoreQueryPrefix: true});
    let {url} = useRouteMatch();

    const selectedDateRange = parseStartAndEndDate(startDate, endDate);

    if (selectedDateRange === null) {
        const newUrl = `${url}?${qs.stringify({...defaultDateRange})}`;
        history.replace(newUrl);
    }

    const definedRanges = [
        {
            label: t('Header.definedRanges.Last7Days'),
            startDate: subDays(startOfToday(), 6),
            endDate: startOfTomorrow(),
        },
        {
            label: t('Header.definedRanges.Last30Days'),
            startDate: subDays(startOfToday(), 29),
            endDate: startOfTomorrow(),
        },
    ];

    const {
        loading: loadingJobsTimeline,
        error: errorJobsTimeline,
        data: dataJobsTimeline,
    } = useJobHistoryByRangeQuery({
        variables: {
            assetId,
            from: selectedDateRange?.startDate
                ? parseDateToLocalDateTime(selectedDateRange?.startDate)
                : parseDateToLocalDateTime(removeTimeZone(sub(startOfToday(), {days: DEFAULT_TIMELINE_RANGE}))),
            to: selectedDateRange?.endDate
                ? parseDateToLocalDateTime(selectedDateRange?.endDate)
                : parseDateToLocalDateTime(removeTimeZone(startOfTomorrow())),
        },
    });

    const handleDateRangeChange = (dateRange: DateRange) => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return;
        }

        if (differenceInCalendarDays(dateRange.endDate, dateRange.startDate) >= 31) {
            const from = removeTimeZone(subDays(dateRange.endDate, 30));
            const to = removeTimeZone(dateRange.endDate);

            enqueueSnackbar(t('jobTimeline.dateRangeMaximum'), {variant: 'info'});

            history.push(`${url}?${qs.stringify({startDate: from, endDate: to})}`);
        } else {
            const from = removeTimeZone(dateRange.startDate);
            const to = removeTimeZone(dateRange.endDate);

            history.push(`${url}?${qs.stringify({startDate: from, endDate: to})}`);
        }
    };

    const datesWithinDateRange = getDatesWithinDateRange(selectedDateRange?.startDate, selectedDateRange?.endDate);

    if (loadingJobsTimeline)
        return (
            <Grid container justifyContent="center" xs>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );

    if (errorJobsTimeline) {
        console.log({errorJobsTimeline});
    }

    return (
        <Grid container>
            <Grid container item>
                <Grid container item xs={12} justifyContent="flex-end">
                    <DateRangePicker
                        dateRange={selectedDateRange || defaultDateRange}
                        definedRanges={definedRanges}
                        onChangeDateRange={handleDateRangeChange}
                        maxDate={startOfTomorrow()}
                    />
                    {errorJobsTimeline ? (
                        <Grid item container xs={12} spacing={2} justifyContent="center">
                            {t('Error')}
                        </Grid>
                    ) : (
                        dataJobsTimeline?.asset?.jobHistoryByRange && (
                            <JobsTimeline
                                assetId={assetId}
                                datesWithinDateRange={datesWithinDateRange}
                                jobs={dataJobsTimeline?.asset?.jobHistoryByRange}
                                unitModes={dataJobsTimeline?.asset?.unitModes}
                            />
                        )
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
