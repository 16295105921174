import React, {FunctionComponent} from 'react';
import {PortalHelper} from '@sym/portal-sdk';

export interface AuthProviderProps {
    forceLogin?: boolean;
    provider: unknown;
}

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({children}) => {
    return <>{children}</>;
};

export const authProvider = {};
export const getAccessToken = async (): Promise<string> => PortalHelper.getAuthToken();
