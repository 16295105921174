import React from 'react';
import {Typography} from '@sym/common-ui/material';

interface InfoboxProps {
    label: string;
    value: React.ReactNode;
    title?: string;
}

export const Infobox: React.FC<InfoboxProps> = ({label, value, title}) => {
    return (
        <div>
            <Typography variant="body2" color="textSecondary">
                {label}
            </Typography>
            <Typography variant="body1" title={title}>
                {value ? value : '--'}
            </Typography>
        </div>
    );
};
