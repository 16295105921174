import React, {useLayoutEffect, useRef} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {am4themes_myTheme} from '../../../theme/GfmsTheme';
import {useTheme} from '@sym/common-ui/material';
import {getChartConfig} from './config';
import {useTranslation} from 'react-i18next';
import {JobsTimelineItem} from '../../../shared/types';
import {getChartLocaleConfig} from '../../../shared';
import {useHistory} from 'react-router';
import {Routes} from '../../../routes';
import {useSnackbar} from 'notistack';
import {ErrorMessage} from '@hookform/error-message';

export interface TimelineChartProps {
    assetId: string;
    chartId: string;
    data: JobsTimelineItem[];
    dayEnd: string;
    dayStart: string;
    endLabel: string;
    startLabel: string;
    className?: string;
}

// Themes begin
//  InterfaceColors are set within the theme
am4core.useTheme(am4themes_myTheme);
// Themes end

export const JobsTimelineChart: React.FC<TimelineChartProps> = ({
    assetId,
    chartId,
    className,
    data,
    dayEnd,
    dayStart,
    endLabel,
    startLabel,
}: TimelineChartProps) => {
    // FIX ME: Type any is not what we want here.
    const timelineChart = useRef<any | null>(null);
    const theme = useTheme();
    const {t} = useTranslation();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    useLayoutEffect(() => {
        let chart = am4core.create(`${chartId}`, am4charts.XYChart);
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        let start = startLabel;
        let end = endLabel;

        getChartConfig({
            chart,
            dateAxis,
            categoryAxis,
            theme,
        });

        getChartLocaleConfig({chart: chart});

        chart.data = data;

        const indicator = chart.tooltipContainer!.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        let indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = t('jobs.jobsTimeline.loading');
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;

        chart.events.on('ready', () => {
            indicator.hide(0);
        });

        // ========================================================
        // Creates Series
        // ========================================================

        let series = new am4charts.ColumnSeries();
        series.dataFields.categoryY = 'value';
        series.dataFields.dateX = 'date';

        series.strokeWidth = 1.5;
        series.columns.template.column.stroke = am4core.color(theme.palette.background.paper);

        chart.series.push(series);

        dateAxis.min = new Date(dayStart).getTime();
        dateAxis.max = new Date(dayEnd).getTime();

        series.columns.template.width = am4core.percent(100);
        series.columns.template.tooltipText = `{label} \n ${start}: {openDateX.formatDate("HH:mm:ss")} \n ${end}: {dateX.formatDate("HH:mm:ss")}`;

        series.dataFields.openDateX = 'fromDate';
        series.dataFields.dateX = 'toDate';
        series.dataFields.categoryY = 'name';
        series.columns.template.propertyFields.fill = 'color'; // get color from data
        series.columns.template.propertyFields.stroke = 'color';
        series.columns.template.strokeOpacity = 1;

        series.columns.template.interactionsEnabled = true;

        // click on a job bar leads to the jobs details page
        series.columns.template.events.on(
            'hit',
            (ev) => {
                // @ts-ignore
                if (ev.target.dataItem?.dataContext.type === 'job') {
                    history.push(
                        // @ts-ignore
                        `${Routes.jobDetailLink}/${assetId}/${ev.target.dataItem.dataContext.id}/historical`
                    );
                }
                // @ts-ignore
                if (ev.target.dataItem?.dataContext.type === 'unitMode') {
                    return;
                }
            },
            this
        );

        series.columns.template.events.on(
            'over',
            (ev) => {
                // @ts-ignore
                if (ev.target.dataItem?.dataContext.type === 'job') {
                    series.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                }
                // @ts-ignore
                if (ev.target.dataItem?.dataContext.type === 'unitMode') {
                    series.cursorOverStyle = am4core.MouseCursorStyle.default;
                }
            },
            this
        );

        timelineChart.current = chart;

        return () => {
            chart.dispose();
        };
    }, [assetId, history, chartId, data, enqueueSnackbar, theme, endLabel, startLabel, t, dayStart, dayEnd]);

    return <div data-testid="TimelineChart" id={chartId} className={className} />;
};
