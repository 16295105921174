import {add, sub} from 'date-fns';
import {TimeDataFromServer, TimeData} from '../shared/types';
import {LocalDateTime, ZoneOffset} from '@js-joda/core';
import {isNumber} from './typeguards';

export const toPercent = (value: number): number => Math.round(value * 100);

export const toDate = (value: string): Date => new Date(value);

export const parseTimeData = (list: TimeDataFromServer[]): TimeData[] => {
    const newList: TimeData[] = [];
    (list || []).forEach((e) => {
        const newE: TimeData = {
            date: e.date,
            value: e.value ? toPercent(e.value) : undefined,
        };
        newList.push(newE);
    });
    return newList;
};

export const formatTime = (timeInMillis: number | null | undefined): string => {
    if (timeInMillis === undefined || timeInMillis === null) {
        return '--';
    }

    var timeInSeconds = Math.abs(timeInMillis / 1000);
    var hours = Math.floor(timeInSeconds / 3600);
    var minutes = Math.floor(timeInSeconds / 60) % 60;
    var seconds = Math.floor(timeInSeconds % 60);

    return `${timeInMillis < 0 ? '-' : ''}${[hours, minutes, seconds].map((v) => (v < 10 ? '0' + v : v)).join(':')}`;
};

// removeUndefinedValues cleans up the incoming data to allow amCharts to show gaps
// gaps are only shown when there is no object on dateaxis
export const removeUndefinedValues = (values: TimeData[]): TimeData[] => {
    const arrayWithUndefinedValues: TimeData[] = [...values];

    const cleanedArray = arrayWithUndefinedValues.filter((item: TimeData, index, arrayWithUndefinedValues) => {
        if (index === 0) return true;
        if (isNumber(item.value)) return true;
        if (index === arrayWithUndefinedValues.length - 1) return true;
    });
    return cleanedArray;
};

// ToDo: Refactor so one function takes care of value modification
// export const removeUndefinedValues = (values: TimeData[], isPlannedKPI?: boolean, plannedBusyRatio?: KpiData) => {
//     const arrayWithUndefinedValues: TimeData[] = [...values];

//     const cleanedArray = arrayWithUndefinedValues.filter((item: TimeData, index, arrayWithUndefinedValues) => {
//         if (index === 0) return true;
//         if (isNumber(item.value)) return true;
//         if (index === arrayWithUndefinedValues.length - 1) return true;
//     });

//     const modifiedPLannedValues: (TimeData | undefined)[] = [];

//     if (isPlannedKPI) {
//         const modifiedPLannedValues = cleanedArray.map((value, index) => {
//             if (plannedBusyRatio?.values[index]?.value === 0) return value;
//             if (plannedBusyRatio?.values[index]?.value === 1) return {date: value.date, value: 0};
//         });
//         return modifiedPLannedValues;
//     }

//     if (isPlannedKPI) return modifiedPLannedValues;
//     else return cleanedArray;
// };

export const setInvalidValuesToZero = (values: TimeData[]) => {
    const arrayWithUndefinedValues: TimeData[] = [...values];

    const cleanedArray = arrayWithUndefinedValues.map((item: TimeData, index, arrayWithUndefinedValues) => {
        if (item.value === undefined) return {date: item.date, value: 0};
        if (isNumber(item.value)) return item;
        else return undefined;
    });
    return cleanedArray;
};

export const removeTimeZone = (date: Date): Date => {
    const offsetInMinutes = date.getTimezoneOffset();
    return sub(date, {minutes: offsetInMinutes});
};

export const dateToSameDateInUTC = (date: Date): Date => {
    const offsetInMinutes = date.getTimezoneOffset();
    return add(date, {minutes: offsetInMinutes});
};

export const parseDateToLocalDateTime = (date: Date): string => {
    const time = Math.round(date.getTime() / 1000);
    return LocalDateTime.ofEpochSecond(time, ZoneOffset.UTC).toString();
};
