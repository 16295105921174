import React from 'react';
import {StyledInfobox, StyledLabelWrapper, StyledTypography} from './Infobox.styled';

interface InfoboxProps {
    label: string;
    value: string;
    icon?: React.ReactNode;
}

export const Infobox: React.FC<InfoboxProps> = ({label, value, icon}) => {
    return (
        <StyledInfobox>
            <StyledLabelWrapper>
                <StyledTypography variant="caption" color="textSecondary">
                    {label}
                </StyledTypography>
                {icon}
            </StyledLabelWrapper>
            <StyledTypography title={value} variant="caption" paragraph>
                {value}
            </StyledTypography>
        </StyledInfobox>
    );
};
