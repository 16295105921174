import React from 'react';
import {MenuItem, SelectProps, Tooltip} from '@sym/common-ui/material';
import {useTranslation} from 'react-i18next';

import {StyledSelect} from './SingleMachineSelect.styled';
import {MachineFromQuery} from '../../App';
import {useApplicationsQuery} from '../../graphql/generated/graphql';
import {getInstalledMachines} from '..';

interface SingleMachineSelectProps {
    handleChange: SelectProps['onChange'];
    machines: MachineFromQuery[];
    value: string | null;
}

export const SingleMachineSelect: React.FC<SingleMachineSelectProps> = ({handleChange, machines, value}) => {
    const {t} = useTranslation();
    const {data: applicationsData} = useApplicationsQuery();

    const {installedMachines, uninstalledMachines} = getInstalledMachines(machines, applicationsData);

    const optionsInstalled = installedMachines
        .map((machine) => ({value: machine.id, label: machine.displayName || 'No name', disabled: false}))
        .sort((a, b) => a.label.localeCompare(b.label));

    const optionsUninstalled = uninstalledMachines
        .map((machine) => ({value: machine.id, label: machine.displayName || 'No name', disabled: true}))
        .sort((a, b) => a.label.localeCompare(b.label));

    const options = optionsInstalled.concat(optionsUninstalled);

    return (
        <StyledSelect
            value={value}
            onChange={handleChange}
            renderValue={(value) => options.find((option) => option.value === value)?.label}
            data-testid="single-machine-select"
        >
            {options.map((option) => {
                const title = option.disabled ? t('jobs.MachineNotInstalled') : '';

                return (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        // needed for tooltip
                        style={{pointerEvents: 'auto'}}
                        disabled={option.disabled}
                    >
                        <Tooltip title={title} arrow>
                            <div> {option.label}</div>
                        </Tooltip>
                    </MenuItem>
                );
            })}
        </StyledSelect>
    );
};
