import {UnitModeInterval} from '../../../../graphql/generated/graphql';
import {JobsTimelineItem} from '../../../../shared/types';

export const unitModeFixture: UnitModeInterval[] = [
    {
        from: '2021-06-04T07:45:00Z',
        to: '2021-06-04T08:00:00Z',
        unitMode: 'SETUP',
    },
    {
        from: '2021-06-04T08:00:00Z',
        to: null,
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-04T11:50:00Z',
        to: '2021-06-04T12:15:00Z',
        unitMode: 'MAINTENANCE',
    },
    {
        from: '2021-06-04T12:51:00Z',
        to: '2021-06-04T12:59:00Z',
        unitMode: 'SETUP',
    },
    {
        from: '2021-06-04T13:00:00Z',
        to: '2021-06-04T16:44:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-04T16:44:00Z',
        to: '2021-06-04T16:49:00Z',
        unitMode: 'UNKNOWN',
    },
    {
        from: '2021-06-04T16:49:00Z',
        to: '2021-06-04T18:00:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-04T18:03:00Z',
        to: '2021-06-04T18:15:00Z',
        unitMode: 'MAINTENANCE',
    },
    {
        from: '2021-06-04T18:15:00Z',
        to: '2021-06-04T18:19:00Z',
        unitMode: 'UNDEFINED',
    },
    {
        from: '2021-06-05T07:45:00Z',
        to: '2021-06-05T08:00:00Z',
        unitMode: 'SETUP',
    },
    {
        from: '2021-06-05T08:00:00Z',
        to: '2021-06-05T11:45:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-05T11:50:00Z',
        to: '2021-06-05T12:15:00Z',
        unitMode: 'MAINTENANCE',
    },
    {
        from: '2021-06-05T12:51:00Z',
        to: '2021-06-05T12:59:00Z',
        unitMode: 'SETUP',
    },
    {
        from: '2021-06-05T13:00:00Z',
        to: '2021-06-05T16:44:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-05T16:44:00Z',
        to: '2021-06-05T16:49:00Z',
        unitMode: 'UNKNOWN',
    },
    {
        from: '2021-06-05T16:49:00Z',
        to: '2021-06-05T18:00:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-05T18:03:00Z',
        to: '2021-06-05T18:15:00Z',
        unitMode: 'MAINTENANCE',
    },
    {
        from: '2021-06-05T18:15:00Z',
        to: '2021-06-05T18:19:00Z',
        unitMode: 'UNDEFINED',
    },
    {
        from: '2021-06-06T07:45:00Z',
        to: '2021-06-06T08:00:00Z',
        unitMode: 'SETUP',
    },
    {
        from: '2021-06-06T08:00:00Z',
        to: '2021-06-06T11:45:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-06T11:50:00Z',
        to: '2021-06-06T12:15:00Z',
        unitMode: 'MAINTENANCE',
    },
    {
        from: '2021-06-06T12:51:00Z',
        to: '2021-06-06T12:59:00Z',
        unitMode: 'SETUP',
    },
    {
        from: '2021-06-06T13:00:00Z',
        to: '2021-06-06T16:44:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-06T16:44:00Z',
        to: '2021-06-06T16:49:00Z',
        unitMode: 'UNKNOWN',
    },
    {
        from: '2021-06-06T16:49:00Z',
        to: '2021-06-06T18:00:00Z',
        unitMode: 'PRODUCTION',
    },
    {
        from: '2021-06-06T18:03:00Z',
        to: '2021-06-06T18:15:00Z',
        unitMode: 'MAINTENANCE',
    },
    {
        from: '2021-06-06T18:15:00Z',
        to: '2021-06-06T18:19:00Z',
        unitMode: 'UNDEFINED',
    },
];

export const jobsFixture = [
    {
        id: '1234999',
        mainProgramName: 'Concat_2D_1C.bia',
        startTimestamp: '2021-06-04T08:00:00Z',
        endTimestamp: '2021-06-04T09:00:01Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '1234',
        mainProgramName: 'Concat_2D_1C.bia',
        startTimestamp: '2021-06-04T08:00:00Z',
        endTimestamp: '2021-06-04T09:00:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '1235',
        mainProgramName: 'roe5265ca_3.bia',
        startTimestamp: '2021-06-04T09:15:00Z',
        endTimestamp: '2021-06-04T10:00:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: true,
    },
    {
        id: '1236',
        mainProgramName: 'roe5265ca_3.bia',
        startTimestamp: '2021-06-04T10:05:00Z',
        endTimestamp: '2021-06-04T11:00:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '1237',
        mainProgramName: null,
        startTimestamp: '2021-06-04T11:05:00Z',
        endTimestamp: '2021-06-04T11:45:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '1239',
        mainProgramName: null,
        startTimestamp: '2021-06-04T13:05:00Z',
        endTimestamp: '2021-06-04T13:08:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '1239',
        mainProgramName: null,
        startTimestamp: '2021-06-04T13:09:00Z',
        endTimestamp: '2021-06-04T13:13:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '1239',
        mainProgramName: null,
        startTimestamp: '2021-06-04T13:13:00Z',
        endTimestamp: '2021-06-04T13:18:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
    {
        id: '12310',
        mainProgramName: null,
        startTimestamp: '2021-06-04T13:25:00Z',
        endTimestamp: '2021-06-04T13:28:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: true,
    },
    {
        id: '12311',
        mainProgramName: 'roe5265ca_3.bia',
        startTimestamp: '2021-06-04T13:45:00Z',
        endTimestamp: '2021-06-04T14:10:00Z',
        jobExecutionId: '2021/06/04 5:38:59 PM_Concat_2D_1C.bia',
        hasErrors: false,
    },
];

export const expectedJobs = [
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: '2021-06-04T12:00:00.000Z',
        toDate: '2021-06-04T13:00:01.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: '2021-06-04T12:00:00.000Z',
        toDate: '2021-06-04T13:00:00.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: '2021-06-04T13:15:00.000Z',
        toDate: '2021-06-04T14:00:00.000Z',
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: '2021-06-04T14:05:00.000Z',
        toDate: '2021-06-04T15:00:00.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: '2021-06-04T15:05:00.000Z',
        toDate: '2021-06-04T15:45:00.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: '2021-06-04T17:05:00.000Z',
        toDate: '2021-06-04T17:08:00.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: '2021-06-04T17:09:00.000Z',
        toDate: '2021-06-04T17:13:00.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: '2021-06-04T17:13:00.000Z',
        toDate: '2021-06-04T17:18:00.000Z',
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: '2021-06-04T17:25:00.000Z',
        toDate: '2021-06-04T17:28:00.000Z',
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: '2021-06-04T17:45:00.000Z',
        toDate: '2021-06-04T18:10:00.000Z',
        color: '#69AC58',
    },
];

export const expectedUnitModes = [
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T05:45:00.000Z'),
        toDate: new Date('2021-06-04T06:00:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T06:00:00.000Z'),
        toDate: new Date('2021-06-04T09:45:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T09:50:00.000Z'),
        toDate: new Date('2021-06-04T10:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T10:51:00.000Z'),
        toDate: new Date('2021-06-04T10:59:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T11:00:00.000Z'),
        toDate: new Date('2021-06-04T14:44:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T14:49:00.000Z'),
        toDate: new Date('2021-06-04T16:00:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T16:03:00.000Z'),
        toDate: new Date('2021-06-04T16:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T05:45:00.000Z'),
        toDate: new Date('2021-06-05T06:00:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T06:00:00.000Z'),
        toDate: new Date('2021-06-05T09:45:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T09:50:00.000Z'),
        toDate: new Date('2021-06-05T10:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T10:51:00.000Z'),
        toDate: new Date('2021-06-05T10:59:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T11:00:00.000Z'),
        toDate: new Date('2021-06-05T14:44:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T14:49:00.000Z'),
        toDate: new Date('2021-06-05T16:00:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T16:03:00.000Z'),
        toDate: new Date('2021-06-05T16:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T05:45:00.000Z'),
        toDate: new Date('2021-06-06T06:00:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T06:00:00.000Z'),
        toDate: new Date('2021-06-06T09:45:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T09:50:00.000Z'),
        toDate: new Date('2021-06-06T10:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T10:51:00.000Z'),
        toDate: new Date('2021-06-06T10:59:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T11:00:00.000Z'),
        toDate: new Date('2021-06-06T14:44:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T14:49:00.000Z'),
        toDate: new Date('2021-06-06T16:00:00.000Z'),
        color: '#005499',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T16:03:00.000Z'),
        toDate: new Date('2021-06-06T16:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
];

export const combinedDataFixture: JobsTimelineItem[] = [
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T05:45:00.000Z'),
        toDate: new Date('2021-06-04T06:00:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: new Date('2021-06-04T06:00:00.000Z'),
        toDate: new Date('2021-06-04T07:00:01.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: new Date('2021-06-04T06:00:00.000Z'),
        toDate: new Date('2021-06-04T07:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T06:00:00.000Z'),
        toDate: new Date('2021-06-04T09:45:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-04T07:15:00.000Z'),
        toDate: new Date('2021-06-04T08:00:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-04T08:05:00.000Z'),
        toDate: new Date('2021-06-04T09:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T09:05:00.000Z'),
        toDate: new Date('2021-06-04T09:45:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T09:50:00.000Z'),
        toDate: new Date('2021-06-04T10:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T10:51:00.000Z'),
        toDate: new Date('2021-06-04T10:59:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T11:00:00.000Z'),
        toDate: new Date('2021-06-04T14:44:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T11:05:00.000Z'),
        toDate: new Date('2021-06-04T11:08:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T11:09:00.000Z'),
        toDate: new Date('2021-06-04T11:13:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T11:13:00.000Z'),
        toDate: new Date('2021-06-04T11:18:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T11:25:00.000Z'),
        toDate: new Date('2021-06-04T11:28:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-04T11:45:00.000Z'),
        toDate: new Date('2021-06-04T12:10:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T13:08:00.000Z'),
        toDate: new Date('2021-06-04T14:10:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T14:12:00.000Z'),
        toDate: new Date('2021-06-04T14:20:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T14:22:00.000Z'),
        toDate: new Date('2021-06-04T14:45:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T14:49:00.000Z'),
        toDate: new Date('2021-06-04T16:00:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'LinesPower_matrix.bia',
        name: 'job',
        fromDate: new Date('2021-06-04T14:50:00.000Z'),
        toDate: new Date('2021-06-04T15:06:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T15:10:00.000Z'),
        toDate: new Date('2021-06-04T15:30:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-04T15:45:00.000Z'),
        toDate: new Date('2021-06-04T16:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-04T16:03:00.000Z'),
        toDate: new Date('2021-06-04T16:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T05:45:00.000Z'),
        toDate: new Date('2021-06-05T06:00:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: new Date('2021-06-05T06:00:00.000Z'),
        toDate: new Date('2021-06-05T07:00:01.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: new Date('2021-06-05T06:00:00.000Z'),
        toDate: new Date('2021-06-05T07:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T06:00:00.000Z'),
        toDate: new Date('2021-06-05T09:45:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-05T07:15:00.000Z'),
        toDate: new Date('2021-06-05T08:00:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-05T08:05:00.000Z'),
        toDate: new Date('2021-06-05T09:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T09:05:00.000Z'),
        toDate: new Date('2021-06-05T09:45:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T09:50:00.000Z'),
        toDate: new Date('2021-06-05T10:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T10:51:00.000Z'),
        toDate: new Date('2021-06-05T10:59:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T11:00:00.000Z'),
        toDate: new Date('2021-06-05T14:44:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T11:05:00.000Z'),
        toDate: new Date('2021-06-05T11:08:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T11:09:00.000Z'),
        toDate: new Date('2021-06-05T11:13:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T11:13:00.000Z'),
        toDate: new Date('2021-06-05T11:18:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T11:25:00.000Z'),
        toDate: new Date('2021-06-05T11:28:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-05T11:45:00.000Z'),
        toDate: new Date('2021-06-05T12:10:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T13:08:00.000Z'),
        toDate: new Date('2021-06-05T14:10:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T14:12:00.000Z'),
        toDate: new Date('2021-06-05T14:20:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T14:22:00.000Z'),
        toDate: new Date('2021-06-05T14:45:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T14:49:00.000Z'),
        toDate: new Date('2021-06-05T16:00:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'LinesPower_matrix.bia',
        name: 'job',
        fromDate: new Date('2021-06-05T14:50:00.000Z'),
        toDate: new Date('2021-06-05T15:06:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T15:10:00.000Z'),
        toDate: new Date('2021-06-05T15:30:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-05T15:45:00.000Z'),
        toDate: new Date('2021-06-05T16:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-05T16:03:00.000Z'),
        toDate: new Date('2021-06-05T16:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T05:45:00.000Z'),
        toDate: new Date('2021-06-06T06:00:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: new Date('2021-06-06T06:00:00.000Z'),
        toDate: new Date('2021-06-06T07:00:01.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'Concat_2D_1C.bia',
        name: 'job',
        fromDate: new Date('2021-06-06T06:00:00.000Z'),
        toDate: new Date('2021-06-06T07:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T06:00:00.000Z'),
        toDate: new Date('2021-06-06T09:45:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-06T07:15:00.000Z'),
        toDate: new Date('2021-06-06T08:00:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-06T08:05:00.000Z'),
        toDate: new Date('2021-06-06T09:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T09:05:00.000Z'),
        toDate: new Date('2021-06-06T09:45:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T09:50:00.000Z'),
        toDate: new Date('2021-06-06T10:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
    {
        type: 'unitMode',
        label: 'SETUP',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T10:51:00.000Z'),
        toDate: new Date('2021-06-06T10:59:00.000Z'),
        color: '#ff9800',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T11:00:00.000Z'),
        toDate: new Date('2021-06-06T14:44:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T11:05:00.000Z'),
        toDate: new Date('2021-06-06T11:08:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T11:09:00.000Z'),
        toDate: new Date('2021-06-06T11:13:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T11:13:00.000Z'),
        toDate: new Date('2021-06-06T11:18:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T11:25:00.000Z'),
        toDate: new Date('2021-06-06T11:28:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'job',
        label: 'roe5265ca_3.bia',
        name: 'job',
        fromDate: new Date('2021-06-06T11:45:00.000Z'),
        toDate: new Date('2021-06-06T12:10:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T13:08:00.000Z'),
        toDate: new Date('2021-06-06T14:10:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T14:12:00.000Z'),
        toDate: new Date('2021-06-06T14:20:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T14:22:00.000Z'),
        toDate: new Date('2021-06-06T14:45:00.000Z'),
        color: '#C16C7A',
    },
    {
        type: 'unitMode',
        label: 'PRODUCTION',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T14:49:00.000Z'),
        toDate: new Date('2021-06-06T16:00:00.000Z'),
        color: '#005499',
    },
    {
        type: 'job',
        label: 'LinesPower_matrix.bia',
        name: 'job',
        fromDate: new Date('2021-06-06T14:50:00.000Z'),
        toDate: new Date('2021-06-06T15:06:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T15:10:00.000Z'),
        toDate: new Date('2021-06-06T15:30:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'job',
        label: 'n/a',
        name: 'job',
        fromDate: new Date('2021-06-06T15:45:00.000Z'),
        toDate: new Date('2021-06-06T16:00:00.000Z'),
        color: '#69AC58',
    },
    {
        type: 'unitMode',
        label: 'MAINTENANCE',
        name: 'unitMode',
        fromDate: new Date('2021-06-06T16:03:00.000Z'),
        toDate: new Date('2021-06-06T16:15:00.000Z'),
        color: 'rgb(135, 75, 85)',
    },
];
