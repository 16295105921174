import {css, styled} from '@sym/common-ui/material';
import {Button} from '@sym/common-ui/material';

export const StyledButton = styled(Button)`
    position: absolute;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

export const StyledTableContainer = styled('div')`
    ${({theme: {breakpoints, palette}}) => css`
        width: 100vw;
        max-height: 25vh;
        background-color: ${palette.background.paper};

        & * {
            background-color: ${palette.background.paper};
        }

        ${breakpoints.up('md')} {
            width: 33vw;
        }
    `}
`;

export const StyledCloseButtonWrapper = styled('div')`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        margin: ${spacing(2)};
    `}
`;
