import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Typography} from '@sym/common-ui/material';
import {WeeklyTemplate} from '../../../graphql/generated/graphql';
import {Schedule} from '../Schedule';
import {format} from 'date-fns';
import {TargetType} from '../ApplyTemplateWizard';
interface Step3Props {
    selectedTemplate: WeeklyTemplate | null;
    firstDayOfSchedule: Date;
    targetType: TargetType;
    targetDate: Date | null;
    targetWeeks: number | undefined;
}

export const Step3: FC<Step3Props> = ({selectedTemplate, firstDayOfSchedule, targetDate, targetType, targetWeeks}) => {
    const {t} = useTranslation();
    return (
        <Grid container spacing={4}>
            <Grid item>
                <Typography variant="h6">{t('productionPlanner.applyTemplate.step3.headline')}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('productionPlanner.applyTemplate.step3.subHeadline')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle2" component="span" color="textSecondary">
                    {t('productionPlanner.applyTemplate.step3.selectedTemplate')}{' '}
                </Typography>

                <Typography variant="subtitle2" component="span">
                    {selectedTemplate?.name}.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Schedule template={selectedTemplate} firstDayOfSchedule={firstDayOfSchedule} />
            </Grid>

            <Grid item>
                <Typography variant="subtitle2" color="textSecondary" paragraph>
                    {t('productionPlanner.applyTemplate.step3.selectedPeriod')}
                </Typography>
                <Typography variant="body2">
                    {targetType === 'repetitions'
                        ? `${t('productionPlanner.applyTemplate.step3.copiedScheduleTargetWeeksLabel')}${' '}
                    ${targetWeeks || 0} weeks`
                        : `${t('productionPlanner.applyTemplate.step3.copiedScheduleTargetDateLabel')}${' '} ${format(
                              targetDate || new Date(),
                              'dd.MM.yyyy'
                          )}`}
                </Typography>
            </Grid>
        </Grid>
    );
};
