import React, {useLayoutEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {removeTimeZone, removeUndefinedValues} from '../../../util/dataFormatter';
import startCase from 'lodash.startcase';
import {MachineData} from '../../../shared/types';
import {am4themes_myTheme} from '../../../theme/GfmsTheme';
import {useTheme} from '@sym/common-ui/material';
import {Typography} from '@sym/common-ui/material';
import {StyledSpan, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary} from './LineChart.styled';
import {ExpandMore as ExpandMoreIcon} from '@sym/common-ui/icons-material';
import {getChartConfig} from './config';
import {handleMaxValue, handleMinValue} from './util';
import {useHistory} from 'react-router-dom';
import {getChartLocaleConfig} from '../../../shared/util/chartConfig';
import {isNumber} from 'util';
import qs from 'qs';
import {startOfDay, sub} from 'date-fns';
import {useSnackbar} from 'notistack';

export interface LineChartProps {
    chartId: string;
    data: MachineData[];
    xAxisLabel: string;
    yAxisLabel: string;
    isAutoScale?: boolean;
    yAxisLabelRight?: string;
    className?: string;
    isPlannedKPI?: boolean;
}

// Themes begin
//  InterfaceColors are set within the theme
am4core.useTheme(am4themes_myTheme);
// Themes end

export const LineChart: React.FC<LineChartProps> = ({
    isAutoScale = false,
    chartId,
    data,
    xAxisLabel,
    yAxisLabel,
    className,
    isPlannedKPI,
}: LineChartProps) => {
    const {t} = useTranslation();
    // FIX ME: Type any is not what we want here.
    const lineChart = useRef<any | null>(null);
    const theme = useTheme();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    // mutate incoming data to make use of amCharts features
    const rawMachineData: MachineData[] = data.filter((e) => e.kpiAveragePerMachine !== null);

    // Find max and min value to initiate the graph's scaling
    const maxValues: number[] = rawMachineData.map((machine): number => {
        const rawValues = machine.values;
        const cleanValues = removeUndefinedValues(rawValues);
        const onlyValues = cleanValues.map((timeDataValue) => (timeDataValue?.value ? timeDataValue.value : 0));

        return Math.max(...onlyValues);
    });
    const maxValue: number = Math.max(...maxValues);

    const minValues: number[] = rawMachineData.map((machine): number => {
        const rawValues = machine.values;
        const cleanValues = removeUndefinedValues(rawValues);
        const onlyValues = cleanValues.map((timeDataValue) => (timeDataValue?.value ? timeDataValue.value : 0));

        return Math.min(...onlyValues);
    });
    const minValue: number = Math.min(...minValues);

    const cleanedMachineData = rawMachineData.map((machine) => {
        const rawValues = machine.values;

        const cleanedValuesComparedWithPlannedTimes = rawValues.map((item, key) => {
            if (machine.plannedBusyRatio?.values[key]?.value === 0) {
                return {date: item.date, value: undefined};
            }
            if (item.value === undefined) {
                return {date: item.date, value: 0};
            }
            if (isNumber(item.value)) {
                return item;
            }
            return undefined;
        });

        const cleanValues = isPlannedKPI ? cleanedValuesComparedWithPlannedTimes : removeUndefinedValues(rawValues);

        return {
            id: machine.id,
            machineId: machine.machineId,
            labelText: machine.labelText,
            values: cleanValues,
            binsize: machine.binSize,
            reliabilityAverage: machine.reliabilityAverage,
            reliabilities: machine.reliabilities,
        };
    });

    // display information for what machines data is missing
    const machinesWithoutData = data.filter((machineData: MachineData): boolean | undefined => {
        if (isPlannedKPI === false) {
            return machineData.kpiAveragePerMachine === null;
        }
        if (isPlannedKPI === true) {
            return machineData.kpiAveragePerMachine === 0;
        }
        return undefined;
    });

    // display information which machines had no planned productionTime in this period
    const machinesWithoutPlannedBusyTime = data.filter((machineData: MachineData) => {
        if (isPlannedKPI === true) {
            return machineData.plannedBusyRatio?.average === 0 && machineData.kpiAveragePerMachine === null;
        } else return undefined;
    });

    useLayoutEffect(() => {
        let chart = am4core.create(`${chartId}`, am4charts.XYChart);
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        let xAxisLabelText = `${xAxisLabel}`;
        let yAxisLabelText = `${xAxisLabel}`;
        // TODO: move legend to an external container so it doesnt corrupt linechart heigth anymore. see example: https://www.amcharts.com/docs/v4/tutorials/chart-legend-in-an-external-container/
        let legend = (chart.legend = new am4charts.Legend());

        getChartConfig({
            binsize: data[0]?.binSize,
            chart,
            dateAxis,
            valueAxis,
            xAxisLabel: xAxisLabelText,
            yAxisLabel: yAxisLabelText,
            theme,
            isAutoScale,
            legend,
        });

        getChartLocaleConfig({chart});

        // ========================================================
        // Creates Series
        // ========================================================

        cleanedMachineData.forEach((machine) => {
            valueAxis.max = isAutoScale ? undefined : handleMaxValue(maxValue);
            valueAxis.min = isAutoScale ? undefined : handleMinValue(minValue);

            let series = new am4charts.LineSeries();
            series.data = machine.values.map((machineValue, index) => ({
                ...machineValue,
                percentageReliable: machine.reliabilities?.[index].percentageReliable,
            }));
            series.connect = false;
            series.autoGapCount = 3;
            series.dataFields.valueY = 'value';
            series.dataFields.dateX = 'date';

            series.strokeWidth = 1.5;
            series.minBulletDistance = 16;
            series.legendSettings.labelText = `[bold]${startCase(machine.labelText)}[/]`;

            // tooltipText and tooltipHTML is both needed to support older browsers. Find more information in the docs:
            // https://www.amcharts.com/docs/v4/concepts/tooltips/#Text_versus_HTML
            series.tooltipText = `${startCase(machine.labelText)}: [bold]{value}[/]`;

            series.tooltipHTML = `<div style="padding:0px; margin:0px">
                    <span style="font-size:0.7rem">{date.formatDate('dd.MM.yyyy')}</span><br>
                    ${startCase(machine.labelText)}: {value}
            </div>`;

            var bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = am4core.color(theme.palette.background.default);
            bullet.circle.radius = 4;
            bullet.circle.strokeWidth = 1;

            bullet.events.on(
                'hit',
                (ev) => {
                    const {machineId} = machine;
                    const date = ev.target.dataItem?.dates.dateX;

                    if (!date) {
                        enqueueSnackbar(t('somethingWentWrong'), {variant: 'error'});
                        return;
                    }

                    const startDate = removeTimeZone(sub(startOfDay(date), {days: 7}));

                    const endDate = removeTimeZone(startOfDay(date));

                    history.push(`/machines/jobs/${machineId}/jobsTimeline?${qs.stringify({startDate, endDate})}`);
                },
                this
            );

            chart.series.push(series);
        });

        lineChart.current = chart;

        return () => {
            chart.dispose();
        };
    }, [
        chartId,
        cleanedMachineData,
        data,
        history,
        isAutoScale,
        theme,
        xAxisLabel,
        yAxisLabel,
        maxValue,
        minValue,
        t,
        enqueueSnackbar,
    ]);

    return (
        <>
            {maxValue > 1.5 && (
                <Typography variant="body2" color="primary" paragraph align="center">
                    {t('kpiOverview.unusualValues')}
                </Typography>
            )}
            {machinesWithoutPlannedBusyTime.length > 0 && (
                <StyledAccordion isMachinesWithoutData={Boolean(machinesWithoutData.length >= 1)}>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        color="primary"
                    >
                        <Typography variant="body2" color="primary">
                            {t('kpiOverview.lineChartInformationPlannedKPIs')} ({machinesWithoutPlannedBusyTime.length})
                        </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography variant="body2" align="center">
                            {machinesWithoutPlannedBusyTime.map((element) => (
                                <StyledSpan>{element.labelText.toString()}</StyledSpan>
                            ))}
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
            )}
            {machinesWithoutData.length >= 1 && (
                <StyledAccordion>
                    <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body2" align="center">
                            {isPlannedKPI
                                ? t('kpiOverview.noDataForSomeMachinesKPIs')
                                : t('kpiOverview.noDataForSomeMachines')}{' '}
                            ({machinesWithoutData.length})
                        </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography variant="body2">
                            {t('kpiOverview.noDataAvailableFor')}{' '}
                            {machinesWithoutData.map((element) => (
                                <StyledSpan>{element.labelText.toString()}</StyledSpan>
                            ))}
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
            )}
            <div data-testid="KpiLineChart" id={chartId} className={className} />
        </>
    );
};
