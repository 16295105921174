import {graphql} from 'msw';

import machineStates from './fixtures/machineStates.json';
import machineStatesSingle from './fixtures/machineStatesSingle.json';

export const machineStateHandler = graphql.query('MachineStates', (req, res, context) => {
    if (req?.body?.variables.machineIds.length === 1) {
        return res(
            context.data({
                machineStates: machineStatesSingle,
            })
        );
    }

    return res(
        context.data({
            machineStates,
        })
    );
});
