import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Typography, Switch, FormControlLabel} from '@sym/common-ui/material';
import {Messages} from './Messages';
import {GroupedMessages} from './GroupedMessages';
import {useUserInformationQuery} from '../../graphql/generated/graphql';

interface RelatedMessagesProps {
    assetId: string;
    jobId: string;
    isActiveJob?: boolean;
    isMobile?: boolean;
}

export const RelatedMessages: React.FC<RelatedMessagesProps> = ({assetId, jobId, isActiveJob, isMobile}) => {
    const {t} = useTranslation();

    // to persists groupedMessages switch
    const {data: userData} = useUserInformationQuery();
    const userId = userData?.myTenantUser.id || 'unknownUser';
    const LOCAL_STORAGE_GROUPEDMESSAGES = `performanceanalyzer.${userId}.groupedMessages`;

    const [isGroupedMessages, setIsGroupedMessages] = useState(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_GROUPEDMESSAGES) || 'false')
    );

    const onGroupedMessagesChangeHandler = () => {
        localStorage.setItem(LOCAL_STORAGE_GROUPEDMESSAGES, JSON.stringify(!isGroupedMessages));
        setIsGroupedMessages(!isGroupedMessages);
    };

    return (
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={8}>
                <Typography variant="h6">{t('jobs.RelatedMessages')}</Typography>
            </Grid>
            <Grid container item xs={12} sm={4} justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={isGroupedMessages}
                                onChange={() => onGroupedMessagesChangeHandler()}
                            />
                        }
                        label={`${t('jobs.GroupingSwitch')}`}
                    />
                </Grid>
            </Grid>
            {isActiveJob && (
                <Grid xs={12} marginTop={2}>
                    <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                        {t('jobs.isActiveMessageInfo')}
                    </Typography>
                </Grid>
            )}

            {isGroupedMessages ? (
                <GroupedMessages assetId={assetId} jobId={jobId} isActiveJob={isActiveJob} />
            ) : (
                <Messages assetId={assetId} jobId={jobId} isActiveJob={isActiveJob} />
            )}
        </Grid>
    );
};
