import {startOfToday, startOfTomorrow, sub} from 'date-fns';
import {DateRange} from '../shared/types';
import {removeTimeZone} from './dataFormatter';

export const getStartAndEndDate = (
    LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY: string,
    LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY: string
): DateRange => {
    try {
        if (
            localStorage.getItem(LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY) &&
            localStorage.getItem(LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY)
        ) {
            return {
                startDate: new Date(
                    JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY) || 'null')
                ),
                endDate: new Date(
                    JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY) || 'null')
                ),
            };
        } else if (
            localStorage.getItem(LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY) === 'null' ||
            localStorage.getItem(LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY) === 'null'
        ) {
            return {
                startDate: removeTimeZone(sub(startOfToday(), {days: 7})),
                endDate: removeTimeZone(startOfTomorrow()),
            };
        } else {
            return {
                startDate: removeTimeZone(sub(startOfToday(), {days: 7})),
                endDate: removeTimeZone(startOfTomorrow()),
            };
        }
    } catch (e) {
        return {
            startDate: removeTimeZone(sub(startOfToday(), {days: 7})),
            endDate: removeTimeZone(startOfTomorrow()),
        };
    }
};
