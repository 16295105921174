/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {Grid, Typography} from '@sym/common-ui/material';
import {StyledImg, StyledInfoCard} from './InfoCard.styled';
import {ImageProps} from '../../shared/types';

export interface InfoCardProps {
    headline: string;
    description: string;
    image?: ImageProps;
}

export const InfoCard: React.FC<InfoCardProps> = ({headline, description, image}: InfoCardProps) => {
    return (
        <StyledInfoCard data-testid="InfoCard">
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h5" paragraph>
                        {headline}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                    <Grid item xs={8} md={4}>
                        <StyledImg src={image?.src} alt={image?.alt} width="500" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" paragraph>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </StyledInfoCard>
    );
};
