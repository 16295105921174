import {css, styled} from '@sym/common-ui/material';

export const StyledContainer = styled('div')`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        margin-bottom: ${spacing(2)};
    `}
`;

export const StyledImageContainer = styled('div')`
    ${({theme: {spacing}}) => css`
        margin-right: ${spacing(2)};

        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;

        img {
            width: auto;
            height: 100%;
        }
    `}
`;
