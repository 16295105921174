import {css, styled} from '@sym/common-ui/material';
import {StyledLinkProps} from './Navigation';

export const StyledNavigation = styled('nav')`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        margin-bottom: ${spacing(3)};
    `}
`;

export const StyledLinkWrapper = styled('div')<StyledLinkProps>`
    ${({theme: {spacing, palette, breakpoints}, isActive}) => css`
        margin: ${spacing(0.5)} ${spacing(0.5)};

        color: ${isActive ? palette.primary.main : palette.text.disabled};

        border-bottom: ${isActive ? `2px solid ${palette.primary.main}` : 'none'};

        ${breakpoints.up('sm')} {
            margin: ${spacing(1)} ${spacing(1)};
        }
    `}
`;
