import {Info, Message} from '@sym/common-ui/icons-material';
import {useTranslation} from 'react-i18next';
import {Grid, Tooltip, IconButton} from '@sym/common-ui/material';
import {SyntheticEvent} from 'react';

interface MessageIconsProps {
    isWarning: boolean;
    isError: boolean;
    onIconClick: (event: SyntheticEvent) => void;
}

function MessageIcons({isWarning, isError, onIconClick}: MessageIconsProps) {
    const {t} = useTranslation();

    return (
        <Grid container item xs justifyContent="flex-end" columnGap={0.5} alignItems="center" flexDirection="row">
            {isWarning && (
                <Grid item>
                    <Tooltip title={`${t('machineTile.WarningIconLabel')}`}>
                        <IconButton
                            data-testid="machineTile-WarningMessageIcon"
                            aria-label="delete"
                            size="small"
                            onClick={(e) => onIconClick(e)}
                        >
                            <Message color="warning" fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {isError && (
                <Grid item>
                    <Tooltip title={`${t('machineTile.ErrorIconLabel')}`}>
                        <IconButton
                            data-testid="machineTile-ErrorMessageIcon"
                            aria-label="delete"
                            size="small"
                            onClick={(e) => onIconClick(e)}
                        >
                            <Info color="error" fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
}

export default MessageIcons;
