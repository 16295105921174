import React, {useLayoutEffect, useRef} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {am4themes_myTheme} from '../../../theme/GfmsTheme';
import {useTheme} from '@sym/common-ui/material';
import {getChartConfig} from './config';
import {JobsTimelineItem} from '../../../shared/types';
import {getChartLocaleConfig} from '../../../shared/util';
import {useTranslation} from 'react-i18next';

export interface UnitStateTimelineChartProps {
    chartId: string;
    data: JobsTimelineItem[];
    endLabel: string;
    startLabel: string;
    className?: string;
}

// Themes begin
//  InterfaceColors are set within the theme
am4core.useTheme(am4themes_myTheme);
// Themes end

export const UnitStateTimelineChart: React.FC<UnitStateTimelineChartProps> = ({
    chartId,
    className,
    data,
    endLabel,
    startLabel,
}: UnitStateTimelineChartProps) => {
    // FIX ME: Type any is not what we want here.
    const timelineChart = useRef<any | null>(null);
    const theme = useTheme();
    const {t} = useTranslation();

    useLayoutEffect(() => {
        let chart = am4core.create(`${chartId}`, am4charts.XYChart);
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        let start = startLabel;
        let end = endLabel;

        getChartConfig({
            chart,
            dateAxis,
            categoryAxis,
            theme,
        });

        getChartLocaleConfig({chart: chart});

        chart.data = data;

        const indicator = chart.tooltipContainer!.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        let indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = t('jobs.jobsTimeline.loading');
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;

        chart.events.on('ready', () => {
            indicator.hide(0);
        });

        // ========================================================
        // Creates Series
        // ========================================================

        let series = new am4charts.ColumnSeries();
        series.dataFields.categoryY = 'value';
        series.dataFields.dateX = 'date';

        series.strokeWidth = 1.5;
        series.columns.template.column.stroke = am4core.color(theme.palette.background.paper);
        series.minBulletDistance = 16;

        chart.series.push(series);

        series.columns.template.width = am4core.percent(100);
        series.columns.template.tooltipText = `${start}: {openDateX.formatDate("dd/MM/yyyy, HH:mm:ss")} \n ${end}: {dateX.formatDate("dd/MM/yyyy, HH:mm:ss")}`;

        series.dataFields.openDateX = 'fromDate';
        series.dataFields.dateX = 'toDate';
        series.dataFields.categoryY = 'name';
        series.columns.template.propertyFields.fill = 'color'; // get color from data
        series.columns.template.propertyFields.stroke = 'color';
        series.columns.template.strokeOpacity = 1;

        timelineChart.current = chart;

        return () => {
            chart.dispose();
        };
    }, [chartId, data, theme, endLabel, startLabel, t]);

    return <div data-testid="TimelineChart" id={chartId} className={className} />;
};
