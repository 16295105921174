import {Grid, Typography} from '@sym/common-ui/material';
import React from 'react';
import {StyledMobileCard} from './MobileDataTableCard.styled';

type Item = {
    label: string;
    value: string;
};
interface MobileDataTableCardProps {
    items: [Item, Item, Item, Item];
    onClick(): void;
    dataTestId: string;
}

export const MobileDataTableCard: React.FC<MobileDataTableCardProps> = ({items, onClick, dataTestId}) => {
    return (
        <StyledMobileCard data-testid={dataTestId} onClick={onClick}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography color="textSecondary" variant="overline">
                        {items[0].label}
                    </Typography>
                    <Typography paragraph>{items[0].value}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="div" color="textSecondary" variant="overline" align="right">
                        {items[1].label}
                    </Typography>
                    <Typography paragraph align="right">
                        {items[1].value}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography color="textSecondary" variant="overline">
                        {items[2].label}
                    </Typography>
                    <Typography>{items[2].value}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="div" color="textSecondary" variant="overline" align="right">
                        {items[3].label}
                    </Typography>
                    <Typography component="div" align="right">
                        {items[3].value}
                    </Typography>
                </Grid>
            </Grid>
        </StyledMobileCard>
    );
};
