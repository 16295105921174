class ConfigurationClass {
    // Lookup of configuration setting. First try to retrieve "real" environment during Docker-runtime,
    // then lookup react dev-time process environment (through .env.development)
    // the key is always something starting with REACT_APP_
    private lookup(key: string): string | undefined {
        return ((window as any).ENVIRONMENT || {})[key] || process?.env[key];
    }

    public get PortalUri() {
        return this.lookup('REACT_APP_PORTAL_URL');
    }

    public get GatewayUri() {
        return this.lookup('REACT_APP_GATEWAY_URI');
    }

    public get MachineStateUri() {
        return this.lookup('REACT_APP_MACHINE_STATE_URI');
    }

    public get MachineStateWebsocketUri() {
        return this.lookup('REACT_APP_MACHINE_STATE_WEBSOCKET_URI');
    }

    public get AmChartsLicenseKey() {
        return this.lookup('REACT_APP_AMCHARTS_LICENSE_KEY');
    }
}

export const USE_MOCK = process.env.REACT_APP_USE_MOCK;

export const Configuration = new ConfigurationClass();
