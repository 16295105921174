import React, {useLayoutEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress, Grid, Typography} from '@sym/common-ui/material';
import {useParams} from 'react-router-dom';
import {ActiveJobInfo, JobHistoryTable} from '../../../components';
import {useMachineStatesQuery} from '../../../graphql/generated/graphql';
import {MACHINE_STATE_SUBSCRIPTION} from '../../../subscriptions/MachineStates';

interface ActiveAndHistoricalJobsProps {
    groupId: string | null;
}

export const ActiveAndHistoricalJobs: React.FC<ActiveAndHistoricalJobsProps> = ({groupId}) => {
    const {t} = useTranslation();
    const {id} = useParams<{id: string}>();

    const {loading, error, data, subscribeToMore} = useMachineStatesQuery({
        variables: {machineIds: [id]},
        nextFetchPolicy: 'cache-only',
    });

    useLayoutEffect(() => {
        const unsubscribe = subscribeToMore?.({
            document: MACHINE_STATE_SUBSCRIPTION,
            variables: {machineIds: [id]},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev;
                }

                return {machineStates: [subscriptionData.data.machineStates]};
            },
        });
        return () => {
            unsubscribe?.();
        };
    }, [id, subscribeToMore]);

    const machineState = data?.machineStates?.[0] || {};

    if (loading)
        return (
            <Grid container justifyContent="center" xs>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );

    if (error) {
        console.log({error});
        return <p>{t('Error')}</p>;
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                    {t('jobs.ActiveJob')}
                </Typography>
                <ActiveJobInfo machineState={machineState} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                    {t('jobs.JobHistory')}
                </Typography>
                <JobHistoryTable machineId={id} />
            </Grid>
        </Grid>
    );
};
