import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Divider, Grid, Typography} from '@sym/common-ui/material';
import {JobHistoryItem, UnitModeInterval} from '../../graphql/generated/graphql';
import {endOfDay, format, startOfDay} from 'date-fns';
import {
    COLOR_SETUP,
    COLOR_OUT_OF_SERVICE,
    COLOR_EXECUTING,
    COLOR_MAINTENANCE,
    COLOR_PRODUCTION,
} from '../../theme/GfmsTheme';
import {combineJobsAndUnitModes} from './chart/util';
import _ from 'lodash';
import {JobsTimelineChart} from './chart';
import {TimelineLegendItem} from '../MachineStateTimeline';
import {JobsTimelineItem} from '../../shared/types';

interface JobsTimelineProps {
    assetId: string;
    jobs?: JobHistoryItem[];
    unitModes?: UnitModeInterval[];
    datesWithinDateRange?: Date[];
}

const JobsTimelineInner: React.FC<JobsTimelineProps> = ({assetId, jobs, unitModes, datesWithinDateRange}) => {
    const {t} = useTranslation();

    // first we merge together unitModes and jobs
    const combinedData = jobs && unitModes ? combineJobsAndUnitModes(jobs, unitModes, t) : [];

    // here we group those items by date in preparation to plot on chart for each day
    const dataGroupedByDay = Object.values(
        _.groupBy(combinedData, function (obj) {
            return obj.fromDate.getDate();
        })
    );

    // because we also want to display an information if there is no data for a specific date within the selected timeRange, we need to find out at what days is no data
    const datesWithData = dataGroupedByDay.map((day) => {
        return startOfDay(day[0].fromDate).toString();
    });
    const datesWithoutData = datesWithinDateRange?.filter((date) => !datesWithData.includes(date.toString()));
    const allDateRangeData: any[] = [...dataGroupedByDay];
    // for each day without data we push an object to our allDateRangeData including the date
    datesWithoutData?.forEach((date) => {
        allDateRangeData.push([{fromDate: date}]);
    });

    // in the last step we sort our data again by day to make sure the order is correct
    const sortedAllDateRangeData: any[] = _.sortBy(
        [...allDateRangeData],
        [
            (item) => {
                return item[0].fromDate;
            },
        ]
    );

    const isDayWithJobsTimelineItems = (item: JobsTimelineItem[] | Date[]): item is JobsTimelineItem[] => {
        return (item as JobsTimelineItem[])[0].type !== undefined;
    };

    const hasBothJobsTimelineItemTypes = (jobTimelineDay: JobsTimelineItem[]): boolean => {
        const isatLeastOneUnitMode = jobTimelineDay.some((JobsTimelineItem: JobsTimelineItem) => {
            return JobsTimelineItem.type === 'unitMode';
        });
        const isatLeastOneJob = jobTimelineDay.some((JobsTimelineItem: JobsTimelineItem) => {
            return JobsTimelineItem.type === 'job';
        });

        if (isatLeastOneUnitMode && isatLeastOneJob) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Grid container spacing={6}>
            <Grid item container xs={12} spacing={2} alignContent="flex-end">
                <Grid item container xs={12} sm={6}>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" paragraph>
                            {t('jobTimeline.legendUnitMode')}:
                        </Typography>
                    </Grid>
                    <TimelineLegendItem
                        color={COLOR_PRODUCTION}
                        machineStateLabel={t('jobTimeline.unitModes.production')}
                    />
                    <TimelineLegendItem color={COLOR_SETUP} machineStateLabel={t('jobTimeline.unitModes.setup')} />
                    <TimelineLegendItem
                        color={COLOR_MAINTENANCE}
                        machineStateLabel={t('jobTimeline.unitModes.maintenance')}
                    />
                </Grid>
                <Grid item container xs={12} sm={6}>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" paragraph>
                            {t('jobTimeline.legendJob')}:
                        </Typography>
                    </Grid>

                    <TimelineLegendItem color={COLOR_EXECUTING} machineStateLabel={t('jobTimeline.job')} />
                    <TimelineLegendItem
                        color={COLOR_OUT_OF_SERVICE}
                        machineStateLabel={t('jobTimeline.jobWithError')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
            </Grid>

            {combinedData !== undefined ? (
                sortedAllDateRangeData.reverse().map((jobTimelineDay) => {
                    if (isDayWithJobsTimelineItems(jobTimelineDay) && hasBothJobsTimelineItemTypes(jobTimelineDay)) {
                        return (
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {format(new Date(jobTimelineDay[0].fromDate), 'dd.MM.yyyy, eeee')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <JobsTimelineChart
                                        data-testid="jobsTimelineChart"
                                        assetId={assetId}
                                        dayStart={startOfDay(jobTimelineDay[0].fromDate).toString()}
                                        dayEnd={endOfDay(jobTimelineDay[0].fromDate).toString()}
                                        chartId={jobTimelineDay[0].fromDate.toString()}
                                        // in this step we sort our array by type and reverse its order, to make sure unitMode is always the upper bar in the chart
                                        data={_.sortBy(jobTimelineDay, [
                                            (jobsTimelineItem) => {
                                                return jobsTimelineItem.type;
                                            },
                                        ]).reverse()}
                                        startLabel={t('jobTimeline.start')}
                                        endLabel={t('jobTimeline.end')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider light />
                                </Grid>
                            </Grid>
                        );
                    }
                    if (isDayWithJobsTimelineItems(jobTimelineDay) && !hasBothJobsTimelineItemTypes(jobTimelineDay)) {
                        const inComingData = jobTimelineDay;
                        const newjobTimelineDay = [...inComingData];

                        newjobTimelineDay.push({
                            type: jobTimelineDay[0].type === 'unitMode' ? 'job' : 'unitMode',
                            label: t('jobTimeline.noDataNoChart'),
                            name:
                                jobTimelineDay[0].type === 'unitMode'
                                    ? t('jobTimeline.job')
                                    : t('jobTimeline.unitMode'),
                            fromDate: startOfDay(jobTimelineDay[0].toDate),
                            toDate: startOfDay(jobTimelineDay[0].toDate),
                            color: 's',
                        });

                        return (
                            <Grid item container xs={12} spacing={2}>
                                <Grid item>
                                    <Typography variant="h6">
                                        {format(new Date(jobTimelineDay[0].fromDate), 'dd.MM.yyyy, eeee')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <JobsTimelineChart
                                        data-testid="jobsTimelineChart"
                                        assetId={assetId}
                                        dayStart={startOfDay(jobTimelineDay[0].fromDate).toString()}
                                        dayEnd={endOfDay(jobTimelineDay[0].fromDate).toString()}
                                        chartId={jobTimelineDay[0].fromDate.toString()}
                                        // in this step we sort our array by type and reverse its order, to make sure unitMode is always the upper bar in the chart
                                        data={_.sortBy(newjobTimelineDay, [
                                            (jobsTimelineItem) => {
                                                return jobsTimelineItem.type;
                                            },
                                        ]).reverse()}
                                        startLabel={t('jobTimeline.start')}
                                        endLabel={t('jobTimeline.end')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider light />
                                </Grid>
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid item container xs={12} spacing={2}>
                                <Grid item>
                                    <Typography variant="h6" color="textSecondary" paragraph>
                                        {format(new Date(jobTimelineDay[0].fromDate), 'dd.MM.yyyy, eeee')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" paragraph align="center">
                                        {t('jobTimeline.noDataNoChart')}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider light />
                                </Grid>
                            </Grid>
                        );
                    }
                })
            ) : (
                <Grid item xs={12}>
                    <Typography align="center">{t('jobTimeline.noDataNoChart')}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export const JobsTimeline = memo(JobsTimelineInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
