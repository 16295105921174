import React from 'react';
import {Button} from '@sym/common-ui/material';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';
import {StyledNavigation, StyledLinkWrapper} from './Navigation.styled';

export interface StyledLinkProps {
    isActive: boolean;
}

interface Tab {
    route: string;
    label: string;
}

interface NavigationProps {
    tabs: Tab[];
}

export const Navigation: React.FC<NavigationProps> = ({tabs}) => {
    const location = useLocation();

    return (
        <StyledNavigation>
            {tabs.map(({label, route}) => {
                return (
                    <StyledLinkWrapper isActive={location.pathname.startsWith(route)} key={label}>
                        <Button
                            data-testid={`navigation-tab-${route}`}
                            color="inherit"
                            component={RouterLink}
                            to={route}
                            size="small"
                            role="button"
                        >
                            {label}
                        </Button>
                    </StyledLinkWrapper>
                );
            })}
        </StyledNavigation>
    );
};
