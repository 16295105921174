import {graphql} from 'msw';

import kpiOverview from './fixtures/kpiOverview.json';

export const kpiOverviewHandler = graphql.query('KpiOverview', (req, res, context) =>
    res(
        context.data({
            kpiOverview,
        })
    )
);
