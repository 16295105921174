import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Step1, Step2, Step3} from './';
import {useApplyWeeklyTemplateMutation, useWeeklyTemplatesQuery, WeeklyTemplate} from '../../graphql/generated/graphql';
import {Modal} from '@sym/common-ui/material';
import {LocalDate, nativeJs} from '@js-joda/core';
import {calculateEndDate} from './util';
import {SetupAssistant, SetupAssistantStep} from '@sym/common-ui/components';
import {isBefore} from 'date-fns';
import {MIN_WEEKS} from './Steps/Step2';
import {useSnackbar} from 'notistack';
import {add} from 'date-fns';
import {startOfWeekWithLocale} from '../../util/i18n';

export type TargetType = 'repetitions' | 'weekSelect';
const defaultStartDate = startOfWeekWithLocale(new Date());
interface ApplyTemplateWizardProps {
    open: boolean;
    onClose(): void;
    firstDayOfSchedule: Date;
    machineId: string;
}

export const ApplyTemplateWizard: React.FC<ApplyTemplateWizardProps> = ({
    open,
    onClose,
    firstDayOfSchedule,
    machineId,
}) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [selectedTemplate, setSelectedTemplate] = useState<WeeklyTemplate | null>(null);
    const [targetType, setTargetType] = useState<TargetType>('repetitions');
    const [targetWeeks, setTargetWeeks] = useState(MIN_WEEKS);
    const [targetDate, setTargetDate] = useState<Date>(startOfWeekWithLocale(new Date()));
    const [targetStartDate, setTargetStartDate] = useState<Date>(defaultStartDate);

    const {data} = useWeeklyTemplatesQuery();
    const [applyTemplate] = useApplyWeeklyTemplateMutation();

    const templates = data?.weeklyTemplates || [];

    const startDateIsBeforeEndDate = isBefore(targetStartDate, add(targetDate, {weeks: 1}));

    const handleFinish = async () => {
        try {
            if (!selectedTemplate) {
                alert('select a template');
                return;
            }
            if (!targetStartDate) {
                alert('select a start date');
                return;
            }

            const templateId = selectedTemplate.id;
            const start = LocalDate.from(nativeJs(targetStartDate)).toString();

            const end: LocalDate = calculateEndDate({
                startDate: targetStartDate,
                endDate: targetDate,
                numWeeks: targetWeeks,
                targetType,
            });

            await applyTemplate({
                variables: {
                    start,
                    end: end.toString(),
                    machineId,
                    templateId,
                },
                refetchQueries: ['CalendarEvents'],
            });
            enqueueSnackbar(t('notification.ApplyTemplateSuccess'), {variant: 'success'});
        } catch (error) {
            console.log(error);
            enqueueSnackbar(t('notification.ApplyTemplateFailure'), {variant: 'error'});
        } finally {
            handleClose();
        }
    };

    const handleClose = () => {
        setSelectedTemplate(null);
        setTargetType('repetitions');
        setTargetWeeks(MIN_WEEKS);
        setTargetStartDate(defaultStartDate);

        onClose();
    };

    return (
        <Modal open={open} onClose={onClose} data-testid="apply-template-modal">
            <SetupAssistant
                isOpen={open}
                title={t('productionPlanner.applyTemplate.stepperLabel')}
                onClose={handleClose}
                onFinish={handleFinish}
                labelNext={t('productionPlanner.applyTemplate.labelNext')}
                labelBack={t('productionPlanner.applyTemplate.labelBack')}
                labelCancel={t('productionPlanner.applyTemplate.labelCancel')}
                labelFinish={t('productionPlanner.applyTemplate.labelFinish')}
            >
                <SetupAssistantStep
                    label={t('productionPlanner.applyTemplate.step1.stepperLabel')}
                    canContinue={Boolean(selectedTemplate)}
                >
                    <Step1
                        templates={templates}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        firstDayOfSchedule={firstDayOfSchedule}
                    />
                </SetupAssistantStep>
                <SetupAssistantStep
                    label={t('productionPlanner.applyTemplate.step2.stepperLabel')}
                    canContinue={
                        Boolean(targetStartDate) &&
                        ((targetType === 'repetitions' && Boolean(targetWeeks)) ||
                            (targetType === 'weekSelect' && Boolean(targetDate))) &&
                        !(targetType === 'weekSelect' && !startDateIsBeforeEndDate)
                    }
                >
                    <Step2
                        targetType={targetType}
                        setTargetType={setTargetType}
                        targetWeeks={targetWeeks}
                        setTargetWeeks={setTargetWeeks}
                        targetDate={targetDate}
                        setTargetDate={setTargetDate}
                        targetStartDate={targetStartDate}
                        setTargetStartDate={setTargetStartDate}
                        startDateIsBeforeEndDate={startDateIsBeforeEndDate}
                    />
                </SetupAssistantStep>
                <SetupAssistantStep label={t('productionPlanner.applyTemplate.step3.stepperLabel')}>
                    <Step3
                        selectedTemplate={selectedTemplate}
                        firstDayOfSchedule={firstDayOfSchedule}
                        targetType={targetType}
                        targetWeeks={targetWeeks}
                        targetDate={targetDate}
                    />
                </SetupAssistantStep>
            </SetupAssistant>
        </Modal>
    );
};
