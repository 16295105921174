import {format} from 'date-fns';
import {JobHistoryItem} from '../../graphql/generated/graphql';
import {formatTime} from '../../util/dataFormatter';

export type TableRowType = {
    duration: string;
    id: string;
    name: string;
    start: string;
    plannedTime: string;
    rowIndex: number;
    numItems: number;
};

export const extractFromJobHistory = (item: JobHistoryItem, rowIndex: number, numItems: number): TableRowType => {
    const name = item.mainProgramName || 'n/a';
    const start = item.startTimestamp ? format(new Date(item.startTimestamp), 'dd.MM.yyyy HH:mm:ss') : 'n/a';
    const plannedTime = item.plannedRuntime ? formatTime(item.plannedRuntime * 1000) : 'n/a';
    const duration = item.duration ? formatTime(item.duration * 1000) : 'n/a';
    const id = item.id;
    return {name, start, plannedTime, duration, id, rowIndex, numItems};
};
