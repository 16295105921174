import {isString} from '../../util/typeguards';
import fallbackImage from '../../assets/machine_fallback.jpg';
import {differenceInHours} from 'date-fns';
import {formatDistanceWithLocale} from '../../util';

export const getAvatarOrFallback = (avatar: string | null | undefined): string => {
    if (isString(avatar)) {
        return avatar.replace(/\$\{size\}/i, 'small');
    } else {
        return fallbackImage;
    }
};

export const isLastUpdateMoreThan24Hours = (lastDocumentUpdateUTC: string | undefined): boolean => {
    if (lastDocumentUpdateUTC && differenceInHours(new Date(), new Date(lastDocumentUpdateUTC)) >= 24) {
        return true;
    } else return false;
};

export const getLastUpdateLabel = (lastDocumentUpdateUTC: string, t: Function) => {
    return `${formatDistanceWithLocale(new Date(lastDocumentUpdateUTC), new Date(), {
        includeSeconds: true,
    })} ${t('machineTile.Ago')}`;
};

export const getStateLabel = (unitStatusState: string, t: Function): string => {
    switch (unitStatusState) {
        case 'Executing':
            return t('machineTile.unitStatusState.Executing');
        case 'Waiting':
            return t('machineTile.unitStatusState.Waiting');
        case 'OutOfService':
            return t('machineTile.unitStatusState.OutOfService');
        case 'Undefined':
            return t('machineTile.unitStatusState.Undefined');
        default:
            return t('machineTile.unitStatusState.Unknown');
    }
};

export const getUnitStatusModeLabel = (unitStatusMode: string | undefined, t: Function): string => {
    switch (unitStatusMode) {
        case 'Production':
            return t('machineTile.unitStatusMode.Production');
        case 'Setup':
            return t('machineTile.unitStatusMode.Setup');
        case 'Maintenance':
            return t('machineTile.unitStatusMode.Maintenance');
        case 'Unused':
            return t('machineTile.unitStatusMode.Unused');
        default:
            return t('machineTile.unitStatusMode.ModeNotSet');
    }
};
