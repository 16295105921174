import {Grid, Typography} from '@sym/common-ui/material';
import {css, styled} from '@sym/common-ui/material';
import {LineChart} from '../charts';

export const StyledGridItem = styled(Grid)`
    ${({theme: {spacing}}) => css`
        padding-right: ${spacing(2)};
        margin-bottom: ${spacing(2)};
    `}
`;

export const StyledHeadlineWrapper = styled('div')`
    ${({theme: {spacing}}) => css`
        display: flex;
        width: 100%;
        padding-bottom: ${spacing(2)};
        align-items: center;
    `}
`;

export const StyledHeadline = styled(Typography)`
    ${({theme: {spacing}}) => css`
        margin-right: ${spacing(2)};
    `}
`;

export const StyledAveragesWrapper = styled('div')``;

export const StyledLineChart = styled(LineChart)`
    height: 600px;
`;
