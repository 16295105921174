import {graphql} from 'msw';
import {
    CreateWeeklyTemplateMutation,
    MutationCreateWeeklyTemplateArgs,
    MutationUpdateWeeklyTemplateArgs,
    UpdateWeeklyTemplateMutation,
    WeeklyTemplate,
    WeeklyTemplatesQuery,
} from '../../graphql/generated/graphql';

import weeklyTemplates from '../fixtures/weeklyTemplates.json';

export const weeklyTemplatesHandler = graphql.query<WeeklyTemplatesQuery>('WeeklyTemplates', (req, res, context) => {
    return res(
        context.data({
            weeklyTemplates: weeklyTemplates as WeeklyTemplate[],
        })
    );
});

export const createWeeklyTemplatesHandler = graphql.mutation<
    CreateWeeklyTemplateMutation,
    MutationCreateWeeklyTemplateArgs
>('CreateWeeklyTemplate', (req, res, context) => {
    // To test error states
    if (req.variables.data.name === 'error') {
        return res(context.errors([{message: 'Fail'}]));
    }

    return res(
        context.data({
            createWeeklyTemplate: {
                id: '123',
                name: 'fake first template',
                events: [
                    {
                        dayOfWeek: 'Monday',
                        start: '10:15:30',
                        end: '15:15:30',
                        eventType: 'ProductionTime',
                        __typename: 'TemplateEvent',
                    },
                    {
                        dayOfWeek: 'Wednesday',
                        start: '08:15:30',
                        end: '15:15:30',
                        eventType: 'ProductionTime',
                        __typename: 'TemplateEvent',
                    },
                ],
                __typename: 'WeeklyTemplate',
            },
        })
    );
});

export const updateWeeklyTemplatesHandler = graphql.mutation<
    UpdateWeeklyTemplateMutation,
    MutationUpdateWeeklyTemplateArgs
>('UpdateWeeklyTemplate', (req, res, context) => {
    // To test error states
    if (req.variables.templateId === 'error') {
        return res(context.errors([{message: 'Fail'}]));
    }

    return res(
        context.data({
            updateWeeklyTemplate: {
                id: '123',
                name: 'fake first template',
                events: [
                    {
                        dayOfWeek: 'Monday',
                        start: '10:15:30',
                        end: '15:15:30',
                        eventType: 'ProductionTime',
                        __typename: 'TemplateEvent',
                    },
                    {
                        dayOfWeek: 'Wednesday',
                        start: '08:15:30',
                        end: '15:15:30',
                        eventType: 'ProductionTime',
                        __typename: 'TemplateEvent',
                    },
                ],
                __typename: 'WeeklyTemplate',
            },
        })
    );
});
