import {css, styled} from '@sym/common-ui/material';
import {Button, FormGroup, Grid} from '@sym/common-ui/material';

export const StyledWrapper = styled('div')`
    ${({theme: {breakpoints, spacing}}) => css`
        padding: ${spacing(2)};
        width: 100%;

        ${breakpoints.up('md')} {
            padding: ${spacing(4)};
        }
    `}
`;

export const StyledHeaderWrapper = styled('div')`
    ${({theme: {spacing}}) => css`
        display: flex;
        margin-bottom: ${spacing(2)};
    `}
`;

export const StyledIconWrapper = styled('div')`
    ${({theme: {spacing}}) => css`
        margin-right: ${spacing(2)};
    `}
`;

export const StyledFormGroup = styled(FormGroup)`
    ${({theme: {spacing, breakpoints}}) => css`
        padding-left: 0;

        ${breakpoints.up('md')} {
            padding-left: ${spacing(5)};
        }
    `}
`;

export const StyledCloseButtonWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
`;

export const StyledCloseButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing(5)};
    `}
`;

export const StyledButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing(5)};
    `}
`;

export const StyledGridItem = styled(Grid)`
    ${({theme: {spacing}}) => css`
        padding: 0 ${spacing(5)} ${spacing(5)};
    `}
`;
