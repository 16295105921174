import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reporting/reportWebVitals';
import {StylesProvider} from '@sym/common-ui/styles';
import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@sym/common-ui/material';
import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ApolloClient, InMemoryCache, ApolloProvider, ApolloLink} from '@apollo/client';
import {enGB as usDateFns} from 'date-fns/locale';
import {i18n, i18nInit, PortalHelper} from '@sym/portal-sdk';
import * as am4core from '@amcharts/amcharts4/core';
import {theme} from './theme/GfmsTheme';
import {gfmsTheme} from '@sym/common-ui/themes';

import {App} from './App';
import {Configuration, USE_MOCK} from './AppConfig';
import {worker} from './mocks/browser';
import './styles/index.css';
import de from './i18n/de.json';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import it from './i18n/it.json';
import {errorLink, splitIfSubscription} from './graphql/links';
import {authLink} from './graphql/authLink';
import {LocalizationProvider} from '@sym/common-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

am4core.addLicense(Configuration.AmChartsLicenseKey || '');

PortalHelper.initializeZoid('performance-analyzer', process.env.REACT_APP_URL);
i18nInit({de, en, fr, it});
i18n.options.debug = true;

// Start the mocking conditionally.
if (USE_MOCK === 'true') {
    worker.start();
}

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            // jobs of different machines can have the same id
            // that's why we disable JobInfo normalization so that
            // the machines don't overwrite each others' jobs in the cache
            JobInfo: {keyFields: false},
        },
    }),
    link: ApolloLink.from([errorLink, authLink, splitIfSubscription]),
    connectToDevTools: true,
});

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <StyledEngineProvider>
                <StylesProvider disableGeneration injectFirst>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SnackbarProvider
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <CssBaseline />
                                <ThemeProvider theme={theme}>
                                    <App />
                                </ThemeProvider>
                            </SnackbarProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StylesProvider>
            </StyledEngineProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
