import {endOfWeek, format, formatDistance, Locale, startOfWeek} from 'date-fns';
import {i18n} from '@sym/portal-sdk';
import {de, enGB as en} from 'date-fns/locale';

// we don't support fr and it yet, fallback to english
const locales = {de, en, fr: en, it: en};

export const formatDistanceWithLocale = (
    date: number | Date,
    baseDate: number | Date,
    options?: {
        includeSeconds?: boolean;
        addSuffix?: boolean;
        locale?: Locale;
    }
) => {
    const {language} = i18n;
    // @ts-ignore
    const locale = locales[language] || en;
    return formatDistance(date, baseDate, {...options, locale});
};

export const formatWithLocale = (
    date: number | Date,
    formatStr: string = 'PP',
    options?: {
        locale?: Locale;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    }
) => {
    const {language} = i18n;
    // @ts-ignore
    const locale = locales[language] || en;
    return format(date, formatStr, {...options, locale});
};

export const startOfWeekWithLocale = (date: number | Date) => {
    const {language} = i18n;
    // @ts-ignore
    const locale = locales[language] || en;
    return startOfWeek(date, {weekStartsOn: 1, locale});
};
export const endOfWeekWithLocale = (date: number | Date) => {
    const {language} = i18n;
    // @ts-ignore
    const locale = locales[language] || en;
    return endOfWeek(date, {weekStartsOn: 1, locale});
};
