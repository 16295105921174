import {Grid} from '@sym/common-ui/material';
import _ from 'lodash';
import React, {memo} from 'react';
import {MachineTile} from '..';
import {MachineFromQuery} from '../../App';
import {MachineState, MachineStatesQuery} from '../../graphql/generated/graphql';
import {getSummarizedMachineData} from '../../pages';

interface MachineCardGroupProps {
    machines: MachineFromQuery[];
    machineStates: MachineStatesQuery['machineStates'];
}

const MachineCardGroupInner: React.FC<MachineCardGroupProps> = ({machines, machineStates}) => {
    const sortedMachineData: [MachineFromQuery, MachineState][] = getSummarizedMachineData(
        machines,
        machineStates
    ).sort((a, b) => (a[0].displayName || '--').localeCompare(b[0].displayName || '--'));

    return (
        <Grid container spacing={3}>
            {sortedMachineData?.map((machineData) => {
                const machineState =
                    machineStates.find((machineState) => machineState.machineId === machineData[1].machineId) ?? {};

                return (
                    <Grid key={machineData[0].id} item xs={12} sm={6} md={4} lg={4} xl={3}>
                        <MachineTile machine={machineData[0]} machineState={machineState} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const MachineCardGroup = memo(MachineCardGroupInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
