import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Grid} from '@sym/common-ui/material';
import {StyledHeadlineWrapper, StyledLineChartCard} from '../../layout';
import {StyledLineChart} from '../../components/KpiAverages/KpiAverages.styled';
import {MoreInfoModal} from '../../components';
import {ImageProps, MachineData} from '../../shared/types';
import {StyledTypography} from './Chart.styled';

interface ChartProps {
    shouldDisplay: boolean;
    headline: string;
    description: string;
    image: ImageProps;
    isAutoScale: boolean;
    data: MachineData[];
    chartId: string;
    xAxisLabel: string;
    yAxisLabel: string;
    dataTestId: string;
    isPlannedKPI?: boolean;
}

export const Chart: React.FC<ChartProps> = ({
    shouldDisplay,
    headline,
    description,
    image,
    isAutoScale,
    data,
    chartId,
    xAxisLabel,
    yAxisLabel,
    dataTestId,
    isPlannedKPI = false,
}) => {
    const {t} = useTranslation();

    if (!shouldDisplay) {
        return null;
    }

    const isKpiAverageNull = (data: MachineData) => data.kpiAveragePerMachine === null;

    const areAllAveragesNullish: boolean = data.every(isKpiAverageNull);

    return (
        <Grid item xs={12} md={6}>
            <StyledLineChartCard data-testid={dataTestId}>
                <StyledHeadlineWrapper>
                    <Typography variant="h5" component="h2">
                        {headline}
                    </Typography>
                    <MoreInfoModal headline={headline} description={description} image={image} />
                </StyledHeadlineWrapper>

                {areAllAveragesNullish ? (
                    <StyledTypography variant="body2" align="center" color="textSecondary">
                        {t('kpiOverview.noDataNoChart')}
                    </StyledTypography>
                ) : (
                    <StyledLineChart
                        isPlannedKPI={isPlannedKPI}
                        isAutoScale={isAutoScale}
                        data={data}
                        chartId={chartId}
                        xAxisLabel={xAxisLabel}
                        yAxisLabel={yAxisLabel}
                    />
                )}
            </StyledLineChartCard>
        </Grid>
    );
};
