import {css, styled} from '@sym/common-ui/material';
import {Paper} from '@sym/common-ui/material';

export const StyledPaper = styled(Paper)`
    ${({theme: {spacing, palette, breakpoints}}) => css`
        position: absolute;
        width: 90%;
        background-color: ${palette.background.paper};
        border-radius: ${spacing(0.5)};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: ${spacing(4)};

        ${breakpoints.up('sm')} {
            width: auto;
        }
    `}
`;
