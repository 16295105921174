import {InputLabel, MenuItem, Select as SymSelect, styled} from '@sym/common-ui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export type MachineGrouping = 'FACILITY' | 'BRAND' | 'TECHNOLOGY' | 'MODEL' | 'NONE';

const Select = styled(SymSelect)`
    min-width: 200px;
`;

interface MachineGroupSelectorProps {
    grouping: MachineGrouping;
    onChange(grouping: MachineGrouping): void;
}

export const MachineGroupSelector: React.FC<MachineGroupSelectorProps> = ({grouping, onChange}) => {
    const {t} = useTranslation();

    return (
        <>
            <InputLabel shrink={true} id={'groupBy'}>
                {t('machineTile.selector.groupBy')}
            </InputLabel>
            <Select
                variant="outlined"
                labelId={'groupBy'}
                value={grouping}
                onChange={(e) => onChange(e.target.value as MachineGrouping)}
            >
                <MenuItem value={'FACILITY'} aria-label={'GroupBy Selection Item'}>
                    {t('machineTile.selector.facility')}
                </MenuItem>
                <MenuItem value={'MODEL'} aria-label={'GroupBy Selection Item'}>
                    {t('machineTile.selector.model')}
                </MenuItem>
                <MenuItem value={'BRAND'} aria-label={'GroupBy Selection Item'}>
                    {t('machineTile.selector.brand')}
                </MenuItem>
                <MenuItem value={'TECHNOLOGY'} aria-label={'GroupBy Selection Item'}>
                    {t('machineTile.selector.technology')}
                </MenuItem>
                <MenuItem value={'NONE'} aria-label={'GroupBy Selection Item'}>
                    {t('machineTile.selector.none')}
                </MenuItem>
            </Select>
        </>
    );
};
