import gql from 'graphql-tag';

export const GET_MACHINES = gql`
    query Machines {
        assets {
            id
            displayName
            serialNumber
            facility {
                name
            }
            edgeDevice {
                connected
            }
            machineModel {
                avatar
                modelName
                brand
                technology
            }
        }
    }
`;
