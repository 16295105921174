import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {buildHeaderBar, useHeaderBar, useLoading, PortalHelper} from '@sym/portal-sdk';
import {useLazyQuery} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {Configuration, USE_MOCK} from './AppConfig';
import {defaultHeaderBarOptions} from './defaultHeaderBarOptions';
import {EmptyState, getInstalledMachines, Header, StyledFullHeightGrid} from './components';
import {GET_MACHINES} from './queries';
import {MachinesQuery, useApplicationsQuery, useUserInformationQuery} from './graphql/generated/graphql';
import {MachinesPage, KpiOverview, JobsPage, ActiveJobDetailPage} from './pages';
import {Layout, StyledLayoutContainer} from './layout';
import {Routes} from './routes';
import {getStartAndEndDate} from './util';
import {startOfTomorrow} from 'date-fns';
import {useSnackbar} from 'notistack';
import {getDateRange} from './util/getDateRange';
import {DateRange} from './shared/types';
import {theme} from './theme/GfmsTheme';
import {CircularProgress, Grid, Typography} from '@sym/common-ui/material';
import emptyStateImage from './assets/graphics/emptyStates/machinesPage.svg';
import {useLocalStorageMachines} from './util';

const NUM_MACHINES_SHOWN_BY_DEFAULT = 3;
const MOBILE_BREAKPOINT = theme.breakpoints.values.md;

export type MachineFromQuery = MachinesQuery['assets'][0];

export const App: React.FC = () => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const [selectedMachineIds, setSelectedMachineIds] = useState<string[]>(['']);
    const [groupId, setGroupId] = useState<string | null>(null);

    const {data: userData} = useUserInformationQuery();

    const {data: applicationsData, loading: applicationsLoading, error: applicationsError} = useApplicationsQuery();

    const userId = userData?.myTenantUser.id || 'unknownUser';
    const LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY = `performanceanalyzer.${userId}.startDate`;
    const LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY = `performanceanalyzer.${userId}.endDate`;

    const [width, setWidth] = useState<number>(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= MOBILE_BREAKPOINT;

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
        getStartAndEndDate(LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY, LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY)
    );

    const [loadMachineData, {data: machineData, loading: machineQueryLoading, error: machineQueryError}] =
        useLazyQuery<MachinesQuery>(GET_MACHINES, {
            variables: {groupId},
        });

    const machines: MachineFromQuery[] = machineData?.assets || [];

    const {installedMachines, uninstalledMachines} = getInstalledMachines(machines, applicationsData);

    const installedAndSelectedMachineIds: string[] = [];

    const installedMachinesIds: string[] = installedMachines.map((machine) => machine.id);

    selectedMachineIds.forEach((id) => {
        if (installedMachinesIds.includes(id)) {
            installedAndSelectedMachineIds.push(id);
        }
    });

    useEffect(() => {
        if (userData?.myTenantUser.id) {
            setSelectedDateRange(
                getStartAndEndDate(
                    LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY,
                    LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY
                )
            );
        }
    }, [userData, LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY, LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY]);

    useEffect(() => {
        const getGroupId = async () => {
            PortalHelper.displayProgressBar(true);
            const groupId = await PortalHelper.getGroupId();

            setGroupId(groupId);
            // @ts-ignore
            if (window.Cypress || USE_MOCK === 'true') {
                setGroupId('123');
            }
            PortalHelper.displayProgressBar(false);
        };
        getGroupId();
    }, []);

    useEffect(() => {
        if (groupId) {
            PortalHelper.displayProgressBar(true);
            loadMachineData();
            PortalHelper.displayProgressBar(false);
        }
    }, [groupId, loadMachineData]);

    useLocalStorageMachines(
        machineData,
        applicationsData,
        userId,
        NUM_MACHINES_SHOWN_BY_DEFAULT,
        setSelectedMachineIds
    );

    useLoading(false);

    useHeaderBar(
        buildHeaderBar(
            defaultHeaderBarOptions,
            () => {
                // do something
            },
            {
                add_circle: {
                    enable: false,
                    command: () => console.log('Clicked header bar icon!'),
                },
            }
        )
    );

    const onSelectedMachinesChange = (machineIds: string[]) => {
        setTimeout(() => {
            setSelectedMachineIds(machineIds);
        }, 0);
    };

    const handleEmptyStateClick = () => {
        window.open(`${Configuration.PortalUri}/assets`, '_blank', '');
    };

    const handleDateRangeChange = (dateRange: DateRange) => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return;
        }
        const dateRangeWithoutTimezones = getDateRange(dateRange);

        if (!dateRangeWithoutTimezones) {
            return;
        }

        if (dateRange.startDate > startOfTomorrow()) {
            enqueueSnackbar(t('Header.noFutureStartDate'), {variant: 'warning'});
        } else if (dateRange.endDate > startOfTomorrow()) {
            enqueueSnackbar(t('Header.noFutureEndDate'), {variant: 'warning'});
        }

        setSelectedDateRange(dateRangeWithoutTimezones);
        localStorage.setItem(
            LOCAL_STORAGE_SELECTED_STARTDATE_BYUSER_KEY,
            JSON.stringify(dateRangeWithoutTimezones.startDate)
        );
        localStorage.setItem(
            LOCAL_STORAGE_SELECTED_ENDDATE_BYUSER_KEY,
            JSON.stringify(dateRangeWithoutTimezones.endDate)
        );
    };

    if (machineQueryLoading || applicationsLoading) {
        return (
            <StyledLayoutContainer>
                <StyledFullHeightGrid container>
                    <Grid container item xs={12} justifyContent="space-around">
                        <Grid item>
                            <CircularProgress data-testid="Progress" />
                        </Grid>
                    </Grid>
                </StyledFullHeightGrid>
            </StyledLayoutContainer>
        );
    }

    if (applicationsError)
        return (
            <Grid container justifyContent="center" xs={12}>
                <Grid item>
                    <Typography variant="body2" align="center" color="error">
                        {t('ErrorApplications')}
                    </Typography>
                </Grid>
            </Grid>
        );

    return (
        <>
            {installedMachines.length === 0 ? (
                <StyledLayoutContainer>
                    <StyledFullHeightGrid container>
                        <Grid container item xs={12} justifyContent="space-around">
                            <Grid item xs={12} sm={6} md={4}>
                                <EmptyState
                                    image={{src: emptyStateImage, alt: t('machinesPage.imageAlt')}}
                                    headline={t('machinesPage.emptyStateHeadline')}
                                    copy={t('machinesPage.emptyStateCopy')}
                                    buttonText={t('machinesPage.buttonText')}
                                    onClick={handleEmptyStateClick}
                                />
                            </Grid>
                        </Grid>
                    </StyledFullHeightGrid>
                </StyledLayoutContainer>
            ) : (
                <Router>
                    <Layout>
                        <Switch>
                            <Route exact path={Routes.kpiOverview}>
                                <Header
                                    machines={machines}
                                    onDateRangeChange={handleDateRangeChange}
                                    onSelectedMachinesChange={onSelectedMachinesChange}
                                    selectedDateRange={selectedDateRange}
                                    selectedMachineIds={selectedMachineIds}
                                    tenantUserId={userId}
                                />
                                <KpiOverview
                                    currentDateRange={selectedDateRange}
                                    installedAndSelectedMachineIds={installedAndSelectedMachineIds}
                                    installedMachines={installedMachines || []}
                                />
                            </Route>
                            <Route exact path={Routes.machines}>
                                <MachinesPage
                                    machinesError={machineQueryError}
                                    machinesLoading={machineQueryLoading}
                                    installedMachines={installedMachines}
                                    uninstalledMachines={uninstalledMachines}
                                />
                            </Route>
                            <Route path={`${Routes.jobs}/:id`}>
                                <JobsPage machines={machines} groupId={groupId} />
                            </Route>
                            <Route path={`${Routes.jobDetailRoute}`}>
                                <ActiveJobDetailPage machines={machines} isMobile={isMobile} />
                            </Route>
                            <Redirect to={Routes.machines} />
                        </Switch>
                    </Layout>
                </Router>
            )}
        </>
    );
};
