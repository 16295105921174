import {UnitStateInterval} from '../../../../graphql/generated/graphql';
import {JobsTimelineItem} from '../../../../shared/types';
import {
    COLOR_WAITING,
    COLOR_OUT_OF_SERVICE,
    COLOR_UNKNOWN,
    COLOR_UNDEFINED,
    COLOR_EXECUTING,
} from '../../../../theme/GfmsTheme';
import {removeTimeZone} from '../../../../util/dataFormatter';

const getColor = (unitState: string): string => {
    switch (unitState) {
        case 'WAITING':
            return COLOR_WAITING;
        case 'OUT_OF_SERVICE':
            return COLOR_OUT_OF_SERVICE;
        case 'UNKNOWN':
            return COLOR_UNKNOWN;
        case 'UNDEFINED':
            return COLOR_UNDEFINED;
        default:
            return COLOR_EXECUTING;
    }
};

export const mapJobUnitStates = (unitStates: UnitStateInterval[]): JobsTimelineItem[] => {
    const timelineStates: JobsTimelineItem[] = [];

    unitStates.forEach((unitState) => {
        // if there's no end date we throw it away
        // can only happen for the last unitState in the interval
        if (!unitState.to) {
            return;
        }
        const name = 'UnitStates';
        const fromDate = new Date(unitState.from);
        const toDate = new Date(unitState.to);
        const color = getColor(unitState.unitState);

        timelineStates.push({name, fromDate, toDate, color});
    });

    return timelineStates;
};
