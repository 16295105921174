import React, {useEffect, useState, memo, Fragment} from 'react';
import {LocalDateTime, nativeJs} from '@js-joda/core';
import {Button, CircularProgress} from '@sym/common-ui/material';
import {add, addMilliseconds} from 'date-fns';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Waypoint} from 'react-waypoint';

import {CloseRounded as CloseRoundedIcon} from '@sym/common-ui/icons-material';

import {JobHistoryItem, useJobHistoryWithoutMessagesLazyQuery} from '../../graphql/generated/graphql';
import {Routes} from '../../routes';
import {extractFromJobHistory, TableRowType} from './utils';
import {StyledCloseButtonWrapper, StyledTableContainer} from './JobHistoryQuicklinks.styled';
import {jobDetailLinkSuffix} from '../../shared/types';
import {DataTable, DataTableColumnDefinition} from '@sym/common-ui/components';

const NUM_QUICKLINKS = 50;

// we start one day in the future so timezones don't make trouble
// and we always get the newest entries
const defaultSearchStartDate: Date = add(new Date(), {days: 1});
const from = LocalDateTime.from(nativeJs(defaultSearchStartDate)).toString();
interface JobHistoryQuicklinksProps {
    assetId: string;
    jobId: string;
    isMobile: boolean;
    onClose(): void;
}

const QuicklinksDrawer: React.FC<JobHistoryQuicklinksProps> = ({assetId, jobId, isMobile, onClose}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [jobHistoryFinished, setJobHistoryFinished] = useState(false);
    const [items, setItems] = useState<JobHistoryItem[]>([]);
    const [fetchJobHistory, {data: queryData, error, loading, fetchMore}] = useJobHistoryWithoutMessagesLazyQuery({
        variables: {assetId, from, count: NUM_QUICKLINKS},
    });

    useEffect(() => {
        fetchJobHistory();
    }, [fetchJobHistory]);

    useEffect(() => {
        if (queryData?.asset?.jobHistory) {
            setItems((prev) => [...prev, ...(queryData?.asset?.jobHistory || [])]);
        }
    }, [queryData]);

    const data: TableRowType[] = items.map((item) => extractFromJobHistory(item)) || [];

    const handleRowClick = (rowData: TableRowType) => {
        let suffix: jobDetailLinkSuffix = 'historical';
        const jobId = rowData.id;

        const job = queryData?.asset?.jobHistory.find((job) => job.id === jobId);

        if (job?.endTimestamp === null) {
            suffix = 'active';
        }

        onClose();
        history.replace(`${Routes.jobDetailLink}/${assetId}/${jobId}/${suffix}`);
    };

    const columns: DataTableColumnDefinition<TableRowType>[] = [
        {
            title: t('jobs.JobName'),
            accessor: 'name',
            width: '25%',
            minWidth: 150,
            renderer: (cellProps: any) => {
                const value = cellProps.cell.value;
                const rowIndex = cellProps.row.index;
                const uniqueRows = _.uniqBy(data, 'id');

                if (rowIndex === uniqueRows.length - 5) {
                    return (
                        <Fragment>
                            <Waypoint
                                onEnter={async ({currentPosition}) => {
                                    if (jobHistoryFinished) {
                                        return;
                                    }

                                    let count = 20;

                                    if (currentPosition === 'inside' && !loading && !error) {
                                        let lastStart: Date | null = null;

                                        // since startTimestamp can be null we get the
                                        // latest non-null timestamp from our items
                                        for (let idx = items.length - 1; idx >= -1; idx--) {
                                            const candidate = items[idx].startTimestamp;
                                            if (candidate) {
                                                lastStart = new Date(candidate);
                                                break;
                                            }
                                        }

                                        // if no item has a startTimestamp we
                                        // fetch again from the start but more this time
                                        // (duplicates are filtered out)
                                        if (!lastStart) {
                                            lastStart = defaultSearchStartDate;
                                            count = count + 20;
                                        }

                                        const from = addMilliseconds(lastStart, 1);

                                        // TODO debounce
                                        const newData = await fetchMore?.({
                                            variables: {
                                                assetId: assetId,
                                                from: LocalDateTime.from(nativeJs(from)).toString(),
                                                count,
                                            },
                                        });
                                        const jobHistory = newData?.data.asset?.jobHistory;

                                        if (jobHistory && jobHistory.length === 0) {
                                            setJobHistoryFinished(true);
                                        }
                                        const newItems = jobHistory || [];

                                        setItems((prev) => [...prev, ...newItems]);
                                    }
                                }}
                            />
                            {value}
                        </Fragment>
                    );
                } else {
                    return <Fragment>{value}</Fragment>;
                }
            },
        },
        {
            title: t('jobs.StartTime'),
            accessor: 'startTime',
            width: '25%',
            minWidth: 150,
        },
        {title: '', accessor: 'id', width: '0%', renderer: () => null},
    ];

    const getNoMatchLabel = () => {
        if (error) {
            return t('jobs.ErrorLoadingJobs');
        }
        if (loading) {
            return <CircularProgress />;
        }
        return t('jobs.NoJobs');
    };

    return (
        <>
            {isMobile && (
                <StyledCloseButtonWrapper>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={onClose}
                        startIcon={<CloseRoundedIcon />}
                        data-testid="close"
                    >
                        {t('Close')}
                    </Button>
                </StyledCloseButtonWrapper>
            )}
            <StyledTableContainer data-testid="quicklinks-drawer">
                <DataTable
                    columns={columns}
                    data={data}
                    onRowClick={handleRowClick}
                    error={error && t('jobs.ErrorLoadingJobs')}
                    loading={loading && !data}
                    labelEmptyData={t('jobs.NoJobs')}
                    // Hide filter button while we don't have filters
                    labelFilters=""
                />
            </StyledTableContainer>
        </>
    );
};

// memo because ActiveJobDetaiPage's subscriptions would update this component every second
export const JobHistoryQuicklinksDrawer = memo(QuicklinksDrawer, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
