import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Grid, Typography} from '@sym/common-ui/material';
import {UnitStateTimelineChart} from './chart';
import {UnitStateInterval} from '../../graphql/generated/graphql';
import {
    COLOR_WAITING,
    COLOR_OUT_OF_SERVICE,
    COLOR_UNKNOWN,
    COLOR_UNDEFINED,
    COLOR_EXECUTING,
} from '../../theme/GfmsTheme';
import {mapJobUnitStates} from './chart/util';
import {TimelineLegendItem} from './Legend';
import _ from 'lodash';

interface MachineStateTimelineProps {
    unitStates: UnitStateInterval[];
}

const MachineStateTimelineInner: React.FC<MachineStateTimelineProps> = ({unitStates}) => {
    const {t} = useTranslation();

    const data = mapJobUnitStates(unitStates);

    const atLeastOneExecutingState = Boolean(unitStates.find((unitState) => unitState.unitState === 'EXECUTING'));
    const atLeastOneWaitingState = Boolean(unitStates.find((unitState) => unitState.unitState === 'WAITING'));
    const atLeastOneOutOfServiceState = Boolean(
        unitStates.find((unitState) => unitState.unitState === 'OUT_OF_SERVICE')
    );
    const atLeastOneUndefinedState = Boolean(unitStates.find((unitState) => unitState.unitState === 'UNDEFINED'));
    const atLeastOneUnknownState = Boolean(unitStates.find((unitState) => unitState.unitState === 'UNKNOWN'));

    return (
        <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs>
                <Typography variant="h6">{t('jobs.jobsTimeline.headline')}</Typography>
            </Grid>
            <Grid item container xs={12} md justifyContent="space-evenly" spacing={2} alignContent="flex-end">
                {atLeastOneExecutingState && (
                    <TimelineLegendItem
                        color={COLOR_EXECUTING}
                        machineStateLabel={t('machineTile.unitStatusState.Executing')}
                    />
                )}
                {atLeastOneWaitingState && (
                    <TimelineLegendItem
                        color={COLOR_WAITING}
                        machineStateLabel={t('machineTile.unitStatusState.Waiting')}
                    />
                )}
                {atLeastOneOutOfServiceState && (
                    <TimelineLegendItem
                        color={COLOR_OUT_OF_SERVICE}
                        machineStateLabel={t('machineTile.unitStatusState.OutOfService')}
                    />
                )}
                {atLeastOneUnknownState && (
                    <TimelineLegendItem
                        color={COLOR_UNKNOWN}
                        machineStateLabel={t('machineTile.unitStatusState.Unknown')}
                    />
                )}
                {atLeastOneUndefinedState && (
                    <TimelineLegendItem
                        color={COLOR_UNDEFINED}
                        machineStateLabel={t('machineTile.unitStatusState.Undefined')}
                    />
                )}
            </Grid>

            {unitStates && (
                <Grid item xs={12}>
                    <UnitStateTimelineChart
                        chartId="TimelineChart"
                        data={data}
                        startLabel={t('jobTimeline.start')}
                        endLabel={t('jobTimeline.end')}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export const MachineStateTimeline = memo(MachineStateTimelineInner, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
